import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMsaByContractor',
  standalone: false
})
export class FilterMsaByContractorPipe implements PipeTransform {

  transform(msaList: any[], selectedContractorId: number | undefined): any[] {
    if (!msaList || !selectedContractorId) {
      return msaList;
    }
    return msaList.filter(msa => (msa.contractor_id === selectedContractorId) || msa.contractor_id === 0);
  }

}
