<div class="justify-content-between d-flex">
    <h3>{{'projectLead.projectStatus' | translate}}</h3>
    <div><button type="button" class="btn btn-xs btn-secondary" (click)="logout()">{{'basic.logOut' | translate}}</button></div>
</div>
<div *ngIf="showWOM" class="page-container">
    <div class="page-content">
        <div *ngIf="projects.length === 0">{{'projectLead.noProjects' | translate}}</div>
        <div *ngFor="let project of projects" class="main">
            <div class="justify-content-between d-flex">
                <h4>{{project.identifier}}</h4>
                <div><button type="button" class="btn btn-primary btn-xs font-size-small" (click)="toggleWOM(project.id)">{{'basic.reports' | translate}}</button></div>
            </div>
            <div class="container">
                <div class="row">
                    <!-- Left Column -->
                    <div class="col-12 col-sm-7 d-flex flex-column flex-sm-row align-items-center align-items-sm-start" *ngIf="project.progress.total !== 0">
                        <!-- First Box -->
                        <div class="font-size-small text-center text-sm-left col-12 col-sm-4 mb-3 mb-sm-0">
                            <app-ngx-circle-custom
                                [efficiency]="project.progress.confirmed / project.progress.total"
                                [efficiencyTitle]="project.confirmedPercent + '%'"
                                color="green">
                            </app-ngx-circle-custom>
                            <div class="gray-text">{{'projectLead.workordersConfirmed' | translate}}</div>
                            <div class="fw-bold">{{project.progress.confirmed}} / {{project.progress.total}}</div>
                        </div>
                        <!-- Second Box -->
                        <div class="font-size-small text-center text-sm-left col-12 col-sm-4 mb-3 mb-sm-0">
                            <app-ngx-circle-custom
                                [efficiency]="project.progress.done / project.progress.total"
                                [efficiencyTitle]="project.donePercent + '%'"
                                color="green">
                            </app-ngx-circle-custom>
                            <div class="gray-text">{{'planner.contractorDashboard.workordersDone' | translate}}</div>
                            <div class="fw-bold">{{project.progress.done}} / {{project.progress.total}}</div>
                        </div>
                        <!-- Third Box -->
                        <div class="font-size-small text-center text-sm-left col-12 col-sm-4">
                            <app-ngx-circle-custom
                                [efficiency]="project.progress.interrupted / project.progress.total"
                                [efficiencyTitle]="project.interruptedPercent + '%'"
                                color="red">
                            </app-ngx-circle-custom>
                            <div class="gray-text">{{'planner.contractorDashboard.workordersInterrupted' | translate}}</div>
                            <div class="fw-bold">{{project.progress.interrupted}} / {{project.progress.total}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-7 d-flex flex-column flex-sm-row align-items-center align-items-sm-start justify-content-center" *ngIf="project.progress.total === 0">
                        {{'fieldReporting.dashboard.noWorkordersFound' | translate}}
                    </div>
                    <!-- Right Column -->
                    <div class="col-12 col-sm-4 font-size-small d-flex flex-column text-center text-sm-left mt-sm-0 mt-2">
                        <div>
                            <div class="gray-text">{{'topBar.contractor' | translate}}</div>
                            <div class="fw-bold">{{project.contractor_name}}</div>
                        </div>
                        <div class="mt-auto">
                            <div class="gray-text">{{'planner.contractorDashboard.workordersTotal' | translate}}</div>
                            <div class="fw-bold">{{project.progress.total}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showReports">
    <app-reports
    (triggerShow)="onTriggerShow($event)"
    [projectId]="projectId"
    [manager]="true"
    (showDetail)="onDetailShow($event)"
    ></app-reports>
</div>
<div *ngIf="showDetail">
    <workorder-detail
    [reportWorkorderDetailId]="reportWorkorderDetailId"
    (backToDashboard)="onBackToDashboard($event)"
    ></workorder-detail>
</div>