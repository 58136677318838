import { Component, Input } from '@angular/core';
import { Toast } from '@shared/models/toast';

@Component({
  selector: 'app-toast-item',
  templateUrl: './toast-item.component.html',
  styleUrls: ['./toast-item.component.scss'],
  standalone: false
})
export class ToastItemComponent {

  @Input()
  toast: Toast | undefined;

}
