import { Component, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamWeeklyPlanning } from '@shared/models/teamWeeklyPlanning';
import { finalize, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-weekly-planning',
  templateUrl: './weekly-planning.component.html',
  styleUrls: ['./weekly-planning.component.scss'],
  standalone: false
})
export class WeeklyPlanningComponent implements OnInit {


 teams = Array()
 spinner = true
 cleanupCalendars = Array()
 spinner2 = true
 componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private router: Router,
    private workersService: WorkersService,
    private calendars: CalendarsService,
    private workorderService: WorkorderService,
    private translateService: TranslateService
  ) { }

  /**
   * Component initialization.
   * Get workerteams information.
   * Get cleanup calendars information.
   * @author Jesse Lindholm
   */
  ngOnInit(): void {
    this.getWorkerteams()
    this.getCleanupCalendars()
  }

  /**
   * Component destruction.
   * End subscriptions to free up space.
   * @author Jesse Lindholm
   */
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Move to weekly planning detail page with correct id
   * @param id give id of team when moving
   * @author Jesse Lindholm
   */
  createCalendar(id) {
    const queryParamsValue: Params = { mode: 'create' };
    let url = "planner/weekly-planning-detail/" + id;
    this.router.navigate([url],{
      queryParams: queryParamsValue,
      queryParamsHandling: 'merge'
    })
  }

  /**
   * Navigate to single team page.
   * @param id teams ID
   * @author Jesse Lindholm
   */
  moveToSingleTeamPage(id) {
    this.router.navigate(['planner/teams-detail/' + id])
  }

  /**
   * Get all workerteams and perform push for every object to teams variable.
   * After response is finished stop spinner.
   * @author Jesse Lindholm
   */
  getWorkerteams() {
    this.spinner = true
    this.workersService.getWorkerteams()
    .pipe(
      takeUntil(this.componentDestroyed$),
      finalize(() => {
        this.spinner = false
      })
    )
    .subscribe(
      data => {
        this.teams = Array()
        for (let i = 0; i < data.length; i++ ) {
          let team: TeamWeeklyPlanning = {} as TeamWeeklyPlanning;
          team.name = data[i].name
          team.teamId = data[i].id
          team.workersCount = data[i].workerscount
          team.calendarsCount = data[i].calendarscount
          team.efficiencyValue = data[i].efficiency
          // Set color to green
          if (data[i].efficiency >= 1.0) {
            team.efficiency = (data[i].efficiency - 1) * 100
            team.colorInner = '#10A231',
            team.colorOuter = '#05791F'
          }
          // Sst color to red 
          else {
            team.efficiency = data[i].efficiency * 100
            team.colorInner = '#ffcccb',
            team.colorOuter = '#FF0000'
          }
          this.teams.push(team)
        }
      }
    )
  }

  /**
   * Get data for cleanup calendars.
   * Locale is set to Finland.
   * @author Jesse Lindholm
   */
  getCleanupCalendars() {
    this.calendars.getCalendars()
    .pipe(
      finalize(() => {
        this.spinner2 = false;
      })
    )
    .subscribe(
      data => {
        for (let i = 0; i < data.length; i++) {
          // type 2 is cleanup calenadr
          if (data[i].type == 2) {
            let startdate = new Date(data[i].startdate)
            data[i].startdate = startdate.toLocaleDateString("fi-FI")
            let enddate = new Date(data[i].enddate)
            data[i].enddate = enddate.toLocaleDateString("fi-FI")
            data[i].totalWeeks = (enddate.getTime() - startdate.getTime()) / 1000
            data[i].totalWeeks = Math.abs(Math.round(data[i].totalWeeks / (60 * 60 * 24 * 7)))
            if (data[i].teams) data[i].team = Object.values(data[i].teams)[0]
            if (data[i].msa_id) this.setMsaName(data[i])
            this.cleanupCalendars.push(data[i])
          }
        }
      }
    )
    
  }

  /**
   * Set msa name for cleanup calendar. Does not work properly for older cleanup calendars and might 
   * throw an error not found for msa. Reason is msa has been deleted prior to database changes so it did not
   * remove link from calendar when deleting MSA. Should work properly for new calendars/MSAs
   * @param object calendar object
   * @author Jesse Lindholm
   */
  setMsaName(object) {
    this.workorderService.getMsa(object.msa_id).subscribe(
      data => {
        if (data != undefined) object.msaName = data.name
        
      }
    )
  }

  /** 
   * Fired when user selects a team for cleanup calendar.
   * Find correct team name and set it to cleanupcalendars team variable.
   * @author Jesse Lindholm
   * 
   * @edit 16.12.2022
   * Now unlinks teams from calendar before inserting new one. End result is calendar can have only have one team assigned.
   * Fixed 4 for loops to 2 find functions.
   * @author Jesse Lindholm
   */
  assignTeam(teamId, cleanupCalendarId) {
    let element = this.cleanupCalendars.find(c => c.id === cleanupCalendarId)
    for (let x = 0; x < this.teams.length; x++) {
      if (this.teams[x].name == element.team) {
        this.calendars.unLinkToWorkerteam(this.teams[x].teamId, cleanupCalendarId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          () => {
            if (!teamId) element.team = this.translateService.instant('teams.noTeamAssigned')
          }
        )
        
      }
    }
    if (teamId) {
      this.calendars.linkToWorkerteam(teamId, cleanupCalendarId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        let team = this.teams.find(t => t.teamId === teamId)
        // Set name to correct cleanup calendar
        element.team = team.name
      })
    }
  }

  /**
   * Navigate to correct page in weekly-planning-map with given id
   * @param calendarId id that calendar has
   * @author Jesse Lindholm
   */
  openCalendar(calendarId) {
    this.router.navigate(['planner/weekly-planning-map/' + calendarId])
  }


}
