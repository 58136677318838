import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import { PlannerContractorService } from '@modules/planner/services/planner-contractor/planner-contractor.service';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { TranslateService } from '@ngx-translate/core';
import { Interrupt } from '@shared/models/interrupt';
import { Status } from '@shared/models/status';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  standalone: false
})

export class ReportComponent implements OnInit {
  
  tab: number = 1
  tabOnePartOneSelectionId: number = 0
  tabOnePartTwoSelectionId: number = 0
  tabTwoSelectionId: number = 0
  tabThreeSelectionId: number = 0
  workorderId: null | number = null
  basicReport: boolean = true
  tabOneTextarea: string = ''
  tabTwoTextarea: string = ''
  tabThreeTextarea: string = ''
  tabFourTextareaPartOne: string = ''
  tabFourTextareaPartTwo: string = ''
  statuses: Status[] = []
  userLanguage: string = 'en'
  interruptCodes: Interrupt[] = []
  selectedInterruptCode: string | null = null

  constructor(
    private fieldReportingService: FieldReportingService,
    private route: ActivatedRoute,
    private router: Router,
    private workorderService: WorkorderService,
    private toastService: ToastService,
    private plannerContractorService: PlannerContractorService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    let userLang = localStorage.getItem('userlanguage')
    if (userLang) this.userLanguage = userLang
    this.route.paramMap.subscribe(params => {
      let id = params.get('id');
      if (id) {
        this.workorderId = parseInt(id)
        if (this.workorderId) this.getWorkorderInfo(this.workorderId)
      }
    });
    this.getStatuses()
    this.getInterruptCodes()
  }
  getStatuses() {
    this.plannerContractorService.getStatuses()
      .subscribe(data => {
        this.statuses = data
    })
  }

  getWorkorderInfo(workorderId: number) {
    this.workorderService.getWorkorder(workorderId).subscribe({
      next: data => {
        this.tab = data.phase
        if (data.phase === 4) this.basicReport = false
      },
      error: err => console.log(err.toString())
    })
  }

  /**
   * Save report with correct status, reportType, reportStatus and phase
   * Phase: new phase for workorder, phase 4 is installation + activation in same workflow
   * Status: new status for workorder, default is 1
   * ReportStatus: old status for report, 6 or 4. 6 is interrupted, 4 is done
   * ReportType: type of report, 1 is doneReport, 2 is interruptedReport
   */
  saveReport(quit: boolean) {
    let reportDoneStatus = this.statuses.find(s => s.state === 4)
    let reportInterruptStatus = this.statuses.find(s => s.state === 6)
    let reportType: number = 1;
    let newPhase: number = this.tab;
    let textArea: string = ''
    let reportStatus: any = null
    let reportInterruptCode: string | null = null
    if (reportDoneStatus) reportStatus = reportDoneStatus.id
    
    if (this.tab === 1) {
      textArea = this.tabOneTextarea
        if (this.tabOnePartOneSelectionId === 1 && reportType === 1) {
          newPhase = 4;
        }
        else if (this.tabOnePartOneSelectionId === 2 && reportType === 1)  newPhase = 2;
        if (this.tabOnePartTwoSelectionId === 2) {
          reportType = 2;
          if (reportInterruptStatus) reportStatus = reportInterruptStatus.id
          if (this.selectedInterruptCode) reportInterruptCode = this.selectedInterruptCode
        }
    } else if ((this.tab === 2 && this.tabTwoSelectionId === 2) || 
               (this.tab === 3 && this.tabThreeSelectionId === 2)) {
        reportType = 2;
        if (reportInterruptStatus) reportStatus = reportInterruptStatus.id
        if (this.selectedInterruptCode) reportInterruptCode = this.selectedInterruptCode
    } else if (this.tab === 4 && this.tabTwoSelectionId === 2) {
      reportType = 2
      if (reportInterruptStatus) reportStatus = reportInterruptStatus.id
      if (this.selectedInterruptCode) reportInterruptCode = this.selectedInterruptCode
      textArea = this.tabFourTextareaPartOne
    }
    let status = this.statuses.find(s => s.state === 1)
    let statusId: any = null
    if (status) statusId = status.id

    if(this.tab === 2) {
      if (reportType === 1) newPhase = 3
      textArea = this.tabTwoTextarea
    } else if (this.tab === 3) {
      textArea = this.tabThreeTextarea
      if (reportType === 1 && reportDoneStatus) {
        status = reportDoneStatus
        if (status) statusId = status.id
      }
    } else if (this.tab === 4) textArea = this.tabFourTextareaPartOne

    if (this.tab === 1 && !this.basicReport && reportType === 1) newPhase = 4
    
    if (this.workorderId && reportStatus && statusId) {
      if (reportType === 2) {
        newPhase = this.tab
        status = this.statuses.find(s => s.state === 6)
        if (status) statusId = status.id
      }
      let done: boolean = true
      if (reportType === 2) done = false
      this.fieldReportingService.reportWorkorder(this.workorderId, newPhase, reportType, reportStatus, statusId, textArea, reportInterruptCode, done).subscribe({
        next: () => {
          if (quit) this.router.navigate(['/field-reporting/dashboard'])
          if (this.tab === 4 && this.workorderId) {
            textArea = this.tabFourTextareaPartTwo
            if (reportDoneStatus) {
              reportStatus = reportDoneStatus.id
              statusId = reportDoneStatus.id
            }
            reportType = 1
            if (this.tabThreeSelectionId === 2) {
              if (reportInterruptStatus) reportStatus = reportInterruptStatus.id
              if (this.selectedInterruptCode) reportInterruptCode = this.selectedInterruptCode
              status = this.statuses.find(s => s.state === 6)
              if (status) statusId = status.id
              reportType = 2
            }
            let done: boolean = true
            if (reportType === 2) done = false
            this.fieldReportingService.reportWorkorder(this.workorderId, newPhase, reportType, reportStatus, statusId, textArea, reportInterruptCode, done).subscribe({
              next: () => this.router.navigate(['/field-reporting/dashboard']),
              error: err => console.log(err.toString())
            })
          } else {
            if (this.tab === 1 && reportType === 2) this.router.navigate(['/field-reporting/dashboard'])
              if (this.tab === 2 && reportType === 2) this.router.navigate(['/field-reporting/dashboard'])
            else if (this.tab !== 3 && newPhase !== 4) this.tab++
            else if (newPhase === 4 && this.tab === 1) {
              this.tab = 4
              this.basicReport = false
            } else this.router.navigate(['/field-reporting/dashboard'])
          }
        },
        error: err => {
          console.log(err)
        }
      })
    } else this.toastService.sendToast(false, 'Error with saving workorder. No workorder ID found')
  }

  nextDisabled() {
    if (this.tab === 1) {
      if (this.tabOnePartOneSelectionId && this.tabOnePartTwoSelectionId === 1) return false
      else if (this.tabOnePartTwoSelectionId === 2 && this.tabOneTextarea) return false
      else return true
    } else if (this.tab === 2) {
      if (this.tabTwoSelectionId === 1) return false
      else return true
    } else return true
  }

  backButtonPressed() {
    this.router.navigate(['/field-reporting/dashboard'])
  }

  previousTab() {
    if (this.tab !== 4) this.tab--
    else this.tab = 1
  }

  showSave() {
    if (this.tab === 3 || this.tab === 4) return true
    else if (this.tab === 1 && this.tabOnePartTwoSelectionId === 2) return true
    else if (this.tab === 2 && this.tabTwoSelectionId === 2) return true
    else return false
  }

  showNext() {
    if (this.tab === 1 && this.tabOnePartTwoSelectionId === 1) return true
    else if (this.tab === 2 && this.tabTwoSelectionId === 1) return true
    else return false
  }

  saveDisabled() {
    if (this.tabThreeSelectionId === 1 && this.tab === 3) return false
    else if (this.tabThreeSelectionId === 2 && this.tabThreeTextarea && this.tab === 3) return false
    else if (this.tab === 4) {
      if (this.tabTwoSelectionId === 1 && this.tabThreeSelectionId === 1) return false
      else if (this.tabTwoSelectionId === 2 && this.tabFourTextareaPartOne && this.tabThreeSelectionId === 1) return false
      else if (this.tabThreeSelectionId === 2 && this.tabFourTextareaPartTwo && this.tabTwoSelectionId === 1) return false
      else if (this.tabThreeSelectionId === 2 && this.tabFourTextareaPartTwo && this.tabTwoSelectionId === 2 && this.tabFourTextareaPartOne) return false
      else return true
    }
    else if (this.tab === 1 && this.tabOneTextarea) return false
    else if (this.tab === 2 && this.tabTwoTextarea) return false
    else return true
  }

  getInterruptCodes() {
    this.plannerContractorService.getInterruptOptionsApi(null, this.workorderId)
      .subscribe(
        (interrupts: Interrupt[]) => {
          interrupts.forEach(interrupt => {
            let interruptDefinition = interrupt.definition || {}
            if(this.userLanguage && interruptDefinition) {
              interruptDefinition = interruptDefinition[this.userLanguage]
            } else if (interruptDefinition){
              interruptDefinition = interruptDefinition['en']
            }
            if (typeof(interruptDefinition) === 'string') interrupt.definition = interruptDefinition
        });
        let otherInterrupt: any = {definition: this.translateService.instant('basic.other'), interrupt_code: '00'}
        interrupts = [...interrupts, otherInterrupt]
        if (interrupts.length === 1) this.selectedInterruptCode = interrupts[0].interrupt_code
        if (interrupts.length > 0) {
          this.selectedInterruptCode = interrupts[0].interrupt_code;
        }
        this.interruptCodes = interrupts
        }
      )
  }



}
