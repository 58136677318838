<div>
      <div id="mapHeaderContainer" class="navbar navbar-expand-lg navbar-light light-bg structure-child"> 
            <div id="map-header" class="map-header dual-container" [hidden]="positionEditor">
                  <div class="left">
                        <div ngbDropdown class="d-inline-block" *ngIf="markersForFiltering.length > 0">
                              <button type="button" class="btn btn-outline-secondary" id="dropdownFiltering" ngbDropdownToggle (mouseenter)="isHoveringFilteringButton = true" (mouseleave)="isHoveringFilteringButton = false">
                                    <img src="assets/icons/filter_alt_gray_24dp.svg" class="map-header-icon" *ngIf="!isHoveringFilteringButton">
                                    <img src="assets/icons/filter_alt_white_24dp.svg" class="map-header-icon" *ngIf="isHoveringFilteringButton">
                                    {{'planner.mapView.filtering' | translate}}
                              </button>
                              <div  ngbDropdownMenu aria-labelledby="dropdownFiltering">
                                    <button class="d-flex justify-content-between w-100 btn-secondary" ngbDropdownItem (click)="filterMap(i, false)" *ngFor="let item of filteringOptions; let i = index">
                                          <span><input type="checkbox" [checked]="item.checkboxValue"> {{item.description}}</span>
                                          <span class="ms-5">({{item.amount}})</span>
                                    </button>
                              </div>
                        </div>
                        <span *ngIf="!hasMultipleCheckboxesSelected()" class="text-small">
                              <span *ngIf="filteringOptions[1].checkboxValue" class="ms-3 light-background">
                                    <span class="mr-1">{{'planner.mapView.filteringList.notStarted' | translate}} ({{filteringOptions[1].amount}})</span>
                                    <button class="btn btn-sm btn-secondary margin-icon" (click)="filterMap(1, false)">X</button>
                              </span>
                              <span *ngIf="filteringOptions[2].checkboxValue" class="ms-3 light-background">
                                    <span class="mr-1">{{'planner.mapView.filteringList.scheduled' | translate}} ({{filteringOptions[2].amount}})</span>
                                    <button class="btn btn-sm btn-secondary margin-icon" (click)="filterMap(2, false)">X</button>
                              </span>
                              <span *ngIf="filteringOptions[3].checkboxValue" class="ms-3 light-background">
                                    <span class="mr-1">{{'planner.mapView.filteringList.started' | translate}} ({{filteringOptions[3].amount}})</span>
                                    <button class="btn btn-sm btn-secondary margin-icon" (click)="filterMap(3, false)">X</button>
                              </span>
                              <span *ngIf="filteringOptions[4].checkboxValue" class="ms-3 light-background">
                                    <span class="mr-1">{{'planner.mapView.filteringList.done' | translate}} ({{filteringOptions[4].amount}})</span>
                                    <button class="btn btn-sm btn-secondary margin-icon" (click)="filterMap(4, false)">X</button>
                              </span>
                              <span *ngIf="filteringOptions[5].checkboxValue" class="ms-3 light-background">
                                    <span class="mr-1">{{'planner.mapView.filteringList.doneExtraWork' | translate}} ({{filteringOptions[5].amount}})</span>
                                    <button class="btn btn-sm btn-secondary margin-icon" (click)="filterMap(5, false)">X</button>
                              </span>
                              <span *ngIf="filteringOptions[6].checkboxValue" class="ms-3 light-background">
                                    <span class="mr-1">{{'planner.mapView.filteringList.interrupted' | translate}} ({{filteringOptions[6].amount}})</span>
                                    <button class="btn btn-sm btn-secondary margin-icon" (click)="filterMap(6, false)">X</button>
                              </span>
                        </span>
                        <span *ngIf="hasMultipleCheckboxesSelected()" class="text-small">
                              <span class="ms-3 light-background">
                                    <span class="mr-1">{{'planner.mapView.manyFilteringOptions' | translate}} ({{checkTotalAmount()}})</span>
                                    <button class="btn btn-sm btn-secondary margin-icon" (click)="clearFilter(false)">X</button>
                              </span>
                        </span>
                  </div>
                  <div *ngIf="!msaMode" class="float-end right">
                        <button id="show-all-btn" class="btn btn-secondary map-btn map-btn-right" (click)="showAllMeters(); filteringOptions[0].checkboxValue = true" *ngIf="!showAllToggle"><img src="assets/icons/location_on_gray_24dp.svg" class="map-header-icon">{{'mapHeader.showAllMetersBtn' | translate }}</button>
                        <button id="show-all-btn" class="btn btn-secondary map-btn map-btn-right" (click)="getProjects(); clearFilter(true); filteringOptions[0].checkboxValue = true" *ngIf="showAllToggle"><img src="assets/icons/location_on_gray_24dp.svg" class="map-header-icon">{{'mapHeader.showAllGroupsBtn' | translate }}</button>   
                        <button id="move-obj-btn" *ngIf="!msaModeOn" class="btn btn-secondary map-btn map-btn-right" (click)="toggleEditor('edit')"><img src="assets/icons/edit_gray_24dp.svg" class="map-header-icon">{{'mapHeader.moveObjectsBtn' | translate }}</button>
                  </div>
                  <div *ngIf="msaMode">
                        <div *ngIf="!editingMode">
                              <div class="float-end right mr-5">
                                    <button *ngIf="!editMsaMode" class="btn btn-secondary map-btn map-btn-right" (click)="createNewMSA()">{{'mapHeader.createMSAButton' | translate }}</button>
                                    <button *ngIf="!editMsaMode && showCancelCreate" class="btn btn-secondary map-btn map-btn-right" (click)="cancelDrawing()">{{'planner.mapView.cancelDrawing' | translate}}</button>
                                    <button *ngIf="editMsaMode" class="btn btn-secondary map-btn map-btn-right" (click)="editMSA()">{{'mapHeader.editMSAButton' | translate }}</button>
                              </div>
                        </div>
                        <div *ngIf="editingMode">
                              <button class="btn btn-secondary map-btn map-btn-right" (click)="cancelEditMsa()">{{'planner.mapView.cancelEditMode' | translate}}</button>
                              <span *ngIf="!editAreaBoolean">
                                    <button class="btn btn-secondary map-btn map-btn-left me-2" (click)="deleteMsa()">{{'mapHeader.deleteMsaButton' | translate }}</button>
                                    <button class="btn btn-secondary map-btn map-btn-left" (click)="editArea()">{{'mapHeader.editAreaButton' | translate }}</button>
                                    <!-- Show edit date range, edit range hover and button for editing date range starts-->
                                    <button class="btn btn-secondary ms-2" (mouseover)="showInformationDateRange = true" (mouseout)="showInformationDateRange = false" (click)="editDateRange()"><img class="date-range-size" src="assets/icons/date_range_gray_48.svg"></button>
                                    <div *ngIf="showEditDateRange">
                                          <div class="up-arrow-date-range white-arrow"></div>
                                          <div class="date-range-popup">
                                                <div class="form-group">
                                                      <div class="fw-bold mb-2">{{'planner.mapView.msaSchedule' | translate}}</div>
                                                      <div class="row">
                                                            <div class="d-flex flex-column col-6">
                                                                <label for="editDateStart" class="control-label gray-text">{{'planner.mapView.starts' | translate}}</label>
                                                                <input type="date" class="form-control fit-content" [(ngModel)]="msaDateStartEdit" [ngModelOptions]="{standalone: true}" id="editDateStart">
                                                            </div>
                                                            <div class="d-flex flex-column col-6">
                                                                  <label for="editDateEnd" class="control-label gray-text">{{'planner.mapView.ends' | translate}}</label>
                                                                  <input type="date" class="form-control fit-content" [(ngModel)]="msaDateEndEdit" [ngModelOptions]="{standalone: true}" id="editDateStart">
                                                            </div>
                                                            <div>
                                                                  <button class="btn btn-primary mt-3" (click)="saveChangesMsaDates()">{{'basic.save' | translate }}</button>
                                                                  <button class="btn btn-secondary mt-3 ms-2" (click)="cancelChangesMsaDates()">{{'basic.cancel' | translate }}</button>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div *ngIf="showInformationDateRange">
                                          <div class="up-arrow-date-range black-arrow"></div>
                                          <div class="edit-schedule">{{'planner.mapView.editSchedule' | translate}}</div>
                                    </div>
                                    <!-- Show edit date range ends -->
                              </span>
                              <span *ngIf="editAreaBoolean">
                                    <span class="gray-text ms-5">{{'planner.mapView.editAreaInfo' | translate}}</span>
                                    <button class="btn btn-primary map-btn map-btn-right" (click)="msaAreaReadjustedAndClickedDone()">{{'basic.done' | translate}}</button>
                              </span>
                        </div>
                  </div>
            </div>
            <div id="map-header" class="map-header dual-container" [hidden]="!positionEditor">
                  <div class="left">
                        <button class="btn btn-secondary map-btn" (click)="toggleEditor('cancel')">{{'mapHeader.undoBtn' | translate }}</button>
                  </div>
                  <div class="right">
                        <button class="btn btn-secondary map-btn map-btn-right" (click)="toggleEditor('cancel')"><img src="assets/icons/close_gray_24dp.svg" class="map-header-icon">{{'basic.cancel' | translate }}</button>  
                        <button class="btn btn-primary map-btn map-btn-right" (click)="savePositionUpdateQueue()"><img src="assets/icons/check_white_24dp.svg" class="map-header-icon">{{'basic.save' | translate }}</button>      
                  </div>
            </div>
      </div> 
      <div id="map" class="map-container structure-child" [style.width]="mapWidth"></div> 
      <!-- Map nav sidebar component for map -->
      <app-map-nav 
      [hidden]="!mapNavOpen"
      id="map-sidebar" 
      (closeNavToParent)=closeNavBtn()
      (updateOrderToParent)=updateProjectId()
      [groupStatus]="groupStatus"
      [meterId]="meterId"
      [identifier]="identifier"
      [groupId]="groupId"
      [address]="address"
      [notes]="notes"
      [locationNumber]="locationNumber"
      [groupping]="groupping"
      [msaMode]="msaMode"
      [markersInsidePolygon]="markersInsidePolygon"
      [msaTitle]="msaTitle"
      (clickShowAllMeters)="showAllMetersFromMapNav($event)"
      [totalTimeMarkers]="totalTimeMarkers"
      [orderTimeEstimate]="orderTimeEstimate"
      [contractorEstimates]= "contractorEstimates"
      [allEstimates]="allEstimates"
      [msaEstimates]="msaEstimates"
      (notesChanged)="notesChanged($event)"
      (addressChanged)="addressChanged($event)"
      (locationNumberChanged)="locationNumberChanged($event)"
      [totalMetersCount]="totalMetersCount"
      [msaStartDate]="msaStartDateToMapNav"
      [msaEndDate]="msaEndDateToMapNav"
      [mapNavSpinner]="mapNavSpinner"
      [oldMeterId]="oldMeterId"
      ></app-map-nav> 

      <button [hidden]="true" id="saveMsa" data-bs-toggle="modal" data-bs-target="#saveMsaModal"></button>
      <button [hidden]="true" id="nameForMsa" data-bs-toggle="modal" data-bs-target="#nameForMsaModal"></button>
      <button [hidden]="true" id="showMsaCalendarButton" data-bs-toggle="modal" data-bs-target="#changeCalendarMsa"></button>

       <!-- Modal nameForMsa -->
       <div class="modal fade" id="nameForMsaModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4>{{'planner.mapView.newMsa' | translate}}</h4>
                        <form ngNativeValidate (ngSubmit)="confirmOverlay('save')">
                              <div class="form-group">
                                    <div class="d-flex flex-column">
                                        <label for="msaName" class="control-label gray-text">{{'planner.mapView.msaName' | translate}}</label>
                                        <input type="text" [(ngModel)]="msaName" [ngModelOptions]="{standalone: true}" class="form-control fit-content" id="msaName" required>
                                    </div>
                              </div>
                              <div class="gray-text">{{'planner.mapView.setMsaSchedule' | translate}}</div>
                              <div *ngIf="datesWrong" class="red-text">{{'planner.mapView.scheduleError' | translate}}</div>
                              <div class="form-group">
                                    <div class="row">
                                          <div class="d-flex flex-column col-6">
                                              <label for="dateStart" class="control-label gray-text">{{'planner.mapView.starts' | translate}}</label>
                                              <input type="date" class="form-control fit-content" [(ngModel)]="MSADateStartCreate" [ngModelOptions]="{standalone: true}" required id="dateStart">
                                          </div>
                                          <div class="d-flex flex-column col-6">
                                                <label for="dateEnd" class="control-label gray-text">{{'planner.mapView.ends' | translate}}</label>
                                                <input type="date" class="form-control fit-content" [(ngModel)]="MSADateEndCreate" [ngModelOptions]="{standalone: true}" required id="dateStart">
                                          </div>
                                    </div>
                              </div>
                              <button class="btn btn-primary" type="submit" id="confirmButton">{{'basic.save' | translate}}</button>
                              <button class="btn btn-secondary" data-bs-dismiss="modal" (click)="confirmOverlay('cancel')">{{'basic.cancel' | translate}}</button>
                              <button [hidden]="true" id="closeModal" data-bs-dismiss="modal"></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal nameForMsa end -->

       <!-- Modal saveMsa -->
       <div class="modal fade" id="saveMsaModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-sm modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3>{{'planner.mapView.saveNewMsa' | translate}}</h3>
                        <button class="btn btn-primary me-2 mb-2" data-bs-dismiss="modal" (click)="overlay('yes')">{{'basic.yes' | translate}}</button>
                        <button class="btn btn-secondary mb-2" data-bs-dismiss="modal" (click)="overlay('no')">{{'basic.no' | translate}}</button><br>
                        <button class="btn btn-secondary" data-bs-dismiss="modal" (click)="overlay('continue')">{{'planner.mapView.continueDrawing' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal saveMsa end -->

        <!-- Modal for confirmation of removing calendars -->
        <div class="modal fade" id="changeCalendarMsa" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-sm modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h5>{{'planner.mapView.confirmDelete' | translate}}</h5>
                        <button class="btn btn-danger me-2 mb-2" data-bs-dismiss="modal" (click)="deleteMsaFinal()">{{'basic.remove' | translate}}</button>
                        <button class="btn btn-secondary mb-2" data-bs-dismiss="modal">{{'basic.cancel' | translate}}</button><br>
                    </div>
                </div>
            </div>
        </div>
</div>