{
    "available_barcodes": [
        {
            "code": "code_128",
            "description": "Code 128 (ISO/IEC 15417:2007)",
            "selected": false
        },
        {
            "code": "code_39",
            "description": "Code 39 (Alpha39, Code 3 of 9, Code 3/9, Type 39, USS Code 39, or USD-3)",
            "selected": false
        },
        {
            "code": "code_39_vin",
            "description": "Code 39-VIN (Vehicle Identification Number)",
            "selected": false
        },
        {
            "code": "code_93",
            "description": "Code-93 (extension for Code-39)",
            "selected": false
        },
        {
            "code": "ean",
            "description": "EAN-13 (European Article Number)",
            "selected": false
        },
        {
            "code": "ean_8",
            "description": "EAN-8",
            "selected": false
        },
        {
            "code": "upc",
            "description": "UPC-A (Universal Product Code)",
            "selected": false
        },
        {
            "code": "upc_e",
            "description": "UPC-E",
            "selected": false
        },
        {
            "code": "codabar",
            "description": "Codabar (Codeabar, Ames Code, NW-7, Monarch, Code 2 of 7, Rationalized Codabar, ANSI/AIM BC3-1995 or USD-4)",
            "selected": false
        },
        {
            "code": "i2of5",
            "description": "ITF (Interleaved 2 of 5, 2 of 5)",
            "selected": false
        },
        {
            "code": "2of5",
            "description": "Code 25",
            "selected": false
        }
    ]
}