import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-weekly-planning-teams',
  templateUrl: './weekly-planning-teams.component.html',
  styleUrls: ['./weekly-planning-teams.component.scss'],
  standalone: false
})
export class WeeklyPlanningTeamsComponent implements OnInit {

  teamName;
  efficiency;
  efficiencyValue;
  innerColor;
  outerColor;
  workersCount;
  spinner = true;
  spinner2 = true;
  allCalendars = Array();
  currentCalendar: any = null;
  showSwitchCalendar = false;
  currentFilter = {text: 'weeklyPlanningTeams.selectFilter', workorders: []}

  @Input() teamId;
  @Input() calendarId;
  @Input() filters: any = []
  @Input() filterIdChangedFromMap: boolean = false
  
  @Output() calendarChanged = new EventEmitter<any>();
  @Output() teamSize: EventEmitter<number> =   new EventEmitter();
  @Output() filterId: EventEmitter<number> = new EventEmitter()

  constructor(
    private router: Router,
    private workersService: WorkersService,
    private calendars: CalendarsService,
    private translateService: TranslateService
  ) { }

  
  

  ngOnInit(): void {
    // Show switch calendar option if url is correct.
    if (this.router.url.includes('weekly-planning-map')) this.showSwitchCalendar = true;
    this.getCalendars()
    let currentLang = localStorage.getItem('userlanguage')
    if (currentLang) this.translateService.use(currentLang)
    this.translateService.get(this.currentFilter.text).subscribe(key => this.currentFilter.text = key)
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange}) {
    // Run if team id changes. Initializes weekly-planning-teams
    if (changes['teamId'] && changes['teamId'].currentValue !== changes['teamId'].previousValue) {
      if (this.teamId) {
        this.getWorkerteam(this.teamId)
      } else if (this.teamId === null){
        this.teamName = this.translateService.instant('teams.noTeam')
        this.workersCount = 0;
        this.spinner = false;
        this.getCalendars()
      }
    }
    if (changes['filterIdChangedFromMap']) this.filterChangedFromMap(changes.filterIdChangedFromMap.currentValue);
  }

  /**
   * Navigate back to dashboard (weekly-planning main page)
   */
  backToDashboard() {
    this.router.navigate(['planner/weekly-planning'])
  }

  /**
   * Get workerteam with teamId that we get from weekly-planning-map or weekly-planning-details.
   * @param teamId 
   */
  getWorkerteam(teamId) {
    this.workersService.getWorkerteam(teamId)
    .pipe(
      finalize(() => {
        this.spinner = false;
      })
    )
    .subscribe(
      data => {
        this.teamName = data.name
        this.workersCount = data.workerscount
        this.teamSize.emit(data.workerscount);
        this.efficiencyValue = data.efficiency
        // Set color to green
        if (data.efficiency >= 1.0) {
          this.efficiency = (data.efficiency - 1) * 100
          this.innerColor = '#10A231',
          this.outerColor = '#05791F'
        }
        // Sst color to red 
        else {
          this.efficiency = data.efficiency * 100
          this.innerColor = '#ffcccb',
          this.outerColor = '#FF0000'
        }
        this.getCalendars()
      }
    )
  }

  /**
   * Get all calendars from contractor
   */
  getCalendars() {
    this.calendars.getCalendars().subscribe(
      data => {
        this.allCalendars = Array()
        for (let i = 0; i < data.length; i++) {
          this.allCalendars.push(data[i])
        }
        for (let i = 0; i < this.allCalendars.length; i++) {
          if (this.allCalendars[i].id == this.calendarId) {
            this.currentCalendar = this.allCalendars[i].name
            break;
          }
        }
        this.spinner2 = false
      }
    )
  }

  /** 
   * Change calendar in interface. Fire "emit" when calendar has been changed so 
   * that we can update calendar in weekly-planning-map.
   * Set name of calendar also in interface.
   */
  changeCalendar(id) {
    for (let i = 0; i < this.allCalendars.length; i++) {
      if (this.allCalendars[i].id == id) {
        this.currentCalendar = this.allCalendars[i].name
        let calendarId = this.allCalendars[i].id
        this.calendarChanged.emit({calendarId})
        break;
      }
    }
  }

  /**
   * Switch weekly-planning-map filter for workorders on map.
   * @param filter 
   */
  switchFilter(filter) {
    this.currentFilter = filter
    this.filterId.emit(filter.id)
  }

  filterChangedFromMap(changed: boolean) {
    if (changed) this.currentFilter = {text: this.translateService.instant('weeklyPlanningTeams.selectFilter'), workorders: []}
  }

}
