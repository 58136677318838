import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@shared/models/languages';
import { UserService } from '@shared/services/user/user.service';
import { forkJoin, of, Subject, takeUntil } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import languages from 'assets/i18n/language-list.json'
import Swal from "sweetalert2";
import { ToastService } from "@shared/services/toast/toast.service";
import { TeamsService } from '@modules/planner/services/teams/teams.service';


@Component({
  selector: 'app-workers',
  templateUrl: './workers.component.html',
  styleUrls: ['./workers.component.scss'],
  standalone: false
})
export class WorkersComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()
  loading = true

  skills;
  name;
  public searchFilter: any = '';
  worker = { name: "", qualifications: Array(), tags: Array(), contractors: Array(), workerteams: Array(), efficiency: 0, hasLeadInstaller: false, efficiencyValue: 0, id: Number, colorInner: "", colorOuter: "", userId: null };
  workers = Array();
  newName;
  newEfficiency;
  allWorkerTeams = Array();
  personFirstName;
  personLastName;
  regions;
  subcontractor = false;
  email;
  postCode;
  address;
  efficiencyFactor = 1.0;
  username;
  phoneNumber;
  workingHours = Array();
  contractorId;
  allSkills = Array();
  skillsToAdd = Array();
  confirmationForResettingValues = true;
  subcontractorValue = 0;
  selectedSkill;
  selectTextStyle = { ['color']: '#707591' }
  newSkill;
  realClose = true;
  addedSkills = Array();
  showProfessionalInformation = false;
  postalDistrict;
  imageProfessionalInformation = "assets/icons/expand_more_black_24dp.svg";
  isWindowTooSmall = false;

  // New region and country
  workerCountry: string = ""
  workerRegion: string = ""

  // Base for days in working hours
  days = [
    { name: 'monday', start: null, end: null, checked: false, englishName: 'Mon' },
    { name: 'tuesday', start: null, end: null, checked: false, englishName: 'Tue' },
    { name: 'wednesday', start: null, end: null, checked: false, englishName: 'Wed' },
    { name: 'tuesday', start: null, end: null, checked: false, englishName: 'Thu' },
    { name: 'friday', start: null, end: null, checked: false, englishName: 'Fri' },
    { name: 'saturday', start: null, end: null, checked: false, englishName: 'Sat' },
    { name: 'sunday', start: null, end: null, checked: false, englishName: 'Sun' },
  ]

  interfaceLanguages: Array<Language> = []
  workerLanguage: string = "fi"

  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef> | undefined;
  all_selected_workers = Array();
  contractors = Array();
  chosenContractor;
  saving: boolean = false

  customerId;
  phantomTeamName = "";

  constructor(
    private router: Router,
    private workersService: WorkersService,
    private userService: UserService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private teamsService: TeamsService
  ) { }

  ngOnInit() {

    this.getContractorId();
    this.getContractors();

    let firstModal = document.getElementById('workerModal')
    firstModal?.addEventListener('hidden.bs.modal', (event) => {
      if (this.realClose == true) this.resetVariables()
    })

    this.setEventListenersForSecondModal()
    if (window.innerWidth < 1000) this.isWindowTooSmall = true;
    this.getTranslations()
    this.getCustomer();
  }


  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 1000) this.isWindowTooSmall = true;
    else this.isWindowTooSmall = false;
  }

  getContractors() {
    // this.contractors = [{id:0,name:'Loading'}]
    this.userService.getAllContractors()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.contractors = data
        }
      )
  }

  /**
   * Set names according to translation of weekdays for working hours table.
   * Set languages to correct display name.
   * @author Jesse Lindholm
   *
   * 16.11.2022
   * Updated languages to use JSON-languages from JSON-file.
   */
  getTranslations() {
    this.translateService.get('basic.weekDays.monday').subscribe(
      () => {
        this.days.forEach(day => {
          day.name = this.translateService.instant(`basic.weekDays.${day.name}`)
        })
        languages.languages.forEach(language => {
          if (this.translateService.instant(`languages.${language}`) !== `languages.${language}`) this.interfaceLanguages.push({ databaseLanguage: language, displayName: this.translateService.instant(`languages.${language}`) })
          else this.interfaceLanguages.push({ databaseLanguage: language, displayName: `No translation (${language})` })
        })
      }
    )
  }

  /**
   * Get workers from database to make a base for page
   * Go through values of data to put correct data to correct variables
   * Finally make a single new worker for every data value in database
   */
  getWorkers() {
    this.workers = Array()
    this.workersService.getWorkers(1)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
            
            this.worker = { name: "", qualifications: Array(), tags: Array(), contractors: Array(), workerteams: Array(), efficiency: 0, hasLeadInstaller: false, efficiencyValue: 0, id: Number, colorInner: "", colorOuter: "", userId: null };
            this.worker.userId = data[i].user_id
            this.worker.name = data[i].firstname + " " + data[i].lastname
            if (data[i].tags) this.worker.tags = JSON.parse(data[i].tags);
            this.setSkills(data[i].qualifications)
            if (data[i].efficiency == null) {
              this.worker.efficiency = 0
              this.worker.efficiencyValue = 0.0
            } else {
              this.worker.efficiency = (data[i].efficiency * 100)
              this.worker.efficiencyValue = data[i].efficiency
            }
            this.setContractors(data[i].contractors)
            this.setWorkerteams(data[i].workerteams)
            this.worker.id = data[i].id
            if (data[i].efficiency >= 1.0) {
              this.worker.colorInner = '#10A231'
              this.worker.colorOuter = '#05791F'
              this.worker.efficiency = (data[i].efficiency - 1) * 100
            } else {
              this.worker.colorOuter = '#FF0000'
              this.worker.colorInner = '#ffcccb'
            }
            this.workers.push(this.worker)
          }
          this.skills = this.workers;
          this.selectedSkill = undefined;
          this.selectTextStyle = { ['color']: '#707591' }
          this.loading = false
          this.sortSkills()
        }
      )
  }

  /**
   * Go through contractors and get the values and save the values to current worker
   * @param contractorsJSON contractors from database
   */
  setContractors(contractorsJSON) {
    let contractors = Array()
    let id = 0;

    for (let i = 0; i < Object.keys(contractorsJSON).length; i++) {
      contractors.push(Object.values(contractorsJSON)[i])
    }
    // Set workerteams to current worker
    this.worker.contractors = contractors;
  }

  /**
   * Go through workerteams and get the values and save the values to current worker
   * @param workerteamsJSON workerteams from database
   */
  setWorkerteams(workerteamsJSON) {
    let workerteams = Array()
    let id = 0;
    for (let i = 0; i < Object.keys(workerteamsJSON).length; i++) {
      workerteams.push(Object.values(workerteamsJSON)[i])
    }
    // Set workerteams to current worker
    this.worker.workerteams = workerteams;

    // Loop through workerteams to check if they exist in allWorkerteams variable
    // If does not exist add workerteam to allWorkerTeams variable for further usage
    for (let j = 0; j < workerteams.length; j++) {
      let isInArray = false;
      for (let x = 0; x < this.allWorkerTeams.length; x++) {
        if (workerteams[j] == this.allWorkerTeams[x]) isInArray = true;
      }
      if (isInArray == false) {
        let jsonVariable = {}
        jsonVariable[id] = workerteams[j]
        this.allWorkerTeams.push(jsonVariable)
        id++;
      }
    }
  }

  /**
   * Set skills to allSkills variable for further usage
   * Also set skills for worker qualifications
   * @param qualifications skills from database
   */
  setSkills(qualifications) {
    let skills = Array()
    // Don't run if qualifications is null
    if (qualifications != null) {
      // Parse through qualifications (skills)
      try {
        let parsedQualifications = JSON.parse(qualifications)

        // Loop through skills
        for (let i = 0; i < parsedQualifications.length; i++) {
          // Push skill to local variable skills
          if (parsedQualifications[i].name != null) skills.push(parsedQualifications[i].name)
          else skills.push(parsedQualifications[i])
          let exist = false;
          // Loop through all skills to check if current skill exists there
          for (let j = 0; j < this.allSkills.length; j++) {
            // Comparison -> make exist true
            if (parsedQualifications[i] == this.allSkills[j].name || parsedQualifications[i].name == this.allSkills[j].name) {
              exist = true;
              break;
            }
          }
          // If skill does not exist in all skills, push it to all skills
          if (!exist) {
            if (parsedQualifications[i].name != null) this.allSkills.push({ name: parsedQualifications[i].name, checked: false })
            else this.allSkills.push({ name: parsedQualifications[i], checked: false })
          }
          // If contains lead installer exists add it to current worker
          if (parsedQualifications[i] == "Lead installer" || parsedQualifications[i].name == "Lead installer") this.worker.hasLeadInstaller = true;
        }
      } catch (e) {
        // console.log(e)
      }
    }
    // Set local variable skills to current worker
    this.worker.qualifications = skills;
  }

  /**
   * Navigate to correct worker detail page
   */
  goToWorkerDetail(event, id) {
    event.stopPropagation();
    let uri = "planner/worker-detail/" + id;
    this.router.navigate([uri])
    return false;
  }

  /**
   * Called when clicked 'save' on modal for making a new worker
   * Push days to workingHours
   * Close modal
   * Show user a sweetalert pop up for giving user username and password
   * Make api calls if pop up has been clicked with save and not if has been clicked with cancel
   * Reset variables
   *
   * 31.10.2022
   * Added language to fullworker api-call
   * Save button disabled after submit to prevent multiple clicks
   * @author Jesse Lindholm
   */
  addNewWorker() {

    // Disable save button briefly to prevent adding worker more than once
    let saveButton = <HTMLButtonElement>document.getElementById('modalSaveButton')
    saveButton.disabled = true

    // Add days to workingHours variable so we can send saved working hours to database
    // Push a day to workingHours if it has been selected with checkbox in UI. Times have default value of null if
    // not given
    for (let i = 0; i < this.days.length; i++) {
      if (this.days[i].checked) this.workingHours.push({ day: this.days[i].englishName, start: this.days[i].start, end: this.days[i].end })
    }

    // Close modal when this is run by clicking modal close
    this.confirmationForResettingValues = true;
    let button = document.getElementById('modalClose')
    button?.click();

    if (this.efficiencyFactor == null) this.efficiencyFactor = 1.0;
    // Default language to German if not given
    if (this.workerLanguage === "") this.workerLanguage = 'fi'
    this.phantomTeamName = this.personFirstName + " " + this.personLastName;
    this.workersService.fullWorker(
      this.contractorId,
      this.personFirstName,
      this.personLastName,
      this.email,
      this.skillsToAdd,
      this.workingHours,
      this.efficiencyFactor,
      this.phoneNumber,
      this.subcontractor,
      this.workerLanguage
    )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          // Automatically create a phantom team with the same name as the worker for our German customer
          // As they do not use our teams in their planning

          if (this.customerId === 3 && data.worker_id && this.phantomTeamName) {
            this.createPhantomTeam(data.worker_id, this.phantomTeamName);
          }
          this.workers = Array();
          this.getWorkers();
          saveButton.disabled = false

        }
      )



  }

  createPhantomTeam(workerId: any, teamName: any) {
    this.teamsService.createTeam(teamName).subscribe(
      (data) => {
        this.workersService.setWorkerToWorkerteam(workerId, data).subscribe();
      }
    );
  }

  /** 
   * Reset variables after changes have been cancelled or saved
   */
  resetVariables() {
    if (this.confirmationForResettingValues == true) {
      this.personFirstName = null;
      this.personLastName = null;
      this.username = null;
      this.email = null
      this.postalDistrict = null;
      this.regions = null;
      this.workingHours = Array();
      this.phoneNumber = null;
      this.address = null;
      this.postCode = null;
      this.efficiencyFactor = 1.0;
      this.subcontractor = false;
      this.skillsToAdd = Array();
      this.subcontractorValue = 1;
      this.showProfessionalInformation = false;
      if (this.addedSkills != null) {
        for (let x = 0; x < this.addedSkills.length; x++) {
          for (let z = 0; z < this.allSkills.length; z++) {
            if (this.allSkills[z].name == this.addedSkills[x] && this.allSkills[z].checked == false) {
              this.allSkills.splice(z, 1);
            }
          }
        }
      }
      this.addedSkills = Array();
      for (let i = 0; i < this.allSkills.length; i++) {
        this.allSkills[i].checked = false;
      }
      // Reset days
      for (let i = 0; i < this.days.length; i++) {
        this.days[i].checked = false;
        this.days[i].start = null;
        this.days[i].end = null;
      }
    } else {
      this.confirmationForResettingValues = true;
    }
    // Reset new variable workerLanguage
    this.workerLanguage = ""
    // Reset country and region
    this.workerCountry = ""
    this.workerRegion = ""
  }

  /**
   * Get contractor id. Ran when opening page so we have access to it after opening page immediatly
   */
  getContractorId() {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.contractorId = data.current_contractor;
          this.getWorkers();
        }
      );
  }

  /**
   * Add skill to selected skills list in UI in new worker addition window
   * @param index id of clicked row
   */
  addSkill(index) {
    let boolean = false
    for (let i = 0; i < this.skillsToAdd.length; i++) {
      if (this.skillsToAdd[i].name == this.allSkills[index].name) {
        boolean = true;
        this.skillsToAdd.splice(i, 1);
      }
    }
    if (boolean == false) this.skillsToAdd.push(this.allSkills[index])
  }

  setSubcontractorValue() {
    if (this.subcontractor == false) {
      this.subcontractorValue = 1;
    }
    if (this.subcontractor == true) {
      this.subcontractorValue = 0;
    }
  }

  skillChanged(value) {
    if (value != 'undefined') {
      this.selectTextStyle = { ['color']: 'black' }
      let newList = Array()
      for (let i = 0; i < this.skills.length; i++) {
        for (let j = 0; j < this.skills[i].qualifications.length; j++) {
          if (this.skills[i].qualifications[j] == value) newList.push(this.skills[i])
        }
      }
      this.workers = newList;
    }
    else {
      this.selectTextStyle = { ['color']: '#707591' }
      this.workers = this.skills;
    }
  }

  createNewSkill() {

    this.allSkills.push({ name: this.newSkill, checked: false })
    this.addedSkills.push(this.newSkill)

    let button = document.getElementById('modalCloseNewSkill')
    button?.click();
    this.newSkill = null;
  }

  resetNewSkill() {
    this.newSkill = null;
  }

  setEventListenersForSecondModal() {
    let secondModal = document.getElementById('createNewSkillModal')
    if (secondModal?.getAttribute('listener') !== 'true') {
      secondModal?.addEventListener('hidden.bs.modal', (event) => {
        secondModal?.setAttribute('listener', 'true');
        this.resetNewSkill()
        this.realClose = true;
        let workerButton = document.getElementById('newWorker')
        workerButton?.click();

      });
      secondModal?.addEventListener('show.bs.modal', (event) => {
        secondModal?.setAttribute('listener', 'true');
        this.realClose = false;
      })
    }
  }

  toggleProfessionalInformation() {
    if (this.showProfessionalInformation == false) {
      this.showProfessionalInformation = true;
      this.imageProfessionalInformation = "assets/icons/expand_less_black_24dp.svg";
    }
    else {
      this.showProfessionalInformation = false;
      this.imageProfessionalInformation = "assets/icons/expand_more_black_24dp.svg";
    }

  }

  /**
   * Change workers language to parameters value
   * @param language chosen language to new worker
   */
  languageChange(language: string) {
    this.workerLanguage = language
  }

  /**
   * Sort skills
   * @param order optional, 1 for reversed sort
   */
  sortSkills(order: number = 0) {
    if (order == 1) {
      // desc
      this.allSkills.sort((a, b) => b.name.localeCompare(a.name))
    } else {
      //asc
      this.allSkills.sort((a, b) => a.name.localeCompare(b.name))
    }
  }

  onChange(userId) {
    console.log(userId)
    if (this.all_selected_workers.includes(userId)) {
      this.all_selected_workers = this.all_selected_workers.filter((item) => item !== userId);
    } else {
      this.all_selected_workers.push(userId);
    }
  }

  onSelected(value: string): void {
    this.chosenContractor = parseInt(value);
  }
  addContractor() {
    if (this.contractorId > 0) {
      if (this.all_selected_workers.length > 0) {
        let apiCalls: any[] = [];
        this.saving = true
        let observables = this.all_selected_workers.map(userId =>
          this.workersService.setWorkerToContractor(userId, this.chosenContractor).pipe(
            takeUntil(this.componentDestroyed$),
            map((data: any) => {
              return { success: data['success'], response: data['message'] };
            }),
            catchError(error => of({ success: false, error })) // Catch and return error info
          )
        );

        forkJoin(observables).subscribe(results => {
          // Check if all calls were successful
          const allSuccessful = results.every(result => result.success);

          if (allSuccessful) {
            // All API calls were successful
            this.toastService.sendToast(true, this.translateService.instant('workers.contractorAdded'));
            this.getWorkers();
          } else {
            this.toastService.sendToast(false, this.translateService.instant('workers.notAllcontractorNotAdded'));
            // Handle the case where one or more calls failed
            // todo alert success / error / partial success
            // inspect the `results` array to find out which calls failed and why
            // console.log(results)

            // todo case 400: err_link_exists
          }

          // TODO if calls fail, the checked elements are unchecked, but cant be checked again? (appear as selected, but gives error no workers selected)
          // @ts-ignore
          this.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });

          this.saving = false
        });
      } else {
        Swal.fire(this.translateService.instant('workers.noWorkersSelected'))
      }
    } else {
      Swal.fire(this.translateService.instant('planners.noContractorSelected'))
    }
  }

  getCustomer() {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.customerId = data.customer_id;
      })
  }

}



