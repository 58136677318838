import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { HeaderSidebarService } from '@shared/services/header/headerSidebarService';
import { UserService } from '@shared/services/user/user.service';
import { Subject, Subscription, takeUntil } from 'rxjs';

/**
 * This is component for header in application. This component fetches data
 * with different api calls to make a display of current contractor user is using
 * and shows all other contractors user has. User can change contractor from list
 * @author Jesse Lindholm
 * @editedDate 23.7.2021
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false
})
export class HeaderComponent implements OnInit {




  contractors
  currentContractorName;
  currentContractorNumber;
  currentContractorId
  initials;
  firstName;
  userInformation;
  currentUser;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let element = document.getElementById('main')
    const hasClass = element?.classList.contains('media-query-enabled');
    if (element && !hasClass) this.renderer.addClass(element, 'media-query-enabled')

    let elementContractor = document.getElementById('contractor-dropdown')
    const hasClassContractor = elementContractor?.classList.contains('media-query-enabled-contractor-dropdown');
    if (elementContractor && !hasClassContractor) this.renderer.addClass(elementContractor, 'media-query-enabled-contractor-dropdown')
  }

  routerSubscription = new Subscription()
  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private renderer: Renderer2,
    private headerSidebarService: HeaderSidebarService
  ) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
          if (event.url == '/planner/contractor/newcontractor') {
            this.getCurrentContractor()
          }
      }
    });
    this.headerSidebarService.toggleSidebar$.subscribe(() => document.getElementById('burger-btn-toggle')?.click());
   }

  /**
   * Initializes header component by setting current contractor name and list of contractors
   */
  ngOnInit()  {
    this.getCurrentContractor();
  }

  /**
   * Destroy subcriptions to prevent memory leaks
   */
  ngOnDestroy() {
    this.routerSubscription.unsubscribe()
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  ngAfterViewInit() {
    const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const isDev = window.location.hostname.includes('dev');

    const navbarElement = document.getElementById('top-bar');
    if (navbarElement) {
      if (isLocal) {
        this.renderer.setStyle(navbarElement, 'background-color', 'yellow');
      } else if (isDev) {
        this.renderer.setStyle(navbarElement, 'background-color', 'lightgreen');
      }
    }
  }

  logout() {
    this.authService.logout()
  }

  /**
   * Set data from api call api/me to userInformation
   * Then use current contractor from gotten data to call getContractorById
   * @author Jesse Lindholm
   * @editedDate 23.7.2021
   */
  getCurrentContractor() {
    this.userService.getUserInfo()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        this.contractors = data.contractors
        for (let i = 0; i < Object.keys(data.contractors).length; i++) {
          if (Object.keys(data.contractors)[i] == data.current_contractor) {
            this.currentContractorName = Object.values(data.contractors)[i]
          }
        }
        this.currentContractorId = data.current_contractor
        this.currentUser = data.id;
        this.firstName = data.firstname;
        this.initials = data.firstname.charAt(0).toUpperCase() + data.lastname.charAt(0).toUpperCase();
      }
    );
  }



  /**
   * Go through a for loop to define correct name for current contractor to show in component
   * Send a patch api call to users/userId to update new current contractor
   * Initialize contractors variable again and remove current contractor from list to show correct list
   * @param contractorId Use this variable as correct contractor id for new current contractor name
   */
   switchContractor(contractorId) {
    // Update database with new contractor as current contractor then reload page with new information
    this.userService.updateCurrentContractor(this.currentUser, contractorId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      () => {
        this.router.navigate(['planner/dashboard'])
        .then(() => {
          window.location.reload();
        });
      }
    );
  }

   /**
   * Checks that fullname has more than one space meaning it has 2 words
   * Sets firstName variable that contains first name of user
   * Sets initials variable that contains initials in uppercase of user
   * @param fullName String that contains fullname of user
   */
  setFirstNameAndInitials(fullName) {
    if (fullName.trim().indexOf(' ') != -1) {
      let finalFirstName = fullName.split(' ').slice(0, -1).join(' ');
      let finalLastName = fullName.split(' ').slice(-1).join(' ');

      let firstNameInitial = finalFirstName.charAt(0).toUpperCase();
      let lastNameInitial = finalLastName.charAt(0).toUpperCase();

      this.firstName = finalFirstName;
      this.initials = firstNameInitial + lastNameInitial;
    } else {
      // String has less than one space meaning its one word
      this.initials = fullName.charAt(0).toUpperCase();
      this.firstName = fullName;
    }

  }

  confirmNewContractor() {
    this.router.navigate(['/planner/new-contractor'])
  }

  toggleSidebar() {
    let element = document.getElementById('main')
    const hasClass = element?.classList.contains('media-query-enabled');

    if (element && hasClass) this.renderer.removeClass(element, 'media-query-enabled')
    else if (element && !hasClass) this.renderer.addClass(element, 'media-query-enabled')

    let elementContractor = document.getElementById('contractor-dropdown')
    const hasClassContractor = elementContractor?.classList.contains('media-query-enabled-contractor-dropdown');

    if (elementContractor && hasClassContractor) this.renderer.removeClass(elementContractor, 'media-query-enabled-contractor-dropdown')
    else if (elementContractor && !hasClassContractor) this.renderer.addClass(elementContractor, 'media-query-enabled-contractor-dropdown')
  }

}
