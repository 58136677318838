import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { catchError, map, Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FieldReportingService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

  // TODO: Change handleError 'string' to better responses
  getFieldReporting(customerId) {
    const thisWeekOnly = customerId === 2;
    return this.http.get(`${baseUrl}/api/fieldreporting`, {params: {
        'thisweekonly': thisWeekOnly,
      }})
    .pipe(
      map((data) => {
        return data['message'];
      }),
      catchError(
        this.handleError(this.translateService.instant('services.gettingWorkordersFailed'), [])
      )
    )
  }

  reportWorkorder(workorderId: number, phase: number, reportType: number, reportStatus: number,  status: number, textAreaText: string, reportInterruptCode: string | null, done: boolean) {

    const date = new Date();

    // Adjust month indexing and pad with leading zeros
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const attend_time = `${year}-${month}-${day} ${hours}:${minutes}`;
    const data: WorkorderReport = {
      status_id: status,
      phase: phase,
      report_type: reportType,
      workorder_id: workorderId,
      report_status_id: reportStatus,
      interrupt_code: reportInterruptCode,
      attend_time: attend_time
    }
    if (done) data.notes = textAreaText
    else if (!done) data.interrupt_reason = textAreaText

    return this.http.post(`${baseUrl}/api/reports`, data)
    .pipe(
      map((data) => {
        this.toastService.sendToast(true, this.translateService.instant('services.reportSent'))
        return data['message']
      }),
      catchError(error => {
        if(error.error.message) {
          this.toastService.sendToast(false, this.translateService.instant('backend_errors.' + error.error.message))
        }
        return of(false);
      })
   );
  }

  /**
   * 15.12.2022
   * Get contractors barcodes with workorder ID
   * @param id Workorder ID
   * @returns Array that contains list of barcodes or empty array
   * @author Jesse Lindholm
   */
  getWorkerContractors(id: number) {
    return this.http.get(`${baseUrl}/api/workercontractors/${id}`)
    .pipe(
      map((contractors) => {
        return contractors['message']
      }),
      catchError(this.handleError(this.translateService.instant('services.workerContractorFailed')))
    )
  }


  patchWorkorders(workorders, status) {

    let body = {
      workorders: workorders,
      status_id: status
    }

    return this.http.patch(`${baseUrl}/api/workorders`, body)
    .pipe(
      map((data) => {
        this.toastService.sendToast(true, 'Success')
        return data['message']
      }),
      catchError(this.handleError('Patching workorders statuses failed'))
    )
  }

  getDoneOrders() {
    return this.http.get(`${baseUrl}/api/doneworkorders`)
    .pipe(
      map((workorders) => {
        return workorders['message']
      }),
      catchError(this.handleError(this.translateService.instant('services.workerContractorFailed')))
    )
  }

    /**
   * Handles Http operations that failed.
   * Lets the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
     private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        //console.log(error)
        //console.log(`${operation} failed: ${error.message}`);

        this.toastService.sendToast(false, error.message)


        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
}

interface WorkorderReport {
  status_id: number;
  phase: number;
  report_type: number;
  workorder_id: number;
  report_status_id: number;
  notes?: string | null;
  interrupt_reason?: string | null;
  interrupt_code: string | null;
  attend_time: string
}
