import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { baseUrl } from '@environments/environment';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  standalone: false
})
export class RegisterComponent implements OnInit {

  formGroup!: UntypedFormGroup

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.initForm()
  }


  initForm() {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required])
    })
  }

  registerProcess() {
    if(this.formGroup.valid){
      this.authService.register(`${baseUrl}/api/register`, this.formGroup.value).subscribe(result=>{
        if(result.success) {
          alert(result)
        } else {
          alert(result)
        }
      })
    }
  }
}
