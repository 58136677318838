import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@shared/models/languages';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import {finalize, forkJoin, Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import languages from 'assets/i18n/language-list.json'

@Component({
  selector: 'app-worker-detail',
  templateUrl: './worker-detail.component.html',
  styleUrls: ['./worker-detail.component.scss'],
  standalone: false
})
export class WorkerDetailComponent implements OnInit {

  componentDestroyed$: Subject<boolean> = new Subject()
  name;
  teams;
  efficiency;
  qualifications;
  // Base for working hours list
  days = [
    {name: 'monday', start: null, end: null, checked: false, englishName: 'Mon'},
    {name: 'tuesday', start: null, end: null, checked: false, englishName: 'Tue'},
    {name: 'wednesday', start: null, end: null, checked: false, englishName: 'Wed'},
    {name: 'thursday', start: null, end: null, checked: false, englishName: 'Thu'},
    {name: 'friday', start: null, end: null, checked: false,  englishName: 'Fri'},
    {name: 'saturday', start: null, end: null, checked: false,  englishName: 'Sat'},
    {name: 'sunday', start: null, end: null, checked: false,  englishName: 'Sun'}
  ]
  efficiencyValue;
  colorInner;
  colorOuter;
  phone = null;
  editMode1 = false;
  editMode2 = false;
  editMode3 = false;
  editMode4 = false; // TODO there must be a better way to do this
  dayHeight = '40px';
  newSkill = null;
  id;
  hasCheckedValue = false;
  email = null;
  employmentRelationship: any = null;
  employmentRelationshipStart = null;
  employmentRelationshipEnd = null;
  userId;
  username;
  newUsername: any = null;
  newPassword = null;
  showUsername = true;
  showPassword = true;
  createUser = true;
  personAccount = 'assets/icons/person_gray_24dp.svg';
  createUserActive = true;
  isWindowTooSmallBasic: boolean = false
  isWindowTooSmallEdit: boolean = false
  skillHeight;
  showGeneratedPassword: boolean = false
  accountDisabled: boolean = false
  editMode5 = false; // Tags
  tags = Array();
  tag;
  existingTags = Array();

  // Original values, if edit is cancelled these are used
  tempName;
  tempEfficiency;
  tempEfficiencyValue;
  tempColorInner;
  tempColorOuter;
  tempQualifications;
  tempDays;
  tempPhone;
  tempEmploymentStart;
  tempEmploymentEnd;
  tempEmail;

  // New interface language
  tempLanguage: Language = {databaseLanguage: "", displayName: ""}
  workerLanguage: Language = {databaseLanguage: "", displayName: ""}
  interfaceLanguages: Array<Language> = []

  currentContractorId = 0;
  contractors = Array();
  selectedContractors = Array();
  unselectedContractors = Array();
  saving = false;


  constructor(
    private route: ActivatedRoute,
    private workersService: WorkersService,
    private router: Router,
    private userService: UserService,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

  // Get correct userId from url route
  ngOnInit(): void {
    this.route.paramMap.subscribe(pmap =>  this.getWorker(pmap.get('id')));
    if (window.innerWidth < 950) {
      this.isWindowTooSmallBasic = true
      this.isWindowTooSmallEdit = true
    } else if (window.innerWidth < 1455) this.isWindowTooSmallEdit = true
    this.translateService.get('basic.weekDays.monday').subscribe(
      () => this.getTranslations()
    )

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 950) this.isWindowTooSmallBasic = true
    else this.isWindowTooSmallBasic = false
    if (window.innerWidth < 1455) this.isWindowTooSmallEdit = true
    else this.isWindowTooSmallEdit = false
  }

  getTranslations() {
    this.days.forEach(day => {
      day.name = this.translateService.instant(`basic.weekDays.${day.name}`)
    })
    languages.languages.forEach(language => {
      if (this.translateService.instant(`languages.${language}`) !== `languages.${language}`) this.interfaceLanguages.push({databaseLanguage: language, displayName: this.translateService.instant(`languages.${language}`)})
      else this.interfaceLanguages.push({databaseLanguage: language, displayName: `No translation (${language})`})
    })
  }


  /**
   * This function is base for all data in single worker page. We get the data
   * from api call and put them to variables to be able to use them in HTML
   * @param id workers id to perfrom api call with
   */
  getWorker(id) {
    this.workersService.getWorker(id)
    .pipe(
      finalize(() => {
        console.log(this.userId)
        this.userService.getUserInfoWithId(this.userId).subscribe(
          data => {
            if (data.disabled == 1) this.accountDisabled = true
            else this.accountDisabled = false
            if(data.tags) {
              this.existingTags = this.tags = JSON.parse(data.tags);
            }
            this.username = data.username
            if (this.username != null) {
              this.createUser = false;
              if (data.disabled == 0) this.personAccount = 'assets/icons/person_blue_24dp.svg';
              this.createUserActive = false;
            }
            if (data.language) {
              for (let i = 0; i < this.interfaceLanguages.length; i++) {
                const language = this.interfaceLanguages[i]
                if (language.databaseLanguage === data.language) this.workerLanguage = language
              }
            }

            let property: keyof typeof data.contractors;
            this.contractors = Array()
            this.unselectedContractors = Array()
            this.selectedContractors = Array()
            this.userService.getAllContractors()
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                allContractors => {
                  this.unselectedContractors = allContractors

                  for (property in data.contractors) {
                    this.contractors.push({id: property, name: data.contractors[property]})
                    if(this.unselectedContractors.find(e => e.id == property)) {
                      this.selectContractor(property)
                    }
                  }
                }
              )
          }
        )
      })
    )
    .subscribe(
      data => {
        console.log(data)
        this.userId = data.user_id;
        this.id = id;
        this.name = data.firstname + " " + data.lastname
        // Set workerteams with workerteams data
        this.setWorkerteams(data.workerteams);
        // If efficiency value is null use this if statement and set them to 0
        // Else multiply efficiency with 100 to get prosent value of it instead of 1.02 value for example
        // Needs to multiply because of addon we use to display values in bubble form
        if (data.efficiency == null) {
          this.efficiency = 0
          this.efficiencyValue = 0.0
        } else {
          this.efficiency = (data.efficiency * 100)
          this.efficiencyValue = data.efficiency
        }
        // This if statement sets green color if efficiency is more than 1 and red if it is less.
        if (data.efficiency >= 1.0) {
          this.colorInner = '#10A231'
          this.colorOuter = '#05791F'
          this.efficiency = (data.efficiency - 1) * 100
        } else {
          this.colorOuter = '#FF0000'
          this.colorInner = '#ffcccb'
        }
        this.setSkills(data.qualifications)
        if (data.working_hours) this.setWorkingHours(data.working_hours)
        this.phone = data.phone;
        this.email = data.email;
        // Set employment relationship if its not null
        if (data.employment_relationship != null) {
          let tempEmploymentRelationship = data.employment_relationship;
          // Split by / which is used to separate start and end in database
          let splittedString = tempEmploymentRelationship.split("/")

          // If data has both end and start use this
          if (splittedString.length == 2) {
            this.employmentRelationshipStart = splittedString[0]
            this.employmentRelationshipEnd = splittedString[1]
          }
          // If data only has end value use this
          if (tempEmploymentRelationship.charAt(0) == "/" && splittedString.length == 1) {
            this.employmentRelationshipEnd = splittedString[0]
          }
          // If data only has start value use this
          if (tempEmploymentRelationship.charAt(0) != "/" && splittedString.length == 1) {
            this.employmentRelationshipStart = splittedString[0]
          }
        }

      }
    )

  }

  /**
   * Set teams variable from workerteams data to use them in HTML.
   * If length is 0 apply null to teams
   * @param workerteamsJSON workerteams we get from api call
   */
  setWorkerteams(workerteamsJSON) {
    let workerteams = Array()
    // Loop through JSON data. Use keys to loop them and push values to workerteams array
    for (let i = 0; i < Object.keys(workerteamsJSON).length; i++) {
      workerteams.push(Object.values(workerteamsJSON)[i])
    }
    if (workerteams.length > 0) this.teams = workerteams;
    else this.teams = null;
  }

  /**
   * Navigation back to workers page. Function is called when "back" is pressed in interface
   */
  toWorkersPage() {
    this.router.navigate(['planner/workers'])
  }

  /**
   * Loop through qualificationsFromApiCall and push them to skills then apply skills to variable qualifications
   * @param qualifications data we get from api call
   */
  setSkills(qualificationsFromApiCall) {
    let skills = Array();
    // If not null
    if (qualificationsFromApiCall != null) {
      // Parse JSON
      let parsedQualifications = JSON.parse(qualificationsFromApiCall)
      // Loop through parsed JSON
      for (let i = 0; i < parsedQualifications.length; i++) {
        // Push to skills both instances if .name exists or is old version that does not have .name variables
        if (parsedQualifications[i].name == null) skills.push({name: parsedQualifications[i], checked: true})
        else skills.push({name: parsedQualifications[i].name, checked: true})
      }
    }
    // Qualifications is used in HTML
    this.qualifications = skills;
    // Set checked value to true if length is more than 0
    if (this.qualifications.length > 0) this.hasCheckedValue = true;
    if (this.qualifications.length < 1) this.skillHeight = '80px';
    if (this.qualifications.length < 2) this.skillHeight = '100px';
    if (this.qualifications.length >= 2) this.skillHeight = '150px';
  }

  /**
   * The working hours part table in interface. Used to determine how worker is working in week. Same logic applied to everyday
   * @param workingHours list of days we get from api call
   */
  setWorkingHours(workingHours) {
    // Parse JSON
    let jsonParsed = JSON.parse(workingHours)
    // Loop through parsed JSON and check if day exists. If it does then save values to correct places in days array.
    // Also put checked to true that means it will save later to daatabse if user modifies something in site.
    for (let i = 0; i < jsonParsed.length; i++) {
      if (jsonParsed[i].day == 'Mon') {
        this.days[0].start = jsonParsed[i].start
        this.days[0].end = jsonParsed[i].end
        this.days[0].checked = true
      }
      if (jsonParsed[i].day == 'Tue') {
        this.days[1].start = jsonParsed[i].start
        this.days[1].end = jsonParsed[i].end
        this.days[1].checked = true
      }
      if (jsonParsed[i].day == 'Wed') {
        this.days[2].start = jsonParsed[i].start
        this.days[2].end = jsonParsed[i].end
        this.days[2].checked = true
      }
      if (jsonParsed[i].day == 'Thu') {
        this.days[3].start = jsonParsed[i].start
        this.days[3].end = jsonParsed[i].end
        this.days[3].checked = true
      }
      if (jsonParsed[i].day == 'Fri') {
        this.days[4].start = jsonParsed[i].start
        this.days[4].end = jsonParsed[i].end
        this.days[4].checked = true
      }
      if (jsonParsed[i].day == 'Sat') {
        this.days[5].start = jsonParsed[i].start
        this.days[5].end = jsonParsed[i].end
        this.days[5].checked = true
      }
      if (jsonParsed[i].day == 'Sun') {
        this.days[6].start = jsonParsed[i].start
        this.days[6].end = jsonParsed[i].end
        this.days[6].checked = true
      }
    }
  }

  /**
   * Function is called when user presses edit button in interface
   */
  toggleEdit(tab) {
    if (tab == 1) {
      this.editMode1 = true;
      this.tempPhone = this.phone;
      this.tempEmail = this.email;
      // New variables to temp
      this.tempLanguage = this.workerLanguage
    } else if (tab == 2) {
      // Css logic
      this.editMode2 = true;
      this.dayHeight = '50px'
      // Set temp values if user clicks cancel in edit mode apply these values
      // to get same values that was before user clicked edit
      this.tempEfficiency = this.efficiency;
      this.tempEfficiencyValue = this.efficiencyValue;
      this.tempColorInner = this.colorInner;
      this.tempColorOuter = this.colorOuter;
      this.tempQualifications = JSON.stringify(this.qualifications);
      this.tempDays = JSON.stringify(this.days)
      this.tempEmploymentStart = this.employmentRelationshipStart;
      this.tempEmploymentEnd = this.employmentRelationshipEnd;
    } else if (tab == 3) {
      this.editMode3 = true;
    } else if (tab == 4) {
      this.editMode4 = true;
    } else if (tab == 5) {
      this.editMode5 = true;
    }

  }

  // Called when user has created new skill in new skill modal.
  createNewSkill() {
    // Push new skill to qualifications with checked false
    this.qualifications.push({name:this.newSkill, checked: false})
    if (this.qualifications.length < 1) this.skillHeight = '80px';
    if (this.qualifications.length < 2) this.skillHeight = '100px';
    if (this.qualifications.length >= 2) this.skillHeight = '150px';
    // Push close button
    let button = document.getElementById('modalCloseNewSkill')
    button?.click();
    // Set new skill to null
    this.newSkill = null;

  }

  // Most important function for saving changes after user made them and
  // Sending them to database
  saveEdit(tab) {

    // Make a big api call with all the changes
    if (tab == 1) {
      this.workersService.updateWorker1(this.id, this.phone)
      // Check if email has been changed and fire pop up for confirmation if it has been changed.
      if (this.email != this.tempEmail) {
        this.userService.updateUserEmail(this.userId, this.email)
      }
      if (this.workerLanguage.databaseLanguage !== this.tempLanguage.databaseLanguage) {
        this.userService.updateUserLanguage(this.userId, this.workerLanguage.databaseLanguage)
      }
      this.editMode1 = false;
    } else if (tab == 2) {
      this.dayHeight = '30px'

      // Set colors and percent correctly for bubble
      if (this.efficiencyValue >= 1.0) {
        this.colorInner = '#10A231'
        this.colorOuter = '#05791F'
        this.efficiency = (this.efficiencyValue - 1) * 100
      } else {
        this.colorOuter = '#FF0000'
        this.colorInner = '#ffcccb'
        this.efficiency = this.efficiencyValue * 100;
      }

      let workingHours = Array();
      // Loop through days to check which is not checked and set them to null if checked is false
      // and if it is true then push them to array workingHours
      for (let i = 0; i < this.days.length; i++) {
        // Ran if checked is false for day
        if (this.days[i].checked == false) {
          this.days[i].start = null;
          this.days[i].end = null;
        }
        // Ran if checked is true for day.
        // Push days with correct values to workingHours array
        if (this.days[i].checked == true) workingHours.push({day: this.days[i].englishName, start: this.days[i].start, end: this.days[i].end})
      }

      // Check if qualifications is checked and push them array if they are.
      let checkedQualifications = Array();
      for (let j = 0; j < this.qualifications.length; j++) {
        if (this.qualifications[j].checked == true) checkedQualifications.push(this.qualifications[j])
      }

      // Logic for saving employment relationship
      // Apply if has both values end and start
      if (this.employmentRelationshipStart != null && this.employmentRelationshipEnd != null) this.employmentRelationship = this.employmentRelationshipStart + "/" + this.employmentRelationshipEnd;
      // Apply if has only end value
      else if (this.employmentRelationshipStart == null && this.employmentRelationshipEnd != null) this.employmentRelationship = "/" + this.employmentRelationshipEnd
      // Apply if has only start value
      else if (this.employmentRelationshipStart != null && this.employmentRelationshipEnd == null) this.employmentRelationship = this.employmentRelationshipStart + "/"
      this.workersService.updateWorker2(this.id, this.efficiencyValue, checkedQualifications, workingHours, this.employmentRelationship)
      this.editMode2 = false;

    } else if (tab == 3) {
      if (this.newUsername != null) {
        let response;
        if (this.newUsername.trim() != "") {
          this.workersService.checkUsername(this.newUsername)
          .pipe(
            finalize(() => {
              if (response != "username_in_use") {
                this.userService.updateUserUsernamePassword(this.userId,this.newUsername, this.newPassword)
                this.username = this.newUsername;
                this.createUserActive = false;
                this.showUsername = true;
                this.showPassword = true;
                this.editMode3 = false;
                this.accountDisabled = false
              }
              this.newUsername = null;
              this.newPassword = null;
            })
          )
          .subscribe(
            data => response = data
          )
        } else {
          this.toastService.sendToast(false, this.translateService.instant('basic.usernameSpacebars'))
          this.newUsername = null;
          this.newPassword = null;
        }
      }
      if (this.newUsername == null && this.newPassword != null) {
        this.userService.updateUserUsernamePassword(this.userId,this.newUsername, this.newPassword)
        this.newUsername = null;
        this.newPassword = null;
        this.editMode3 = false;
        this.showUsername = true;
        this.showPassword = true;
        this.createUserActive = false;
      }
      if (this.showGeneratedPassword) this.showGeneratedPassword = false


    } else if (tab == 4) {
      let requests: Array<any> = [];
      this.saving = true;
      var keys = this.contractors.map(({ id }) => id);
      for(var i = 0; i < this.selectedContractors.length; i++) {
        let contractor = this.selectedContractors[i]

        if(keys.includes(contractor.id.toString())) {
          // console.log('key '+contractor.id+' already in contractors. Skip')
        } else {
          // console.log('key '+contractor.id+' not in contractors. Add')
          const request = this.workersService.setWorkerToContractor(this.id, contractor.id);
          requests.push(request);
        }
      }
      for(var i = 0; i < this.unselectedContractors.length; i++) {
        let contractor = this.unselectedContractors[i]

        if(keys.includes(contractor.id.toString())) {
          // console.log('key '+contractor.id+' in unselectedContractors. Remove')
          const request = this.workersService.removeWorkerFromContractor(this.id, contractor.id);
          requests.push(request);
        }
      }
      forkJoin(requests).subscribe(results => {
        // Check if all calls were successful
        const allSuccessful = results.every(result => result.success);
        //TODO if (allSuccessful) {, show message, else show error message

        this.editMode4 = false;
        this.saving = false;
        this.getWorker(this.id)
      })

    } else if (tab == 5) {
      if(this.tag) {
        this.existingTags.push(this.tag)
      }
      this.userService.updateUserTags(this.userId, this.existingTags)
      this.tag = ""
      this.tags = this.existingTags
      this.editMode5 = false;
    }
  }

  /**
   * Logic for cancelling edit by user. Apply old values to all places that could have been changed.
   */
  cancelEdit(tab) {
    if (tab == 1) {
      this.editMode1 = false;
      this.phone = this.tempPhone;
      this.email = this.tempEmail;
      this.workerLanguage = this.tempLanguage
    } else if (tab == 2) {
      this.editMode2 = false;
      this.dayHeight = '30px';
      this.efficiency = this.tempEfficiency;
      this.efficiencyValue = this.tempEfficiencyValue;
      this.colorInner = this.tempColorInner;
      this.colorOuter = this.tempColorOuter;
      this.qualifications = JSON.parse(this.tempQualifications);
      this.days = JSON.parse(this.tempDays);
      this.employmentRelationshipStart = this.tempEmploymentStart;
      this.employmentRelationshipEnd = this.tempEmploymentEnd;
    } else if (tab == 3) {
      if (this.createUserActive == true) {
        this.createUser = true
        this.personAccount = 'assets/icons/person_gray_24dp.svg';
      }
      this.editMode3 = false;
      this.showUsername = true;
      this.showPassword = true;
      this.newPassword = null;
      this.newUsername = null;
      if (this.showGeneratedPassword) this.showGeneratedPassword = false
    } else if (tab == 4) {
      this.editMode4 = false;
    } else if (tab == 5) {
      this.editMode5 = false;
      this.existingTags = this.tags
    }

  }

  /**
   * If skill is clicked set qualifications checked to true and if it alreydy true set it to false.
   * Used in saving data to database.
   * @param index correct skill to access
   */
  skillClicked(index) {
    let boolean = false;
    for (let i = 0; i < this.qualifications.length; i++) {
      if (this.qualifications[index].checked == false) boolean = true;
      if (this.qualifications[i].checked == true && this.qualifications[i] != this.qualifications[index]) boolean = true;
    }
    if (boolean == true) this.hasCheckedValue = true;
    else this.hasCheckedValue = false;
  }

  changeUsernameToggle() {
    this.showUsername = false;

  }

  changePasswordToggle() {
    this.showPassword = false;
  }

  generatePassword() {
    this.newPassword = uuidv4()
  }

  createAccount() {
    this.personAccount = 'assets/icons/person_blue_24dp.svg';
    this.createUser = false;
    this.editMode3 = true;
    this.showPassword = false;
    this.showUsername = false;
  }

  hideShowPassword() {
    let passwordField = document.getElementById("login-password");

    this.showGeneratedPassword = !this.showGeneratedPassword

    const type = passwordField!.getAttribute("type") === "password" ? "text" : "password";
    passwordField!.setAttribute("type", type);

  }

  disableAccount() {
    Swal.fire({
      title: this.translateService.instant('planner.workerDetail.disableAccountConfirmation'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.workersService.changeDisabledStatus(1, this.userId).subscribe()
        this.accountDisabled = true
        this.personAccount = 'assets/icons/person_gray_24dp.svg';
      }
    })

  }

  activateAccount() {
    this.workersService.changeDisabledStatus(0, this.userId).subscribe()
    this.accountDisabled = false
    this.personAccount = 'assets/icons/person_blue_24dp.svg';
  }

  languageChange(databaseLanguage) {
    for (let i = 0; i < this.interfaceLanguages.length; i++) {
      const language = this.interfaceLanguages[i];
      if (language.databaseLanguage === databaseLanguage) this.workerLanguage = language

    }
  }

  selectContractor(id) {
    if(id) {
      for(let i = 0; i < this.unselectedContractors.length; i++) {
        let contractor = this.unselectedContractors[i];
        if (contractor.id == id) {
          this.selectedContractors.push(contractor);
          this.unselectedContractors.splice(i, 1);
          break
        }
      }
    }
  }

  unselectContractor(id) {
    if(id) {
      for(let i = 0; i < this.selectedContractors.length; i++) {
        let contractor = this.selectedContractors[i];
        if (contractor.id == id) {
          this.unselectedContractors.push(contractor);
          this.selectedContractors.splice(i, 1);
          break
        }
      }
      this.unselectedContractors.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    }
  }

  unselectTag(chosenTag) {
    this.existingTags = this.existingTags.filter(tag => tag !== chosenTag);
  }
}
