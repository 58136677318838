 <!-- Progress dashboard starts -->
<div class="page-container">
   <div class="page-heading">
      <h3>
         <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
         <span class="page-title">{{'sideNavigation.titleDashboard' | translate}}</span>
      </h3>
   </div>
   <div class="page-content">
      
      <div class="card shadow-sm">
         <div class="m-3 row">
            <div class="col-4">
               <h4>{{'planner.contractorDashboard.calendarOverview' | translate}}</h4>
               <div class="d-flex">
                  <div class="form-switch">
                     <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="showCalendarsWithSlots">
                  </div>
                  <p class="gray-text">{{'planner.contractorDashboard.showCalendars' | translate}}</p>
               </div>
            </div>
            <div class="col-4">
               <app-week-picker
               (weekChange)="setCurrentWeek($event)"
               ></app-week-picker>
            </div>
            <div class="col-4">
              <button type="button" class="font-size-small btn btn-primary" (click)="downloadWorkorders()">{{'planner.contractorDashboard.downloadWorkorders' | translate}}</button>
            </div>
         </div>
      </div>
      <div class="text-center gray-text mb-4" *ngIf="!calendarsLoading && !showCalendarsWithSlots">{{visibleCalendarsCount()}} {{'calendar.calendar' | translate}}</div>
      <div class="text-center gray-text mb-4" *ngIf="!calendarsLoading && showCalendarsWithSlots">{{calendarsWithSlotsCount()}} {{'calendar.calendar' | translate}}</div>
      <div class="justify-content-center w-100 d-flex" *ngIf="calendarsLoading">
         <load-spinner *ngIf="calendarsLoading"></load-spinner>
      </div>
      <div class="d-flex mb-2">
         <div>
            <label for="search">{{'planner.contractorDashboard.searchCalendars' | translate}}</label>
            <input name="search" [(ngModel)]="searchCalendarValue" class="form-control">
         </div>
         <div class="mt-auto ms-2 d-flex">
            <div class="dropdown">
               <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="searchDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                 {{selectedSearch.name}}
               </button>
               <ul class="dropdown-menu" aria-labelledby="searchDropdown">
                 <li><a class="dropdown-item" (click)="selectSearch(0)">{{'planner.contractorDashboard.searchCalendars' | translate}}</a></li>
                 <li><a class="dropdown-item" (click)="selectSearch(1)">{{'weeklyPlanningDetails.calendarName' | translate}}</a></li>
                 <li><a class="dropdown-item" (click)="selectSearch(2)">{{'planner.contractorDashboard.installerName' | translate}}</a></li>
               </ul>
             </div>
            <button class="btn btn-primary ms-2" (click)="filterCalendars()">{{'basic.search' | translate}}</button>
         </div>
      </div>
      <section class="section" *ngIf="!calendarsLoading">
         <div *ngFor="let calendar of allCalendars; let i = index">
            <div *ngIf="calendar.showCalendar">
               <div class="card shadow-sm" *ngIf="hasSlotsThisWeek(calendar.slots)">

                  <div class="d-flex w-100">
                     <div class="w-10 top-item gray-border">
                        <h5>{{calendar.name}}</h5>
                        <div class="gray-text">{{'calendar.calendar' | translate}} {{calendar.id}}</div>
                     </div>
                     <div class="w-30 top-item gray-border">
                        <div *ngIf="returnFirstTeam(calendar.teams)" class="d-flex">
                           <div class="w-40">
                              <h6 class="fw-bold">{{returnFirstTeam(calendar.teams).name}}</h6>
                              <div class="d-flex">
                                 <img src="assets/icons/groups_black_24dp.svg" class="mr-3 ml-1">
                                 {{returnFirstTeam(calendar.teams).workercount}}
                              </div>

                           </div>
                           <div class="w-60 d-flex">
                              <circle-progress
                                 [percent]="returnFirstTeam(calendar.teams).workercount"
                                 [radius]="18"
                                 [outerStrokeWidth]="4"
                                 [innerStrokeWidth]="4"
                                 [space]="-4"
                                 [outerStrokeColor]="getOuterColor(returnFirstTeam(calendar.teams).efficiency)"
                                 [innerStrokeColor]="getInnerColor(returnFirstTeam(calendar.teams).efficiency)"
                                 [animation]="true"
                                 [animationDuration]="300"
                                 [titleFontSize]="'13'"
                                 [title]="returnFirstTeam(calendar.teams).efficiency"
                                 [showUnits]=false
                                 [titleColor]="getOuterColor(returnFirstTeam(calendar.teams).efficiency)"
                                 [titleFontWeight]="'600'"
                              ></circle-progress>
                              <div class="text-item">{{'teams.overallEfficiency' | translate}}</div>
                           </div>

                        </div>
                        <div *ngIf="!calendar.teams" class="red-text">{{'teams.noTeamAssigned' | translate}}</div>
                     </div>
                     <div class="w-20 top-item gray-border">
                        <h6 class="fw-bold">{{'planner.contractorDashboard.calendarDuration' | translate}}</h6>
                        <div class="gray-text">{{dashesToDots(calendar.startdate)}} - {{dashesToDots(calendar.enddate)}}</div>
                     </div>
                     <div class="w-10 top-item gray-border">
                        <div *ngIf="!calendar.msaname" class="red-text">{{'planner.contractorDashboard.missingMsa' | translate}}</div>
                        <div *ngIf="calendar.msaname" class="fw-bold"><span class="dot"></span>{{calendar.msaname}}</div>
                     </div>
                     <div class="w-10 top-item gray-border">
                        <button class="btn btn-secondary btn-sm" id="worklist-button-{{calendar.id}}" (click)="getWorklist(calendar)">
                           <img src="/assets/icons/list_alt_gray_24dp.svg">
                           <span>{{'planner.contractorDashboard.withoutSlot' | translate}} ({{calendar.worklist.ordercount}})</span>
                       </button>
                       <div class="work-list" *ngIf="calendar.showWorklist">
                           <load-spinner *ngIf="calendar.calendarSpinner"></load-spinner>
                           <div *ngIf="!calendar.calendarSpinner && currentWorklist">
                              <div *ngIf="currentWorklist.state_4 && currentWorklist.workorders" (click)="openWorklist('reported')" class="work-list-bg">
                                 <img class="mr-1" src="/assets/icons/calendar_available_green_24dp.svg">
                                 <span>{{'planner.contractorDashboard.reported' | translate}}: {{currentWorklist.state_4}} / {{currentWorklist.workorders.length}}</span>
                                 <hr>
                              </div>
                              <div *ngIf="currentWorklist && currentWorklist.state_6 && currentWorklist.workorders" (click)="openWorklist('interrupted')" class="work-list-bg">
                                 <img class="mr-1" src="/assets/icons/event_busy_red_24dp.svg">
                                 <span>{{'planner.contractorDashboard.interrupted' | translate}}: {{currentWorklist.state_6}} / {{currentWorklist.workorders.length}}</span>
                                 <hr>
                              </div>
                              <div *ngIf="getNotDoneWorklist() !== 0 && currentWorklist.workorders" (click)="openWorklist('notDone')" class="work-list-bg">
                                 <img class="me-1" src="/assets/icons/calendar_available_black_24dp.svg">
                                 <span>{{'planner.contractorDashboard.notDone' | translate}}: {{getNotDoneWorklist()}} / {{currentWorklist.workorders.length}}</span>
                                 <hr>
                              </div>
                              <div *ngIf="currentWorklist.workorders === undefined || currentWorklist.workorders.length === 0" class="red-text">{{'planner.weeklyPlanningMap.noWorkordersWorklist' | translate}}</div> 
                              <div *ngIf="currentWorklist.workorders !== undefined && currentWorklist.workorders.length > 0" class="text-center"><button type="button" class="btn btn-primary mt-2" (click)="openWorklist('all')">{{'planner.contractorDashboard.openAll' | translate}}</button></div>
                           </div>
                       </div>
                     </div>
                     <div class="w-20 top-item gray-border text-center">
                        <div class="w-100"><button type="button" class="btn btn-secondary btn-sm" (click)="openWorkordersWeekly(calendar.id)">{{'planner.contractorDashboard.openWeeklyWorkorders' | translate}}</button></div>
                        <div class="w-100 mt-2"><button type="button" class="btn btn-primary btn-sm" (click)="openCalendar(calendar.id)">{{'planner.contractorDashboard.openCalendar' | translate}}</button></div>
                     </div>
                  </div>
                  <hr>

                  <div class="d-flex w-100">
                     <div *ngFor="let day of weekdays;let i = index;" class="gray-border day-slot">
                        <div class="d-flex">
                           <div class="w-100 day-padding" [ngClass]="today == i+1 ? 'day-today' : '' ">
                              <h6 *ngIf="today == i+1" class="blue-text">{{'planner.contractorDashboard.today' | translate}}</h6>
                              <div  *ngIf="today != i+1" class="not-today"></div>
                              <div class="booking-status shadow-sm" [hidden]="!(thisDayHover === i && hoveredCalendar == calendar.id)">
                                 <div><span class="fw-bold">{{ getOverallStatus(day, calendar.slots) }}</span> {{'planner.contractorDashboard.overallCapacity' | translate}}</div>
                                 <div><span class="fw-bold blue-text">{{ getFixedStatus(day, calendar.slots) }}</span> {{'planner.contractorDashboard.fixed' | translate}}</div>
                                 <div><span class="fw-bold yellowish-text">{{ getFreeStatus(day, calendar.slots) }}</span> {{'planner.contractorDashboard.free' | translate}}</div>
                                 <div><span class="fw-bold red-text">{{ getOtherStatus(day, calendar.slots) }}</span> {{'planner.contractorDashboard.unknown' | translate}}</div>
                              </div>
                              <div class="dual-container" (mouseover)="hoverHandler(i, calendar.id)" (mouseout)="hoverHandler(-1, -1)">
                                 <div class="fw-bold left text-capitalize d-flex">
                                    <div class="pt-1">{{ getDayName(day) }} {{ day.getDate() }}.{{ day.getMonth()+1 }}</div>
                                 </div>
                                 <img *ngIf="getDayLocked(day, calendar.slots)" class="right lock-icon day-locked" src="assets/icons/lock_black_24dp.svg" role="button" >
                                 <img *ngIf="!getDayLocked(day, calendar.slots)" class="right lock-icon" src="assets/icons/lock_open_gray_24dp.svg" role="button" >
                              </div>
                              <hr>
                                 <div *ngIf="getOrderCount(day, calendar.slots)">
                                   <div class="mb-2 fs-small">
                                     <button class="btn btn-secondary btn-sm fs-xs" (click)="openWorkordersDaily(day, calendar.id)">{{'planner.contractorDashboard.openDailyWorkorders' | translate }}</button>
                                   </div>
                                    <h6 class="mb-2 fw-bold">{{'planner.contractorDashboard.progress' | translate}}</h6>
                                    <div class="fs-small mb-2"><img class="me-1" src="/assets/icons/calendar_available_black_24dp.svg">{{getConfirmed(day, calendar.slots)}} {{'planner.contractorDashboard.confirmed' | translate}}</div>
                                    <div class="green-text fs-small d-flex mb-2">
                                       <img class="mr-1" src="/assets/icons/calendar_available_green_24dp.svg">
                                       {{getReported(day, calendar.slots)}} {{'planner.contractorDashboard.reported' | translate}}
                                    </div>
                                    <div class="red-text fs-small d-flex mb-2">
                                       <img class="mr-1" src="/assets/icons/event_busy_red_24dp.svg">
                                       {{getInterrupted(day, calendar.slots)}} {{'planner.contractorDashboard.interrupted' | translate}}
                                    </div>
                                 </div>
                                 <div *ngIf="calendar.showSpecificSlots && calendar.specificData !== undefined">
                                    <div *ngIf="calendar.specificData[getFormattedDate(day)]">
                                    <hr *ngIf="getOrderCount(day, calendar.slots) > 0">
                                    <h6 class="fw-bold">{{'planner.contractorDashboard.slotSpecificProgress' | translate}}</h6>
                                    <ng-container *ngIf="getFormattedDate(day) as formattedDay">
                                       <div *ngFor="let key of objectKeys(calendar.specificData[formattedDay]); let i = index" class="fs-small">
                                          <hr>
                                         <div class="gray-text mb-1 dual-container">
                                          <span class="left">{{ calendar.specificData[formattedDay][key].starttime  | date:'HH:ss'}} - {{ calendar.specificData[formattedDay][key].endtime | date: 'HH:ss'}}</span>
                                          <button type="button" *ngIf="calendar.specificData[formattedDay][key].total > 0" class="btn btn-sm btn-secondary right" (click)="openWorkorders(calendar.specificData[formattedDay][key])">{{'basic.open' | translate}} </button>
                                         </div>
                                         <div *ngIf="calendar.specificData[formattedDay][key].total > 0">
                                          <img class="mr-1" src="/assets/icons/calendar_available_black_24dp.svg">
                                          {{calendar.specificData[formattedDay][key].confirmed.length}} / {{ calendar.specificData[formattedDay][key].total }} {{'planner.contractorDashboard.confirmed' | translate}}
                                         </div>
                                         <div *ngIf="calendar.specificData[formattedDay][key].reported.length > 0" class="green-text">
                                          <img class="mr-1" src="/assets/icons/calendar_available_green_24dp.svg">
                                          {{ calendar.specificData[formattedDay][key].reported.length }} / {{ calendar.specificData[formattedDay][key].total }} {{'planner.contractorDashboard.reported' | translate}}
                                         </div>
                                         <div *ngIf="calendar.specificData[formattedDay][key].interrupted.length > 0" class="red-text">
                                          <img class="mr-1" src="/assets/icons/event_busy_red_24dp.svg">
                                          {{ calendar.specificData[formattedDay][key].interrupted.length }} / {{ calendar.specificData[formattedDay][key].total }} {{'planner.contractorDashboard.interrupted' | translate}}
                                         </div>
                                         <div *ngIf="calendar.specificData[formattedDay][key].total === 0">
                                          {{'planner.weeklyPlanningMap.emptySlot' | translate}}
                                         </div>
                                       </div>
                                     </ng-container>
                                     </div>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="mt-2">
                     <button type="button" class="btn btn-secondary w-100" (click)="toggleSpecificSlot(true, i)" *ngIf="!calendar.showSpecificSlots">{{'planner.contractorDashboard.showSpecificSlotInfo' | translate}}</button>
                     <button type="button" class="btn btn-secondary w-100" (click)="toggleSpecificSlot(false, i)" *ngIf="calendar.showSpecificSlots">{{'planner.contractorDashboard.hideSpecificSlotInfo' | translate}}</button>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="section" *ngIf="!calendarsLoading">
          <h3>{{'planner.contractorDashboard.slotlessCalendars' | translate}}</h3>
          <div class="text-center gray-text mb-4" *ngIf="!calendarsLoading && showCalendarsWithSlots">{{calendarsWithNoSlotsCount()}} {{'calendar.calendar' | translate}}</div>
         <div *ngFor="let calendar of allCalendars; let i = index">
            <div *ngIf="calendar.showCalendar">
               <div class="card shadow-sm" *ngIf="!hasSlotsThisWeek(calendar.slots)">
                  <div class="d-flex w-100">
                     <div class="w-20 top-item gray-border">
                        <h5>{{calendar.name}}</h5>
                        <div class="gray-text">{{'calendar.calendar' | translate}} {{calendar.id}}</div>
                     </div>
                     <div class="w-30 top-item gray-border">
                        <div *ngIf="returnFirstTeam(calendar.teams)" class="d-flex">
                           <div class="w-40">
                              <h6 class="fw-bold">{{returnFirstTeam(calendar.teams).name}}</h6>
                              <div class="d-flex">
                                 <img src="assets/icons/groups_black_24dp.svg" class="mr-3 ml-1">
                                 {{returnFirstTeam(calendar.teams).workercount}}
                              </div>

                           </div>
                           <div class="w-60 d-flex">
                              <circle-progress
                                 [percent]="returnFirstTeam(calendar.teams).workercount"
                                 [radius]="18"
                                 [outerStrokeWidth]="4"
                                 [innerStrokeWidth]="4"
                                 [space]="-4"
                                 [outerStrokeColor]="getOuterColor(returnFirstTeam(calendar.teams).efficiency)"
                                 [innerStrokeColor]="getInnerColor(returnFirstTeam(calendar.teams).efficiency)"
                                 [animation]="true"
                                 [animationDuration]="300"
                                 [titleFontSize]="'13'"
                                 [title]="returnFirstTeam(calendar.teams).efficiency"
                                 [showUnits]=false
                                 [titleColor]="getOuterColor(returnFirstTeam(calendar.teams).efficiency)"
                                 [titleFontWeight]="'600'"
                              ></circle-progress>
                              <div class="text-item">{{'teams.overallEfficiency' | translate}}</div>
                           </div>

                        </div>
                        <div *ngIf="!calendar.teams" class="red-text">{{'teams.noTeamAssigned' | translate}}</div>
                     </div>
                     <div class="w-20 top-item gray-border">
                        <h6 class="fw-bold">{{'planner.contractorDashboard.calendarDuration' | translate}}</h6>
                        <div class="gray-text">{{dashesToDots(calendar.startdate)}} - {{dashesToDots(calendar.enddate)}}</div>
                     </div>
                     <div class="w-20 top-item">
                        <div *ngIf="!calendar.msaname" class="red-text">{{'planner.contractorDashboard.missingMsa' | translate}}</div>
                        <div *ngIf="calendar.msaname" class="fw-bold"><span class="dot"></span>{{calendar.msaname}}</div>
                     </div>
                     <button type="button" class="btn btn-secondary" (click)="openCalendar(calendar.id)">{{'planner.contractorDashboard.openCalendar' | translate}}</button>
                  </div>
               </div>
            </div>
         </div>
      </section>

       <section class="section" *ngIf="!calendarsLoading">
           <h4>{{'planner.contractorDashboard.titleMsaStatuses' | translate}}</h4>
           <div class="card shadow-sm" *ngFor="let msa of msas" >
             <h5>{{msa.name}}</h5>
             <div>{{msa.schedule_start  | date: 'dd.MM.YYYY'}} - {{msa.schedule_end  | date: 'dd.MM.YYYY'}}</div>
             <div>{{'planner.contractorDashboard.workorderNotConfirmed' | translate }}: {{msa.notConfirmed}} </div>
             <div class="progress progress-primary right">
               <div class="progress-bar" role="progressbar"
                    [ngStyle]="setProgress(msa.notConfirmed, msa.total)" aria-valuenow="0"
                    aria-valuemin="0" aria-valuemax="100">
               </div>
             </div>
             <div>{{'planner.contractorDashboard.workordersDone' | translate }}: {{msa.done}}</div>
             <div class="progress progress-primary right">
               <div class="progress-bar" role="progressbar"
                    [ngStyle]="setProgress(msa.done, msa.total)" aria-valuenow="0"
                    aria-valuemin="0" aria-valuemax="100">
               </div>
             </div>
             <div>{{'planner.contractorDashboard.workordersInterrupted' | translate }}: {{msa.interrupted}}</div>
             <div class="progress progress-primary right">
               <div class="progress-bar" role="progressbar"
                    [ngStyle]="setProgress(msa.interrupted, msa.total)" aria-valuenow="0"
                    aria-valuemin="0" aria-valuemax="100">
               </div>
             </div>
             <div>{{'planner.contractorDashboard.workordersTotal' | translate }}: {{msa.total}}</div>

               <hr>
           </div>
       </section>
   </div>
</div>
<!-- Contractor dashboard ends -->
