import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-work-order',
  template: `
    <div class="p-2">
        <div 
        (click)="!workorder.disabled && onWorkorderClick()" 
        [ngClass]="{'cursor-pointer': !workorder.disabled}"
        (mousedown)="!workorder.disabled && onMouseDown()"
        (mouseup)="!workorder.disabled && onMouseUp()"
        (mouseleave)="!workorder.disabled && onMouseUp()"
        (touchstart)="!workorder.disabled && onMouseDown()"
        (touchend)="!workorder.disabled && onMouseUp()">
          <div class="dual-container p-1">
              <div class="left text-uppercase">
                <div *ngIf="workorder.state !== 4">
                  <div *ngIf="workorder.time_confirmed == 1" >
                      <img class="dp24" src="assets/icons/Time confirmed.svg">
                      <span class="green-text">{{'fieldReporting.dashboard.timeConfirmed' | translate }}</span>
                  </div>
                  <div *ngIf="workorder.time_confirmed != 1">
                      <img class="dp24" src="assets/icons/Time not confirmed.svg">
                      <span class="red-text">{{'fieldReporting.dashboard.timeNotConfirmed' | translate }}</span>
                  </div>
                </div>
                <div class="text-lowercase text-break">
                    <span class="text-capitalize">{{'basic.meter' | translate }}</span>
                    <span class="ml-1" *ngIf="workorder.old_device_id">{{workorder.old_device_id}}</span>
                    <span class="ml-1" *ngIf="workorder.location_number">({{workorder.location_number}})</span>
                  <span class="ml-1" *ngIf="!workorder.old_device_id && !workorder.location_number">-</span>
                </div>
              </div>
              <div class="right">
                  <img class="corner-icon" [src]=setCornerIcon(workorder.access_type)>
              </div>
          </div>
          <div class="row p-1 mr-5">
              <div class="col-6 d-flex">
                  <img class="card-icon dp24" src="assets/icons/schedule_blue_24dp.svg">
                  <div *ngIf="workorder.time_window_start">{{timeWindowToHours(workorder.time_window_start)}}</div>
                  <div *ngIf="!workorder.time_window_start" class="red-text">XX:XX</div>
                  -
                  <div *ngIf="workorder.time_window_end">{{timeWindowToHours(workorder.time_window_end)}}</div>
                  <div *ngIf="!workorder.time_window_end" class="red-text">XX:XX</div>
              </div>
              <div class="col-6 d-flex justify-content-end">
                  <img class="card-icon dp24" src="assets/icons/today_blue_24dp.svg">
                  <div *ngIf="workorder.time_window_start">{{timeWindowToDate(workorder.time_window_start)}}</div>
                  <div *ngIf="!workorder.time_window_start" class="red-text">{{'fieldReporting.shared.missingDate' | translate }}</div>
              </div>
          </div>
          <div class="row p-1 mr-5">
            <div class="d-flex col-7 text-break">
                <img class="dp24" src="assets/icons/location_on_blue_24dp.svg">
                <div class="mt-1">{{workorder.address}} {{workorder.zip}} {{workorder.city}}</div>
            </div>
            <div class="col-5 d-flex align-items-center justify-content-end">
              <img class="card-icon dp24" src="assets/icons/edit_calendar.svg">
              <div *ngIf="!report">{{'planner.reports.phase.' + workorder.phase | translate}}</div>
              <div *ngIf="report">{{'planner.reports.phase.' + workorder.report_phase | translate}}</div>
            </div>
          </div>
          <div class="p-1 d-flex" *ngIf="workorder.distance">
              <img class="dp24" src="assets/icons/location.svg">
              <div class="mt-1">{{workorder.distance < 1 ? (workorder.distance * 1000).toFixed(0) : workorder.distance.toFixed(2)}} {{workorder.distance < 1 ? ('basic.metersShort' | translate) : 'basic.kilometersShort' | translate}}</div>
          </div>
          <div class="display-flex" *ngIf="workorder.endusers">
              <div class="d-flex flex-wrap mt-3" *ngFor="let user of workorder.endusers">
                  <div class="tagbox" *ngIf="user.contact_person_last || user.contact_person_first">{{user.contact_person_last}} {{user.contact_person_first}}</div>
                  <div class="tagbox" *ngIf="user.phone">{{user.phone}}</div>
              </div>
          </div>
          <hr *ngIf="workorder.time_table && workorder.time_table.length > 0">
          <div>
              <div *ngFor="let estimate of workorder.time_table | keyvalue">
                <div><b class="text-primary">{{estimate.key}}:</b> {{estimate.value}}</div>
              </div>
          </div>
          <hr *ngIf="workorder.reserver">
          <div class="display-flex" *ngIf="workorder.reserver">
              <div class="tagbox-green" *ngIf="workorder.reserver_id == this.userid "><img id="me2Img" src="assets/icons/person_white_32x32px.svg"> {{'fieldReporting.dashboard.assignedToMe' | translate }}</div>
              <div class="tagbox-grey"*ngIf="workorder.reserver_id != this.userid"><img id="else2Img" src="assets/icons/person_white_32x32px.svg"> {{'fieldReporting.dashboard.assignedTo' | translate }} {{workorder.reserver}}</div>
          </div>
        </div> 
        <hr *ngIf="workorder.worker_notes">
        <div *ngIf="workorder.worker_notes">
            <div class="blue-text dual-container mb-2">
              <details class="w-100" #details (toggle)="onDetailsToggle()">
                <summary class="dual-container">
                    <div class="left d-flex">
                        <img class="card-icon" src="assets/icons/info_blue_24dp.svg">
                        <h6 class="m-0 lh-body">{{'fieldReporting.dashboard.extraRemarks' | translate }}</h6>
                    </div>
                    <img *ngIf="!workorder.expand" class="right" src="assets/icons/expand_more_blue_24dp.svg">
                    <img *ngIf="workorder.expand" class="right" src="assets/icons/expand_less_blue_24dp.svg">
                </summary>
                <div class="ms-4 mt-2">
                    <div *ngIf="workorder.worker_notes">
                        <div>{{'fieldReporting.shared.extraInformationPlanner' | translate }}</div>
                        <p [ngClass]="workorder.disabled ? 'disabled' : 'gray-text'">{{workorder.worker_notes}}</p>
                    </div>
                </div>
              </details>
            </div>
        </div>
        <div *ngIf="report" class="p-1">
          <hr>
          <div *ngIf="workorder.report_state === 6" class="red"><img class="dp24" src="assets/icons/warning_red.svg">Interrupted</div>
          <div *ngIf="workorder.report_state === 6">
            <span class="red-text" *ngIf="workorder.report_interrupt_reason">{{workorder.report_interrupt_reason}}</span>
            <span class="red-text" *ngIf="!workorder.report_interrupt_reason">{{'fieldReporting.dashboard.massInterrupted' | translate}}</span>
          </div>
          <div *ngIf="workorder.report_state === 6">
            <span *ngIf="workorder.report_interrupt_code" class="red-text">{{workorder.report_interrupt_code}} - </span>
            <span class="red-text" *ngIf="workorder.report_interrupt_definition">{{workorder.report_interrupt_definition.definition}}</span>
            <span *ngIf="workorder.modified" class="red-text"> - {{workorder.modified}}</span>
          </div>
          <div *ngIf="workorder.report_state === 4" class="green">Done</div>
        </div>
    </div>
  `,
  styleUrls: ['./dashboard.component.scss'],
  standalone: false
})
export class WorkorderComponent implements AfterViewInit {
  @Input() workorder: any;
  @Input() userid: any;
  @Input() contractorType: any;
  @Input() currentWorkerteam: any;
  @Input() search: boolean = false
  @Input() report: boolean = false
  @Output() workorderClicked = new EventEmitter<any>();
  @Output() mouseDownEmit = new EventEmitter<any>()
  @Output() mouseUpEmit = new EventEmitter<any>()

  @ViewChild('details') detailsElement!: ElementRef<HTMLDetailsElement>;


  ngAfterViewInit() {
    // Set the initial open state without binding
    if (this.workorder.expand && this.workorder.time_window_start === null) {
        this.detailsElement.nativeElement.setAttribute('open', '');
    }
}

  onWorkorderClick() {
    this.workorderClicked.emit(this.workorder)
  }

  onDetailsToggle() {
    // Update the component's state based on user interaction
    this.workorder.expand = this.detailsElement.nativeElement.open;
}

  setCornerIcon(accessType) {
    if (accessType == 3) return "assets/icons/key_blue_24dp.svg"
    else if (accessType == 2) return "assets/icons/person_lock_blue_24dp.svg"
    else if (accessType == 1) return "assets/icons/lock_unlocked_blue_24dp.svg"
    else return null
  }

  timeWindowToHours(time) {
    time = time.split(" ")[1]
    let hours = time.split(":")[0]
    let minutes = time.split(":")[1]

    if (hours == '0') {
      hours = '00'
    }
    if (minutes == '0') {
      minutes = '00'
    }

    return hours + ':' + minutes
  }

  timeWindowToDate(time) {
    time = time.split(" ")[0]
    time = time.replaceAll("/", "-")
    var newDate = new Date(time);
    var date = newDate.getDate() + '.' + (newDate.getMonth() + 1) + '.' + newDate.getFullYear()
    return date
  }

  onMouseDown() {
    if (this.search) this.mouseDownEmit.emit(this.workorder)
  }

  onMouseUp() {
    if (this.search) this.mouseUpEmit.emit()
  }
}
