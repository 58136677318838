import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { ProjectLeadService } from '@modules/project-lead/services/project-lead.service';

@Component({
  selector: 'app-project-lead-dashboard',
  templateUrl: './project-lead-dashboard.component.html',
  styleUrls: ['./project-lead-dashboard.component.scss'],
  standalone: false
})
export class ProjectLeadDashboardComponent implements OnInit {
  
  projects: any[] = []
  showWOM: boolean = true;
  @Output() projectId: number | null = null
  @Output() reportWorkorderDetailId: number | null = null
  @Input() showDetail: boolean = false
  showReports: boolean = false

  constructor(
    private authService: AuthService,
    private router: Router,
    private projectLeadService: ProjectLeadService
    ) {}

  ngOnInit(): void {
    this.getProjects()
  }

  getProjects() {
    
    this.projectLeadService.getManagerProjects().subscribe({
      next: data => {
        data.forEach(e => {
          e.confirmedPercent = Math.floor(e.progress.confirmed / e.progress.total * 100)
          e.interruptedPercent = Math.floor(e.progress.interrupted / e.progress.total * 100)
          e.donePercent = Math.floor(e.progress.done / e.progress.total * 100)
        })
        this.projects = data
      },
      error: err => console.error(err.toString)
    })
    
    
    
  }

  toggleWOM(projectId) {
    this.showReports = true
    this.showWOM = false
    this.projectId = projectId
  }

  logout() {
    this.authService.doLogoutUser()
    this.router.navigate(['/'])
  }

  onTriggerShow(newValue: boolean) {
    if (newValue) {
      this.showWOM = true
      this.showReports = false
    } else {
      this.showWOM = false
      this.showReports = true
    }
  }

  onBackToDashboard(newValue: boolean) {
    if (newValue) {
      this.showWOM = false
      this.showReports = true
      this.showDetail = false
    } else {
      this.showWOM = false
      this.showReports = false
      this.showDetail = true
    }
  }

  onDetailShow(newValue: number) {
    if (newValue) {
      this.showDetail = true
      this.reportWorkorderDetailId = newValue
      this.showReports = false
      this.showWOM = false
    } else {
      this.showReports = true
      this.showWOM = false
      this.showDetail = false
      this.reportWorkorderDetailId = null
    }
  }
}
