import { Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-call-log',
  template: `
    <div class="mt-4">
        <h5>{{title}}</h5>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="border-right min-height-th">{{ 'call-service.dashboard.customer' | translate }}</th>
                <th class="border-right min-height-th">{{ 'call-service.dashboard.phone' | translate }}</th>
                <th class="border-right min-height-th">{{ 'basic.address' | translate}}</th>
                <th class="border-right min-height-th">{{ 'call-service.dashboard.contractor' | translate }}</th>
                <th class="border-right min-height-th">{{ 'call-service.dashboard.accessType' | translate }}</th>
                <th class="border-right min-height-th">{{ 'call-service.dashboard.msa' | translate }}</th>
                <th class="border-right min-height-th">{{ 'basic.phase' | translate }}</th>
                <th class="border-right min-height-th">{{ 'call-service.dashboard.timeSlot' | translate }}</th>
                <th class="border-right min-height-th">{{ 'call-service.dashboard.daysLeft' | translate }}</th>
                <th class="border-right sort min-height-th d-flex align-items-center" (click)="sortByCallLog()">
                  <span>{{ 'call-service.dashboard.callLog' | translate }}</span>
                  <img src="assets/icons/swap_vert_24.svg">
                </th>
                <th class="border-right min-height-th">{{ 'call-service.dashboard.additionalInfo' | translate }}</th>
                <th>{{ 'call-service.dashboard.actions' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of callingList">
                <tr *ngIf="item.show" [ngClass]="getPhoneCommunicatedValue(item.phone_communicated)"
                  class="border-bottom">
                  <td class="call-log-name border-right">{{ item.contact_name }} ({{'personTypes.' + item.person_type | translate }})</td>
                  <td class="column-width border-right">
                    {{ item.phone }}
                    <img src="assets/icons/Copy_icon.svg" alt="Copy Icon" (click)="copyToClipboard(item.phone)" />
                    <img src="assets/icons/Call_icon.svg" alt="Call Icon" (click)="callPhoneNumber(item.phone)" />
                  </td>
                  <td class="column-width border-right">{{item.address}}</td>
                  <td class="column-width border-right">{{ item.contractor_name }}</td>
                  <td class="border-right column-width" *ngIf="item.access_type">{{'accessTypes.' + item.access_type | translate }}</td>
                  <td class="border-right column-width" *ngIf="!item.access_type">{{'call-service.accessTypeNotFound' | translate }}</td>
                  <td class="column-width border-right">
                    <span *ngIf="item.msa_name">{{item.msa_name}}</span>
                    <span *ngIf="!item.msa_name">-</span>
                  </td>
                  <td class="column-width border-right">
                    <span *ngIf="item.phase">{{'planner.reports.phase.' + item.phase | translate }}</span>
                    <span *ngIf="!item.phase">-</span>
                  </td>
                  <td class="installation-time-passed-list-time-window border-right">
                    <ng-container
                      *ngIf="item.time_start && item.time_window_start && item.time_end && item.time_window_end">
                      {{ item.time_window_start | date: 'dd.MM.yyyy' }} {{ formatTime(item.time_start) }} - {{
                      formatTime(item.time_end) }}
                    </ng-container>
                    <ng-container
                      *ngIf="!item.time_start || !item.time_window_start || !item.time_end || !item.time_window_end">
                      {{'planner.endUserCommunications.timeNotFound' | translate }}
                    </ng-container>
                  </td>
                  <td class="column-width border-right">
                    <ng-container *ngIf="item.time_window_start">
                      {{ calculateDaysUntilDate(item.time_window_start) }}
                      {{ calculateDaysUntilDate(item.time_window_start) === 1 ? ('call-service.day' | translate) :
                      ('call-service.days' | translate) }}
                    </ng-container>
                    <ng-container *ngIf="!item.time_window_start">
                      {{'planner.endUserCommunications.timeNotFound' | translate }}
                    </ng-container>
                  </td>
                  <td class="column-width border-right">
                    <span>{{ item.call_count }}/3</span>
                    <img *ngIf="item.call_count > 0" src="assets/icons/Call_not_answered_icon.svg" alt="Call Icon" />
                    <img *ngIf="item.call_count === 0" src="assets/icons/Call_not_called_icon.svg"
                      alt="No Call Icon" />
                  </td>
                  <td class="notes column-width border-right">
                    <div *ngIf="item.notes" class="tooltip-container">
                      <img src="assets/icons/info_blue_24dp.svg" class="info-icon">
                      <span class="tooltip-text">{{item.notes}}</span>
                    </div>
                    <div *ngIf="!item.notes">-</div>
                  </td>
                  <td class="installation-time-passed-list-log-a-call-button">
                    <button *ngIf="!item.callStatus" class="btn btn-primary"
                      (click)="moveToEnduserPage(item.enduser_id, item.workorder_id)">
                      {{ 'call-service.openDetails' | translate }}
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
    </div>
  `,
  styleUrls: ['./call-service-dashboard.component.scss'],
  standalone: false
})
export class CallLogComponent {
    @Input() title: string = ''
    @Input() callingList: any[] = []
    sortState: string = 'asc'

    constructor(
        private clipboardService: ClipboardService,
        private translateService: TranslateService,
        private router: Router
    ) {}

    sortByCallLog() {
        this.callingList.sort((a, b) => {
          let comparison = 0;
          if (a.call_count < b.call_count) {
            comparison = -1;
          } else if (a.call_count > b.call_count) {
            comparison = 1;
          }
          // Apply the common sort direction
          return this.sortState === 'asc' ? comparison : -comparison;
        });
        // Toggle the sort state for next time
        this.sortState = this.sortState === 'asc' ? 'desc' : 'asc';
    }

    getPhoneCommunicatedValue(phoneCommunicatedValue) {
        if (!phoneCommunicatedValue) return null
        let objects = JSON.parse(phoneCommunicatedValue)
        if (objects) {
          // Get today's date in "YYYY-MM-DD" format
          const today = new Date().toISOString().slice(0, 10);
          
          // Check if any object has a date matching today and is not answered
          const boolean = objects.some(element => element.date === today);
        
          // Return 'gray-bg' if an unanswered call today is found, null otherwise
          return boolean ? 'gray-bg' : null;
        } else return null
    }

    copyToClipboard(text: string) {
        if (this.clipboardService.isSupported) {
          this.clipboardService.copy(text);
          alert(this.translateService.instant('planner.endUserCommunications.copiedToClipboard') + text);
        } else {
          // Handle clipboard not supported
          alert(this.translateService.instant('planner.endUserCommunications.notCopiedToClipboard'));
        }
    }

    callPhoneNumber(phoneNumber: string) {
        if (phoneNumber) {
          window.location.href = 'tel:' + phoneNumber;
        }
    }

    formatTime(time: string): string {
        if (!time) return time;
        const [hours, minutes] = time.split(':');
        return `${hours}:${minutes}`;
    }

    calculateDaysUntilDate(targetDate: string): number {
        const targetDateObj = new Date(targetDate);
        targetDateObj.setHours(0, 0, 0, 0)
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0)
        const timeDifferenceInDays = Math.floor((+targetDateObj - +currentDate) / (1000 * 60 * 60 * 24));
        return timeDifferenceInDays;
    }

    moveToEnduserPage(id, workorderId) {
        const queryParamsValue: Params = { workorderId: workorderId, backTab: 2 };
        this.router.navigate(['call-service/enduser/' + id], {
          queryParams: queryParamsValue,
          queryParamsHandling: 'merge'
        })
      }
}