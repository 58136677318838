import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
  standalone: false
})
export class PlannerComponent implements OnInit {

  constructor() { }


  /**
   * Calls to loadscript which loads the chosen script dynamically
   */
   ngOnInit() {
    this.loadScript("assets/js/sidebar.js")
  }

  /**
   * Handles the dynamic loading of main.js that mazer provides.
   * Main.js controls the behaviour of our main/left side navigation.
   * If we load this in app.component, it will display console errors on pages such as login. (because there is no mazer navigation present)
   * @param url 
   */
  public loadScript(url) {
    let node = document.createElement('script')
    node.src = url
    node.type = 'text/javascript'
    document.getElementsByTagName('head')[0].appendChild(node)
  }
}
