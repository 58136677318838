import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-field-reporting-header',
  templateUrl: './field-reporting-header.component.html',
  styleUrls: ['./field-reporting-header.component.scss'],
  standalone: false
})
export class FieldReportingHeaderComponent implements OnInit {
  

  constructor(
    private authService: AuthService,
    private router: Router
    )
     { }

  

  ngOnInit(): void {}

  /**
   * Log user out of system and redirect to login page.
   */
  logout() {
    this.authService.logout()
    this.router.navigate(['/login'])
  }


}
