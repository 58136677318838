<div class="page-container">
   <div class="page-heading">
      <h3>
         <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
         <span class="page-title">{{'planner.contractorPage.contractorSettings' | translate }}</span>
      </h3>
   </div>
   <div class="page-content">
         <div class="card">
            <div class="card-body">
               <div id="contractor-basic-information">
                  <div class="row">
                     <div class="col-md-6 right-border">
                        <div>
                           <div class="row">
                              <div class="col-md-8">
                                 <div class="form-group">
                                    <h4>{{'planner.contractorPage.basicInfo' | translate }}</h4>
                                 </div>
                              </div>
                              <div class="col-md-4">
                                 <button class="btn btn-secondary float-end" (click)="showContractorInfo = !showContractorInfo"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">{{'basic.edit' | translate }}</button>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label>{{'basic.name' | translate }}</label>
                                 <div>{{ contractorName }}</div>
                                 <load-spinner [hidden]="contractorName"></load-spinner>
                              </div>
                              <div class="form-group">
                                 <label>{{'basic.info' | translate }}</label>
                                 <div [hidden]="showContractorInfo">{{contractorInfo}}</div>
                                <textarea class="mb-3 w-100" cols="30" rows="12" [(ngModel)]="contractorInfo" [hidden]="!showContractorInfo" style="resize: none;"></textarea>
                                <button type="button" class="btn btn-primary left" [hidden]="!showContractorInfo" (click)="saveInfo()" data-bs-dismiss="modal">{{'basic.save' | translate}}</button>
                                <button type="button" class="btn btn-secondary ml-1" [hidden]="!showContractorInfo" (click)="showContractorInfo = !showContractorInfo"> {{'basic.cancel' | translate }}</button>

                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                    <label>{{'basic.transformers' | translate }}</label>
                                    <div>{{ contractorProjectCount }}</div>
                                    <load-spinner [hidden]="contractorProjectCount >= 0"></load-spinner>
                              </div>
                              <div class="form-group">
                                    <label>{{'basic.meters' | translate }}</label>
                                    <div>{{ contractorOrderCount }}</div>
                                    <load-spinner [hidden]="contractorOrderCount >= 0"></load-spinner>

                              </div>
                              <div class="form-group">
                                 <label>{{'basic.installTime' | translate }}</label>
                                 <div>{{ calculatedTime }}</div>
                                 <load-spinner [hidden]="calculatedTime"></load-spinner>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 section-padding">
                        <div class="d-flex">
                           <div class="info-hover shadow-sm" [hidden]="!infoHover">
                              {{'planner.contractorPage.createMailingFile' | translate}}
                              <br>
                              <br>
                              {{'planner.contractorPage.mailingForwarded' | translate}}
                           </div>
                           <h4 class="mr-1">{{'planner.contractorPage.mailingFile' | translate}} </h4><img class="info-button" src="/assets/icons/info_blue_filled.svg" (mouseover)="hoverHandler(true, 'info')" (mouseout)="hoverHandler(false, 'info')">
                        </div>


                        <div *ngIf="!contractorData" class="gray-text mt-10">
                           <load-spinner></load-spinner>
                        </div>
                        <div *ngIf="contractorData">

                           <div class="dual-container" [hidden]="!scheduledText && !freeText">
                              <div class="left"></div>
                              <div class="right mr-1">
                                 <label>{{'planner.contractorPage.mailingFileType' | translate}} </label>
                                 <select class="form-select" [(ngModel)]="mailType" (change)="setMailText($event.target.value); setModalText($event.target.value)">
                                    <option value="scheduled">{{'planner.contractorPage.scheduled' | translate}} </option>
                                    <option value="free" selected>{{'planner.contractorPage.openAccess' | translate}} </option>
                                 </select>
                              </div>

                           </div>
                           <div class="form-group mb-5" *ngIf="selectedText">
                              <label class="mb-1">{{'planner.contractorPage.textField' | translate}} </label>
                              <p class="mail-text text-truncate">{{selectedText}}</p>
                           </div>

                           <div class="dual-container w-50">
                              <button class="btn btn-primary left" data-bs-toggle="modal" data-bs-target="#mailingFileBackDrop" *ngIf="(mailType == 'scheduled' && !scheduledText) || (mailType == 'free' && !freeText)">+ {{'planner.contractorPage.createMailingFile2' | translate}} </button>
                              <button class="btn btn-secondary left" data-bs-toggle="modal" data-bs-target="#mailingFilePreview" *ngIf="(mailType == 'scheduled' && scheduledText) || (mailType == 'free' && freeText)">{{'planner.contractorPage.previewMail' | translate}} </button>
                              <button class="btn btn-secondary right" data-bs-toggle="modal" data-bs-target="#mailingFileEdit"  *ngIf="(mailType == 'scheduled' && scheduledText) || (mailType == 'free' && freeText)">{{'planner.contractorPage.editMailingFile' | translate}} </button>
                           </div>


                           <!-- Create mail modal -->

                           <div class="modal" id="mailingFileBackDrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="mailingFileLabel" aria-hidden="true">
                              <div class="modal-dialog">
                                 <div class="modal-content">
                                    <div class="modal-header" *ngIf="mailingPreview">
                                       <h5 class="modal-title">{{'planner.contractorPage.mailingFilePreview' | translate}} </h5>
                                       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="mailingPreviewSetup(false); clearFiles()"></button>
                                    </div>
                                    <div class="modal-header" *ngIf="!mailingPreview">
                                       <h5 class="modal-title">{{'planner.contractorPage.createMailingFile2' | translate}} </h5>
                                       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body" *ngIf="!mailingPreview">
                                       <span class="gray-text mb-1" [hidden]="scheduledText || freeText">{{'planner.contractorPage.mailingFileType' | translate}}</span>
                                       <select class="form-select w-40" [(ngModel)]="modalType" (change)="setModalText($event.target.value)" [hidden]="scheduledText || freeText">
                                          <option value="scheduled">{{'planner.contractorPage.scheduled' | translate}}</option>
                                          <option value="free" selected>{{'planner.contractorPage.openAccess' | translate}}</option>
                                       </select>
                                       <span class="gray-text left" style="padding-top: 1rem;">{{'planner.contractorPage.textField' | translate}}</span>

                                       <button class="btn btn-secondary right mb-1" (mouseover)="hoverHandler(true)" (mouseout)="hoverHandler(false)">{{'planner.contractorPage.variables' | translate}}</button>

                                      <div class="variables shadow-sm" [hidden]="!variablesHover">
                                        <div>{{'basic.firstName' | translate}}: [FIRSTNAME]</div>
                                        <div>{{'basic.lastName' | translate}}: [LASTNAME]</div>
                                        <div>{{'planner.contractorPage.dateTime' | translate}}: [DATETIME]</div>
                                        <div>{{'basic.address' | translate}}: [ADDRESS]</div>
                                        <div>{{'basic.meterNumber' | translate}}: [METERNUMBER]</div>
                                        <div>{{'planner.contractorPage.username' | translate}}: [USERNAME]</div>
                                        <div>{{'planner.contractorPage.password' | translate}}: [PASSWORD]</div>
                                      </div>

                                       <textarea class="mb-3 w-100" cols="30" rows="12" [(ngModel)]="createMailText" style="resize: none;"></textarea>

                                       <span class="gray-text mb-1">{{'planner.contractorPage.logo' | translate}}</span>
                                       <div ngClass="{{dragAreaClass}}" style="height: 150px !important;" class="mt-1">
                                          <div *ngIf="!fileSelected" class="text-center">
                                             <header>{{'planner.contractorPage.dragLogo' | translate}}</header>
                                          </div>
                                          <div *ngIf="fileSelected" class="text-center">
                                                <header class="blue-text">{{ fileName }}<span> {{'basic.selected' | translate}}</span></header>
                                          </div>
                                          <input type="file" id="fileInput" hidden accept="image/*">
                                       </div>
                                       <button type="button" class="btn btn-primary mt-5" (click)="saveLogo()">{{'import.uploadFromPC' | translate}}</button>
                                       <br>
                                       <button type="button" class="btn btn-secondary mt-5 mb-3" (click)="mailingPreviewSetup(true)">{{'planner.contractorPage.previewMail' | translate}}</button>
                                    </div>
                                    <div class="modal-body" *ngIf="mailingPreview">
                                       <button type="button" class="btn btn-secondary mb-3" (click)="mailingPreviewSetup(false)">< {{'planner.contractorPage.backToCreate' | translate}}</button>
                                       <textarea class="w-100" cols="30" rows="25" [(ngModel)]="createMailText" readonly style="resize: none;"></textarea>
                                    </div>

                                    <div class="modal-footer" style="justify-content: flex-start;" *ngIf="!mailingPreview">
                                       <button type="button" class="btn btn-primary left" (click)="saveMailingTemplate()" data-bs-dismiss="modal">{{'planner.contractorPage.saveMailingTemplate' | translate}}</button>
                                       <button type="button" class="btn btn-secondary left" data-bs-dismiss="modal" (click)="clearFiles()">{{'basic.close' | translate}}</button>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <!-- Edit mail modal -->

                           <div class="modal" id="mailingFileEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="mailingFileLabel" aria-hidden="true">
                              <div class="modal-dialog">
                                 <div class="modal-content">
                                    <div class="modal-header" *ngIf="mailingPreview">
                                       <h5 class="modal-title">{{'planner.contractorPage.editMailingFilePreview' | translate}}</h5>
                                       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="mailingPreviewSetup(false); clearFiles()"></button>
                                    </div>
                                    <div class="modal-header" *ngIf="!mailingPreview">
                                       <h5 class="modal-title">{{'planner.contractorPage.editMailingFile' | translate}}</h5>
                                       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body" *ngIf="!mailingPreview">
                                       <span class="gray-text mb-1" [hidden]="!scheduledText || !freeText">{{'planner.contractorPage.mailingFileType' | translate}}</span>
                                       <select class="form-select w-40" [(ngModel)]="modalType" (change)="setModalText($event.target.value)" [hidden]="!scheduledText || !freeText">
                                          <option value="scheduled">{{'planner.contractorPage.scheduled' | translate}}</option>
                                          <option value="free" selected>{{'planner.contractorPage.openAccess' | translate}}</option>
                                       </select>
                                       <span class="gray-text left" style="padding-top: 1rem;">{{'planner.contractorPage.textField' | translate}}</span>

                                       <button class="btn btn-secondary right mb-1" (mouseover)="hoverHandler(true)" (mouseout)="hoverHandler(false)">{{'planner.contractorPage.variables' | translate}}</button>
                                       <textarea class="mb-3 w-100" cols="30" rows="12"  *ngIf="modalType == 'scheduled'" [(ngModel)]="scheduledTextEdit" style="resize: none;"></textarea>
                                       <textarea class="mb-3 w-100" cols="30" rows="12" *ngIf="modalType == 'free'" [(ngModel)]="freeTextEdit" style="resize: none;"></textarea>



                                       <span class="gray-text mb-1">{{'planner.contractorPage.logo' | translate}}</span>
                                       <div ngClass="{{dragAreaClass}}" style="height: 150px !important;" class="mt-1">
                                          <div *ngIf="!fileSelected" class="text-center">
                                             <header>{{'planner.contractorPage.dragLogo' | translate}}</header>
                                          </div>
                                          <div *ngIf="fileSelected" class="text-center">
                                                <header class="blue-text">{{ fileName }}<span> {{'basic.selected' | translate}}</span></header>
                                          </div>
                                          <input type="file" hidden accept="image/*">
                                       </div>
                                       <button type="button" class="btn btn-primary mt-5">{{'import.uploadFromPC' | translate}}</button>
                                       <br>
                                       <button type="button" class="btn btn-secondary mt-5 mb-3" (click)="mailingPreviewSetup(true)">{{'planner.contractorPage.previewMail' | translate}}</button>
                                    </div>
                                    <div class="modal-body" *ngIf="mailingPreview">
                                       <button type="button" class="btn btn-secondary mb-3" (click)="mailingPreviewSetup(false)">< {{'basic.back' | translate}}</button>
                                       <textarea class="mb-3 w-100" cols="30" rows="25"  *ngIf="modalType == 'scheduled'" readonly [(ngModel)]="scheduledTextEdit" style="resize: none;"></textarea>
                                       <textarea class="mb-3 w-100" cols="30" rows="25" *ngIf="modalType == 'free'" readonly [(ngModel)]="freeTextEdit" style="resize: none;"></textarea>
                                    </div>

                                    <div class="modal-footer" style="justify-content: flex-start;" *ngIf="!mailingPreview">
                                       <button type="button" class="btn btn-primary left" (click)="editMailTemplate()" data-bs-dismiss="modal">{{'basic.saveChanges' | translate}}</button>
                                       <button type="button" class="btn btn-secondary left" data-bs-dismiss="modal" (click)="clearFiles()">{{'basic.close' | translate}}</button>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <!-- Preview mail modal -->

                           <div class="modal" id="mailingFilePreview" data-bs-keyboard="false" tabindex="-1" aria-labelledby="mailingFileLabel" aria-hidden="true">
                              <div class="modal-dialog">
                                 <div class="modal-content">

                                    <div class="modal-header">
                                       <h5 class="modal-title">{{'planner.contractorPage.previewMail' | translate}}</h5>
                                       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body" *ngIf="!mailingPreview">
                                       <span class="gray-text mb-1" [hidden]="!scheduledText || !freeText">{{'planner.contractorPage.mailingFileType' | translate}}</span>
                                       <select class="form-select w-40" [(ngModel)]="modalType" (change)="setModalText($event.target.value)" [hidden]="!scheduledText || !freeText">
                                          <option value="scheduled">{{'planner.contractorPage.scheduled' | translate}}</option>
                                          <option value="free" selected>{{'planner.contractorPage.openAccess' | translate}}</option>
                                       </select>

                                       <button class="btn btn-secondary right mb-1" (mouseover)="hoverHandler(true)" (mouseout)="hoverHandler(false)">{{'planner.contractorPage.variables' | translate}}</button>

                                       <span class="gray-text left" style="padding-top: 1rem;">{{'planner.contractorPage.textField' | translate}}</span>
                                       <textarea class="w-100" cols="30" rows="25" *ngIf="modalType == 'scheduled'" [(ngModel)]="scheduledText" readonly style="resize: none;"></textarea>
                                       <textarea class="w-100" cols="30" rows="25" *ngIf="modalType == 'free'" [(ngModel)]="freeText" readonly style="resize: none;"></textarea>
                                    </div>

                                    <div class="modal-footer" style="justify-content: flex-start;">
                                       <button type="button" class="btn btn-secondary left" data-bs-dismiss="modal">{{'basic.close' | translate}}</button>
                                    </div>
                                 </div>
                              </div>
                           </div>

                        </div>

                     </div>

                  </div>
               </div>

               <hr> <!-- Divider -->

               <div class="w-40 mb-10" *ngIf="myParam">
                  <div class="form-group">
                     <h4>{{'planner.contractorPage.importInfo' | translate }}</h4>
                  </div>

                  <div class="mt-5">
                     <div class="blue-text text-center fs-5 mb-1">{{queueProgress}} {{'planner.contractorPage.workorderProcessed' | translate }}</div>
                     <div class="mt-5">
                        {{'planner.contractorPage.notifyImport' | translate }}<br>
                        {{'planner.contractorPage.proceedTimeEstimates' | translate }}
                     </div>
                     <button class="btn btn-secondary mt-5" *ngIf="!queFinished">{{'basic.wait' | translate }}</button>
                     <button class="btn btn-primary mt-5" *ngIf="queFinished" (click)="newContractorNextBtn()">{{'basic.next' | translate }}</button>

                  </div>
               </div>
               <div *ngIf="!myParam">
                  <div class="row">
                     <!-- Contractor default time estimates begin -->
                     <div class="col-md-6 text-capitalize pb-10">
                        <div class="row">
                           <div class="col-md-8">
                              <div class="form-group">
                                 <h4>{{'planner.contractorPage.contractorTimeEstimates' | translate }}</h4>
                              </div>
                           </div>
                           <div class="col-md-4" [hidden]="estimatesLoading">
                              <button [hidden]="timeEditor" [disabled]="!contractorTimeEstimates" id="timeEditBtn" class="btn btn-secondary ml-10 float-end" (click)="enableTimeEditor()"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">Edit</button>
                              <div [hidden]="!timeEditor">
                                 <button type="button" class="btn btn-primary" form="time-parameters" type="submit"> {{'basic.save' | translate }}</button>
                                 <button type="button" class="btn btn-secondary ml-1" (click)="disableTimeEditor()"> {{'basic.cancel' | translate }}</button>
                             </div>
                           </div>
                        </div>
                        <!-- Display contractor default time estimates -->
                        <div [hidden]="timeEditor || estimatesLoading">
                           <div [hidden]="contractorTimeEstimates" class="red-text">{{'planner.contractorPage.noTimeEstimates' | translate}}</div>
                           <div [hidden]="!contractorTimeEstimates">
                              <div class="estimate-holder mt-10" *ngFor="let estimate of contractorTimeEstimates;let index = index">
                                 <div class="form-group">
                                    <label>{{ estimate.name }}</label>
                                 </div>
                                 <div class="estimates row" *ngFor="let item of estimate.estimates">
                                    <div class="col-md-6">
                                       <div>
                                          {{ item.name }}
                                       </div>
                                    </div>
                                    <div class="col-md-3 minutes">
                                       <div>
                                          {{ item.time }} <span>{{'planner.contractorPage.minutes' | translate}}</span>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div *ngIf="contractorTimeEstimates[index]['highlighted'] == 'shape'" [innerHTML]="setIconByAttribute(item.attribute)">
                                       </div>
                                       <div *ngIf="contractorTimeEstimates[index]['highlighted'] == 'color'">
                                          <span class="dot" [ngStyle]="{'background-color': item.attribute}"></span>
                                       </div>
                                       <div *ngIf="contractorTimeEstimates[index]['highlighted'] == 'dots'">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 32 32">
                                             <defs>
                                               <clipPath id="clip-Element_dot_1">
                                                 <rect width="32" height="32"/>
                                               </clipPath>
                                             </defs>
                                             <g id="Element_dot_1" data-name="Element + dot – 1" clip-path="url(#clip-Element_dot_1)">
                                               <g id="Ellipse_696" data-name="Ellipse 696" transform="translate(4 4)" fill="none" stroke="#a2a2a3" stroke-width="3">
                                                 <circle cx="12" cy="12" r="12" stroke="none"/>
                                                 <circle cx="12" cy="12" r="10.5" fill="none"/>
                                               </g>
                                               <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" [attr.fill]="item.attribute"  />
                                             </g>
                                           </svg>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <!-- Contractor default time estimates editor -->
                        <div [hidden]="!timeEditor">
                           <form ngNativeValidate id="time-parameters" class="estimate-holder" #f="ngForm" (ngSubmit)="saveChangesToEstimates(f)">
                              <div *ngFor="let estimate of contractorTimeEstimates;let index = index;" class="mt-10">
                                 <div class="form-group">
                                    <div class="row">
                                       <div class="col-6">
                                          <label> {{'basic.headline' | translate }}</label>
                                       </div>
                                       <div class="col-6">
                                          <label *ngIf="contractorTimeEstimates[index]['checked']">{{'planner.contractorPage.elementType' | translate }}</label>
                                       </div>
                                    </div>

                                    <div class="row">
                                       <div class="col-6">
                                          <input class="form-control" readonly type="text" [(ngModel)]="contractorTimeEstimates[index]['name']" name="{{ index }}" value="{{ estimate.type }}">
                                          <div class="mt-1">
                                             <input class="form-check-input mr-1" type="checkbox" name="{{ index+'-checked' }}" [(ngModel)]="contractorTimeEstimates[index]['checked']" [checked]="contractorTimeEstimates[index]['checked']"><label>{{'planner.contractorPage.highlightedParam' | translate}}</label>
                                          </div>
                                       </div>
                                       <div class="col-6">
                                          <ng-container *ngIf="contractorTimeEstimates[index]['checked']">
                                             <input class="d-none" type="text" name="{{ index+'-highlighted' }}" [(ngModel)]="contractorTimeEstimates[index]['highlighted']">
                                             <div ngbDropdown class="d-inline-block">
                                                <button class="btn btn-secondary" id="dropdownBasic2" ngbDropdownToggle type="button" [innerHtml]="setElementType(contractorTimeEstimates, index)"></button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                                                  <button ngbDropdownItem type="button" (click)="setSelectedElementType(contractorTimeEstimates, index, 'color')">
                                                     <img src="assets/icons/attributes/colors.svg"></button>
                                                  <button ngbDropdownItem type="button" (click)="setSelectedElementType(contractorTimeEstimates, index, 'shape')">
                                                      <img src="assets/icons/attributes/shapes.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedElementType(contractorTimeEstimates, index, 'dots')">
                                                      <img src="assets/icons/attributes/dots.svg"></button>
                                                   </div>
                                              </div>
                                          </ng-container>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="form-group col-md-6">
                                       <label>{{'basic.options' | translate }}</label>
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label>{{'basic.estimates' | translate }}</label>
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label *ngIf="contractorTimeEstimates[index]['checked'] && contractorTimeEstimates[index]['highlighted']">{{'basic.attribute' | translate }}</label>
                                    </div>
                                 </div>
                                 <div class="row" *ngFor="let item of estimate.estimates;let i = index;">
                                    <div class="col-md-6">
                                       <div class="form-group">
                                          <input type="text" readonly [(ngModel)]="item.name" name="{{ index+'-name_'+i }}" class="form-control">
                                       </div>
                                    </div>
                                    <div class="col-md-3 minutes">
                                       <div class="form-group">
                                          <input type="number" [(ngModel)]="item.time" name="{{ index+'-time_'+i }}" class="form-control">
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div *ngIf="contractorTimeEstimates[index]['checked']" class="form-group text-capitalize">

                                          <input type="color" required *ngIf="contractorTimeEstimates[index]['highlighted'] == 'color'" class="form-control" name="{{ index+'-attribute_'+i }}" [(ngModel)]="item.attribute">

                                          <div class="row" *ngIf="contractorTimeEstimates[index]['highlighted'] == 'shape'">
                                             <div class="col">
                                               <div ngbDropdown class="d-inline-block">
                                                 <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle type="button" [innerHtml]="setIcon(contractorTimeEstimates, index, i)"></button>
                                                 <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'ball')">
                                                      <img src="assets/select-icons/select_gray_ball.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'diamond')">
                                                      <img src="assets/select-icons/select_gray_diamond.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'hexagon')">
                                                      <img src="assets/select-icons/select_gray_hexagon.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'octagon')">
                                                      <img src="assets/select-icons/select_gray_octagon.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'plumbob')">
                                                      <img src="assets/select-icons/select_gray_plumbob.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'reverse-triangle')">
                                                      <img src="assets/select-icons/select_gray_reverse_triangle.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'square-star')">
                                                      <img src="assets/select-icons/select_gray_square_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'square')">
                                                      <img src="assets/select-icons/select_gray_square.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'star')">
                                                      <img src="assets/select-icons/select_gray_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'triangle')">
                                                      <img src="assets/select-icons/select_gray_triangle.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'north-star')">
                                                      <img src="assets/select-icons/select_gray_north_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(contractorTimeEstimates, index, i, 'cross')">
                                                      <img src="assets/select-icons/select_gray_cross.svg"></button>
                                                 </div>
                                               </div>
                                             </div>
                                          </div>

                                          <input type="color" required *ngIf="contractorTimeEstimates[index]['highlighted'] == 'dots'" class="form-select" name="{{ index+'-attribute_'+i }}" [(ngModel)]="item.attribute">
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                        <div [hidden]="!estimatesLoading" class="gray-text mt-10">
                           <load-spinner></load-spinner>
                        </div>
                     </div>
                     <!-- MSA time estimates begin -->
                     <div class="col-md-6 text-capitalize right-container pb-10">
                        <div class="row">
                           <div class="col-md-8">
                              <div class="form-group">
                                 <h4>{{'planner.contractorPage.msaTimeEstimates' | translate }}</h4>
                              </div>
                           </div>
                           <div class="col-md-4">
                              <button [hidden]="msaEditor" id="timeEditBtn" class="btn btn-secondary ml-10 float-end" (click)="enableMsaEditor()"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">{{'basic.edit' | translate }}</button>
                              <div [hidden]="!msaEditor">
                                 <button type="button" class="btn btn-primary" form="msa-parameters" type="submit">{{'basic.save' | translate }}</button>
                                 <button type="button" class="btn btn-secondary ml-1" (click)="disableTimeEditor()">{{'basic.cancel' | translate }}</button>
                             </div>
                           </div>
                        </div>
                        <select class="form-select w-50" *ngIf="msaNames" (change)="msaSelect($event.target.value)" [attr.disabled]="msaEditor ? '' : null">
                           <option *ngFor="let stuff of msaNames" value="{{stuff.id}}" [selected]="stuff.id == selectedMsaId">{{stuff.name}}</option>
                        </select>
                        <!-- Display MSA time estimates  -->
                        <div [hidden]="msaEditor" *ngIf="selectedMsa">
                           <div class="estimate-holder mt-10" *ngFor="let estimate of selectedMsa">
                              <div class="form-group">
                                 <label>{{ estimate.name }}</label>
                              </div>
                              <div class="estimates row" *ngFor="let item of estimate.estimates">
                                 <div class="col-md-6">
                                    <div>
                                       {{ item.name }}
                                    </div>
                                 </div>
                                 <div class="col-md-3 minutes">
                                    <div>
                                       {{ item.time }} <span>{{'planner.contractorPage.minutes' | translate}}</span>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div *ngIf="estimate.highlighted == 'shape'" [innerHTML]="setIconByAttribute(item.attribute)">
                                    </div>
                                    <div *ngIf="estimate.highlighted != 'shape'">
                                       <span class="dot" [ngStyle]="{'background-color': item.attribute}"></span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <!-- Edit MSA time estimates -->
                        <div [hidden]="!msaEditor || msaEstimatesLoading" *ngIf="selectedMsa">
                           <form ngNativeValidate id="msa-parameters" class="estimate-holder" #f="ngForm" (ngSubmit)="saveChangesToMsaEstimates(f)">
                              <div *ngFor="let estimate of selectedMsa;let index = index;" class="mt-10">
                                 <div class="form-group">
                                    <div class="row">
                                       <div class="col-6">
                                          <label>{{'basic.headline' | translate }}</label>
                                       </div>
                                       <div class="col-6">
                                          <label *ngIf="selectedMsa[index]['checked']">{{'planner.contractorPage.elementType' | translate }}</label>
                                       </div>
                                    </div>

                                    <div class="row">
                                       <div class="col-6">
                                          <input class="form-control" readonly type="text" [(ngModel)]="selectedMsa[index]['name']" name="{{ index }}" value="{{ estimate.name }}">
                                          <div class="mt-1">
                                             <input class="form-check-input mr-1" type="checkbox" name="{{ index+'-checked' }}" [(ngModel)]="selectedMsa[index]['checked']" [checked]="selectedMsa[index]['highlighted']"><label>{{'planner.contractorPage.highlightedParam' | translate }}</label>
                                          </div>
                                       </div>
                                       <div class="col-3">
                                          <ng-container *ngIf="selectedMsa[index]['checked']">
                                             <input class="d-none" type="text" name="{{ index+'-highlighted' }}" [(ngModel)]="selectedMsa[index]['highlighted']">

                                             <div ngbDropdown class="d-inline-block">
                                                <button class="btn btn-secondary" id="dropdownBasic3" ngbDropdownToggle type="button" [innerHtml]="setElementType(selectedMsa, index)"></button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                                                   <button ngbDropdownItem type="button" (click)="setSelectedElementType(selectedMsa, index, 'color')">
                                                      <img src="assets/icons/attributes/colors.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedElementType(selectedMsa, index, 'shape')">
                                                      <img src="assets/icons/attributes/shapes.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedElementType(selectedMsa, index, 'dots')">
                                                      <img src="assets/icons/attributes/dots.svg"></button>
                                                </div>
                                             </div>
                                          </ng-container>
                                       </div>
                                       <div class="col-3">
                                          <button class="btn btn-secondary" (click)="deleteMsaEstimate(index, estimate.name)" type="button">{{'basic.delete' | translate }}</button>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="form-group col-md-6">
                                       <label>{{'basic.options' | translate }}</label>
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label>{{'basic.estimates' | translate }}</label>
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label *ngIf="selectedMsa[index]['checked'] && selectedMsa[index]['highlighted']">{{'basic.attribute' | translate }}</label>
                                    </div>
                                 </div>
                                 <div class="row" *ngFor="let item of estimate.estimates;let i = index;">
                                    <div class="col-md-6">
                                       <div class="form-group">
                                          <input type="text" readonly [(ngModel)]="item.name" name="{{ index+'-name_'+i }}" class="form-control">
                                       </div>
                                    </div>
                                    <div class="col-md-3 minutes">
                                       <div class="form-group">
                                          <input type="number" [(ngModel)]="item.time" name="{{ index+'-time_'+i }}" class="form-control">
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div *ngIf="selectedMsa[index]['checked']" class="form-group text-capitalize">

                                          <input type="color" required *ngIf="selectedMsa[index]['highlighted'] == 'color'" class="form-control" name="{{ index+'-attribute_'+i }}" [(ngModel)]="item.attribute">

                                          <div class="row" *ngIf="selectedMsa[index]['highlighted'] == 'shape'">
                                             <div class="col">
                                               <div ngbDropdown class="d-inline-block">
                                                 <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle type="button" [innerHtml]="setIcon(selectedMsa, index, i)"></button>
                                                 <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'ball')">
                                                      <img src="assets/select-icons/select_gray_ball.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'diamond')">
                                                      <img src="assets/select-icons/select_gray_diamond.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'hexagon')">
                                                      <img src="assets/select-icons/select_gray_hexagon.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'octagon')">
                                                      <img src="assets/select-icons/select_gray_octagon.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'plumbob')">
                                                      <img src="assets/select-icons/select_gray_plumbob.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'reverse-triangle')">
                                                      <img src="assets/select-icons/select_gray_reverse_triangle.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'square-star')">
                                                      <img src="assets/select-icons/select_gray_square_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'square')">
                                                      <img src="assets/select-icons/select_gray_square.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'star')">
                                                      <img src="assets/select-icons/select_gray_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'triangle')">
                                                      <img src="assets/select-icons/select_gray_triangle.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'north-star')">
                                                      <img src="assets/select-icons/select_gray_north_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(selectedMsa, index, i, 'cross')">
                                                      <img src="assets/select-icons/select_gray_cross.svg"></button>
                                                 </div>
                                               </div>
                                             </div>
                                          </div>

                                          <input type="color" required *ngIf="selectedMsa[index]['highlighted'] == 'dots'" class="form-select" name="{{ index+'-attribute_'+i }}" [(ngModel)]="item.attribute">
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                        <div [hidden]="msaEstimatesLoading" *ngIf="msaNames?.length > 0 && selectedMsa?.length < 1" class="gray-text mt-10">
                           {{'planner.contractorPage.noEstimatesMsa' | translate }}
                        </div>
                        <div [hidden]="!msaEstimatesLoading" class="gray-text mt-10">
                           <load-spinner></load-spinner>
                        </div>
                        <div *ngIf="!msaEstimatesLoading && msaNames?.length < 1" class="gray-text mt-10">
                           {{'planner.contractorPage.noMsa' | translate }}
                        </div>
                        <!-- Add new MSA time estimate -->
                        <div [hidden]="!msaEditor || msaEstimatesLoading" *ngIf="allEstimatesObject">
                           <button class="btn btn-primary mt-5" (click)="activateMsaAdding()">+ {{'planner.contractorPage.addEstimate' | translate }}</button>
                           <form class="mt-5" id="msa-estimate-add-form" [hidden]="!addMsaActive" #f="ngForm" (ngSubmit)="addParameterToSelectedMsa(f)" *ngIf="chosenEstimateObject">
                              <h4>{{'planner.contractorPage.newEstimate' | translate }}</h4>
                              <div class="form-group">
                                 <div class="row">
                                    <div class="col-6">
                                       <label>{{'basic.headline' | translate }}</label>
                                    </div>
                                    <div class="col-3">
                                       <label *ngIf="allEstimatesObject['checked']">{{'planner.contractorPage.elementType' | translate }}</label>
                                    </div>
                                 </div>

                                 <div class="row">
                                    <div class="col-6">
                                       <select class="form-select w-50 " name="estimateToMsa" (change)="setChosen($event.target.value)">
                                          <option *ngFor="let stuff of allEstimatesObject; let i = index" value="{{i}}">{{stuff.type}}</option>
                                       </select>
                                       <div class="mt-1">
                                          <input class="form-check-input mr-1" type="checkbox" name="checked" [(ngModel)]="allEstimatesObject['checked']" [checked]="chosenEstimateObject['highlighted']"><label>{{'planner.contractorPage.highlightedParam' | translate }}</label>
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <ng-container *ngIf="allEstimatesObject['checked']">
                                          <select required class="form-select" name="highlighted" [(ngModel)]="chosenEstimateObject['highlighted']">
                                             <option value="color">{{'planner.contractorPage.color' | translate }}</option>
                                             <option value="shape">{{'planner.contractorPage.shape' | translate }}</option>
                                             <option value="dots">{{'planner.contractorPage.dots' | translate }}</option>
                                          </select>
                                       </ng-container>
                                    </div>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="form-group col-md-6">
                                    <label>{{'basic.options' | translate }}</label>
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label>{{'basic.estimates' | translate }}</label>
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label *ngIf="chosenEstimateObject['highlighted']">{{'basic.attribute' | translate }}</label>
                                 </div>
                              </div>
                              <div>
                                 <div class="row" *ngFor="let item of chosenEstimateObject.estimates;let i = index;">
                                    <div class="col-md-6">
                                       <div class="form-group">
                                          <input type="text" readonly [(ngModel)]="item.name" name="{{ '-name_'+i }}" class="form-control">
                                       </div>
                                    </div>
                                    <div class="col-md-3 minutes">
                                       <div class="form-group">
                                          <input type="number" [(ngModel)]="item.time" name="{{ '-time_'+i }}" class="form-control" required>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div *ngIf="chosenEstimateObject['highlighted']" class="form-group text-capitalize">

                                          <input type="color" required *ngIf="chosenEstimateObject['highlighted'] == 'color'" class="form-control" name="{{ 'attribute_'+i }}" [(ngModel)]="item.attribute">

                                          <div class="row" *ngIf="chosenEstimateObject['highlighted'] == 'shape'">
                                             <div class="col">
                                               <div ngbDropdown class="d-inline-block">
                                                 <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle type="button" [innerHtml]="setIcon(chosenEstimateObject, false, i)"></button>
                                                 <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'ball')">
                                                      <img src="assets/select-icons/select_gray_ball.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'diamond')">
                                                      <img src="assets/select-icons/select_gray_diamond.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'hexagon')">
                                                      <img src="assets/select-icons/select_gray_hexagon.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'octagon')">
                                                      <img src="assets/select-icons/select_gray_octagon.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'plumbob')">
                                                      <img src="assets/select-icons/select_gray_plumbob.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'reverse-triangle')">
                                                      <img src="assets/select-icons/select_gray_reverse_triangle.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'square-star')">
                                                      <img src="assets/select-icons/select_gray_square_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'square')">
                                                      <img src="assets/select-icons/select_gray_square.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'star')">
                                                      <img src="assets/select-icons/select_gray_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'triangle')">
                                                      <img src="assets/select-icons/select_gray_triangle.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'north-star')">
                                                      <img src="assets/select-icons/select_gray_north_star.svg"></button>
                                                   <button ngbDropdownItem type="button" (click)="setSelectedIconAttribute(chosenEstimateObject, false, i, 'cross')">
                                                      <img src="assets/select-icons/select_gray_cross.svg"></button>
                                                 </div>
                                               </div>
                                             </div>
                                          </div>

                                          <input type="color" required *ngIf="chosenEstimateObject['highlighted'] == 'dots'" class="form-control" name="{{ 'attribute_'+i }}" [(ngModel)]="item.attribute">
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <button class="btn btn-primary mr-5" form="msa-estimate-add-form" type="submit">{{'planner.contractorPage.saveEstimate' | translate }}</button>
                              <button class="btn btn-secondary" type="button" (click)="cancelMsaEstimateAdding()">{{'basic.cancel' | translate }}</button>
                           </form>
                        </div>
                     </div>
                  </div>
                  <!-- Fuse types, Extra work types and Out of range reasons display for Field Reporting installers -->
                  <!-- Uncomment to get displayed
                  <hr>
                  <div class="d-flex">
                     <div class="col-6">
                        <h4>{{'planner.contractorPage.fuseTypes' | translate}}</h4>
                        <div *ngFor="let fuseType of fuseTypes;let i = index">
                           <div class="d-flex">
                              <div class="col-6">
                                 <label class="gray-text font-size-normal" *ngIf="i === 0">{{'basic.name' | translate}}</label>
                                 <div>{{fuseType.name}}</div>
                              </div>
                              <div class="col-6">
                                 <label class="gray-text font-size-normal" *ngIf="i === 0">{{'planner.contractorPage.opensCustomText' | translate}}</label>
                                 <div *ngIf="fuseType.openCustom === true">{{'basic.yes' | translate}}</div>
                                 <div *ngIf="fuseType.openCustom === false">{{'basic.no' | translate}}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-6 right-container">
                        <h4>{{'planner.contractorPage.extraWorkTypes' | translate}}</h4>
                        <div *ngFor="let extraWorkType of extraWorkTypes;let i = index">
                           <div class="d-flex">
                              <div class="col-6">
                                 <label class="gray-text font-size-normal" *ngIf="i === 0">{{'basic.name' | translate}}</label>
                                 <div>{{extraWorkType.name}}</div>
                              </div>
                              <div class="col-6">
                                 <label class="gray-text font-size-normal" *ngIf="i === 0">{{'planner.contractorPage.opensCustomText' | translate}}</label>
                                 <div *ngIf="extraWorkType.openCustom === true">{{'basic.yes' | translate}}</div>
                                 <div *ngIf="extraWorkType.openCustom === false">{{'basic.no' | translate}}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <hr>
                  <div class="d-flex">
                     <div class="col-6 right-border">
                        <h4>{{'planner.contractorPage.reasons' | translate}}</h4>
                        <div *ngFor="let reason of outOfRangeReasons;let i = index">
                           <div class="d-flex">
                              <div class="col-6">
                                 <label class="gray-text font-size-normal" *ngIf="i === 0">{{'basic.name' | translate}}</label>
                                 <div>{{reason.name}}</div>
                              </div>
                              <div class="col-6">
                                 <label class="gray-text font-size-normal" *ngIf="i === 0">{{'planner.contractorPage.opensCustomText' | translate}}</label>
                                 <div *ngIf="reason.openCustom === true">{{'basic.yes' | translate}}</div>
                                 <div *ngIf="reason.openCustom === false">{{'basic.no' | translate}}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  -->

                  <hr>
                  <div class="row">
                     <div class="col d-flex">
                        <h4 class="col-8">{{'planner.contractorPage.barcodeTypesAllowed' | translate}}</h4>
                        <div class="col-4">
                           <button *ngIf="!barcodeEdit" id="barcode-edit-on" (click)="setBarcodeEdit(true)" class="btn btn-secondary float-end"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">{{'basic.edit' | translate }}</button>
                           <div *ngIf="barcodeEdit" class="float-end">
                              <button class="btn btn-primary" (click)="saveBarcodes()">{{'basic.save' | translate}}</button>
                              <button class="btn btn-secondary ms-2" (click)="resetBarcodes()">{{'basic.cancel' | translate}}</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div *ngIf="!barcodeEdit">
                     <div class="gray-text">{{'planner.contractorPage.barcodeTypes' | translate}}</div>
                     <div *ngIf="selectedBarcodes.length > 0" class="d-flex flex-wrap">
                        <div *ngFor="let barcode of selectedBarcodes" class="selected-barcode">{{barcode.description}}</div>
                     </div>
                     <div *ngIf="selectedBarcodes.length === 0">{{'basic.notSelected' | translate}}</div>
                  </div>
                  <div class="d-flex" *ngIf="barcodeEdit">
                     <div class="normal-display">
                        <div class="gray-text">{{'planner.contractorPage.barcodeTypes' | translate}}</div>
                        <div ngbDropdown [autoClose]="'outside'" class="d-inline-block">
                           <button type="button" class="btn btn-outline-secondary" id="dropdown-barcodes" ngbDropdownToggle>{{'planner.contractorPage.selectBarcodeTypes' | translate}}</button>
                           <div ngbDropdownMenu aria-labelledby="dropdown-barcodes" >
                              <div *ngFor="let barcode of barcodes">
                                 <div ngbDropdownItem class="d-flex" (click)="selectBarcode(barcode)">
                                    <input class="form-check-input checkbox-input cursor-pointer" type="checkbox" [(ngModel)]="barcode.selected">
                                    <div>{{barcode.description}}</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="normal-display ms-5">
                        <div class="gray-text">{{'basic.selected' | translate}}</div>
                        <div *ngIf="selectedBarcodes.length > 0" class="d-flex flex-wrap">
                           <div *ngFor="let item of selectedBarcodes" class="selected-barcode">{{item.description}}</div>
                        </div>
                        <div *ngIf="selectedBarcodes.length === 0">{{'basic.notSelected' | translate}}</div>
                     </div>
                  </div>

                 <hr>
                 <div class="row">
                   <div class="col d-flex">
                     <h4 class="col-8">{{'planner.contractorPage.smsService' | translate}}</h4>
                     <div class="col-4">
                       <button *ngIf="!smsEdit" id="sms-edit-on" (click)="setSmsEdit(true)" class="btn btn-secondary float-end"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">{{'basic.edit' | translate }}</button>
                     </div>
                   </div>
                 </div>
                 <div *ngIf="!smsEdit">
                   <div *ngIf="smsMessages.length > 0" class="d-flex flex-wrap row row-cols-3">
                     <div *ngFor="let sms of smsMessages" class="smss col-4 mb-4">
                       {{sms.content}}
                       <br /><br />
                       <strong><i>{{sms.sendTimingFiltered}} {{(sms.sendTiming == '1d' ? 'planner.contractorPage.day' : 'planner.contractorPage.days') | translate}} {{'basic.before' | translate}}</i></strong>
                     </div>
                   </div>
                   <div *ngIf="smsMessages.length === 0">{{'planner.contractorPage.noMessagesCreated' | translate}}</div>
                 </div>

                 <div class="smsVariableContainer" *ngIf="smsEdit">
                   <div class="row">
                     <div class="col-3">
                       <div class="smsMessageSender mb-4">
  <!--                       <div class="gray-text">{{'planner.contractorPage.nameOfSender' | translate}}</div>-->
                         <!--                       <input type="text" class="form-control max-content mt-2" [(ngModel)]="smsSender" value="{{smsSettings.sender}}" required>-->
                         <div class="gray-text">{{'planner.contractorPage.selectTimezone' | translate}}</div>
                         <select name="timezone_offset" id="timezone-offset" [(ngModel)]="smsTimezone"  class="span5 form-select">
                           <option [selected]="smsTimezone === 'Pacific/Kwajalein'" value="Pacific/Kwajalein">(GMT -12:00) Eniwetok, Kwajalein</option>
                           <option [selected]="smsTimezone === 'Pacific/Samoa'" value="Pacific/Samoa">(GMT -11:00) Midway Island, Samoa</option>
                           <option [selected]="smsTimezone === 'US/Hawaii'" value="US/Hawaii">(GMT -10:00) Hawaii</option>
                           <option [selected]="smsTimezone === 'Pacific/Marquesas'" value="Pacific/Marquesas">(GMT -9:30) Taiohae</option>
                           <option [selected]="smsTimezone === 'America/Juneau'" value="America/Juneau">(GMT -9:00) Alaska</option>
                           <option [selected]="smsTimezone === 'US/Pacific'" value="US/Pacific">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                           <option [selected]="smsTimezone === 'US/Mountain'" value="US/Mountain">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                           <option [selected]="smsTimezone === 'US/Central'" value="US/Central">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                           <option [selected]="smsTimezone === 'US/Eastern'" value="US/Eastern">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                           <option [selected]="smsTimezone === 'America/Caracas'" value="America/Caracas">(GMT -4:30) Caracas</option>
                           <option [selected]="smsTimezone === 'Canada/Atlantic'" value="Canada/Atlantic">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                           <option [selected]="smsTimezone === 'Canada/Newfoundland'" value="Canada/Newfoundland">(GMT -3:30) Newfoundland</option>
                           <option [selected]="smsTimezone === 'America/Sao_Paulo'" value="America/Sao_Paulo">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                           <option [selected]="smsTimezone === 'Atlantic/South_Georgia'" value="Atlantic/South_Georgia">(GMT -2:00) Mid-Atlantic</option>
                           <option [selected]="smsTimezone === 'Atlantic/Cape_Verde'" value="Atlantic/Cape_Verde">(GMT -1:00) Azores, Cape Verde Islands</option>
                           <option [selected]="smsTimezone === 'Europe/London'" value="Europe/London">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                           <option [selected]="smsTimezone === 'Europe/Copenhagen'"  value="Europe/Copenhagen">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                           <option [selected]="smsTimezone === 'Europe/Helsinki'"  value="Europe/Helsinki">(GMT +2:00) Bucharest, Helsinki, Kiev</option>
                           <option [selected]="smsTimezone === 'Europe/Moscow'"  value="Europe/Moscow">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                           <option [selected]="smsTimezone === 'Asia/Tehran'"  value="Asia/Tehran">(GMT +3:30) Tehran</option>
                           <option [selected]="smsTimezone === 'Asia/Muscat'"  value="Asia/Muscat">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                           <option [selected]="smsTimezone === 'Asia/Kabul'"  value="Asia/Kabul">(GMT +4:30) Kabul</option>
                           <option [selected]="smsTimezone === 'Asia/Yekaterinburg'"  value="Asia/Yekaterinburg">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                           <option [selected]="smsTimezone === 'Asia/Kolkata'"  value="Asia/Kolkata">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                           <option [selected]="smsTimezone === 'Asia/Kathmandu'"  value="Asia/Kathmandu">(GMT +5:45) Kathmandu, Pokhara</option>
                           <option [selected]="smsTimezone === 'Asia/Almaty'"  value="Asia/Almaty">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                           <option [selected]="smsTimezone === 'Asia/Yangon'"  value="Asia/Yangon">(GMT +6:30) Yangon, Mandalay</option>
                           <option [selected]="smsTimezone === 'Asia/Bangkok'"  value="Asia/Bangkok">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                           <option [selected]="smsTimezone === 'Asia/Singapore'"  value="Asia/Singapore">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                           <option [selected]="smsTimezone === 'Australia/Eucla'"  value="Australia/Eucla">(GMT +8:45) Eucla</option>
                           <option [selected]="smsTimezone === 'Asia/Tokyo'"  value="Asia/Tokyo">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                           <option [selected]="smsTimezone === 'Australia/Darwin'"  value="Australia/Darwin">(GMT +9:30) Adelaide, Darwin</option>
                           <option [selected]="smsTimezone === 'Pacific/Guam'"  value="Pacific/Guam">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                           <option [selected]="smsTimezone === 'Australia/Lord_Howe'"  value="Australia/Lord_Howe">(GMT +10:30) Lord Howe Island</option>
                           <option [selected]="smsTimezone === 'Asia/Magadan'"  value="Asia/Magadan">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                           <option [selected]="smsTimezone === 'Pacific/Nauru'"  value="Pacific/Nauru">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                           <option [selected]="smsTimezone === 'Pacific/Chatham'"  value="Pacific/Chatham">(GMT +12:45) Chatham Islands</option>
                           <option [selected]="smsTimezone === 'Pacific/Apia'"  value="Pacific/Apia">(GMT +13:00) Apia, Nukualofa</option>
                           <option [selected]="smsTimezone === 'Pacific/Kiritimati'"  value="Pacific/Kiritimati">(GMT +14:00) Line Islands, Tokelau</option>
                         </select>
                       </div>
                     </div>
                   </div>
                   <div class="row">
                     <div class="col-3">
                       <button class="btn btn-secondary mb-1 mr-1" (mouseover)="hoverHandler( true, 'sms')" (mouseout)="hoverHandler( false, 'sms')">{{'planner.contractorPage.variables' | translate}}</button>
                       <div class="msg-tooltip smsTooltipHover" [hidden]="smsTooltipHover === false" (mouseover)="hoverHandler( true, 'sms')" (mouseout)="hoverHandler( false, 'sms')">
                         <div class="text-warning">{{'planner.contractorPage.charactersWarning' | translate}}</div>
                         <div>{{'basic.firstName' | translate}}: [FIRSTNAME]</div>
                         <div>{{'basic.lastName' | translate}}: [LASTNAME]</div>
                         <div>{{'planner.contractorPage.dateTime' | translate}}: [DATETIME]</div>
                         <div>{{'planner.contractorPage.startTime' | translate}}: [STARTTIME]</div>
                         <div>{{'planner.contractorPage.endTime' | translate}}: [ENDTIME]</div>
                         <div>{{'basic.address' | translate}}: [ADDRESS]</div>
                         <div>{{'basic.meterNumber' | translate}}: [METERNUMBER]</div>
                         <div>{{'planner.contractorPage.username' | translate}}: [USERNAME]</div>
                         <div>{{'planner.contractorPage.password' | translate}}: [PASSWORD]</div>
                       </div>
                     </div>
                   </div>
                   <div class="d-flex smscontainer row row-cols-3">
                     <div *ngFor="let sms of smsMessages; let i = index" class="smss col-4 px-2 mb-4">
                       <div class="smsMessageContent-{{i}} mb-1">
                         <button class="btn btn-secondary right mb-1" (click)="removeSms(i)"><img src="assets/icons/Bin.svg" class="btn-icon btnimg"></button>
                         <div class="gray-text">{{'planner.contractorPage.message' | translate}}</div>
                         <textarea class="m-c-child-content edit-input form-control" [maxlength]="sms.contentMaxLength" [(ngModel)]="sms.content" (ngModelChange)="checkMaxLength(sms, $event)" ></textarea>
                         <div [hidden]="!sms.over1Message" class="gray-text right">{{'planner.contractorPage.longMessage' | translate}}</div>
                         <div class="gray-text">{{sms.realLength}}/{{sms.contentMessageLength}} {{'planner.contractorPage.characters' | translate}}</div>
                       </div>
                       <div class="smsMessageSchedule-{{i}} mb-1">
                         <div class="gray-text">{{'planner.contractorPage.messageSchedule' | translate}}</div>
  <!--                       <select class="form-select" [(ngModel)]="mailType" (change)="setMailText($event.target.value); setModalText($event.target.value)">-->
                         <select class="form-select" [(ngModel)]="sms.sendTiming">
<!--                           <option style="font-size: 1pt; background-color: #707591;" disabled>&nbsp;</option>-->
                           <option *ngFor="let e of [].constructor(21); let i = index" value="{{i+1}}d" [selected]="sms.sendTiming === '{{i+1}}d'">{{i+1}} {{ (i == 0 ? 'planner.contractorPage.day' : 'planner.contractorPage.days') | translate}} {{'basic.before' | translate}}</option>
                         </select>
                       </div>
                       <div class="smsSendWhen-{{i}}">
<!--                         <input class="form-check-input float-start mr-1 smsMessageWhen-{{i}}" type="checkbox"[(ngModel)]="sms.sendWhen">-->
<!--                         <div class="gray-text">{{'planner.contractorPage.sendNotConfirmed' | translate}}</div>-->
                         <div class="gray-text">{{'planner.contractorPage.sendAccessType' | translate}}</div>
                         <select class="form-select" [(ngModel)]="sms.sendAccessType">
                           <option value="1" [selected]="sms.sendAccessType === '1'">{{ 'accessTypes.1' | translate}} </option>
                           <option value="2" [selected]="sms.sendAccessType === '2'">{{ 'accessTypes.2' | translate}} </option>
                           <option value="3" [selected]="sms.sendAccessType === '3'">{{ 'accessTypes.3' | translate}} </option>
                           <option value="any" [selected]="sms.sendAccessType === 'any'">{{'basic.any' | translate}}</option>
                         </select>
                         <div class="gray-text">{{'planner.contractorPage.sendPhase' | translate}}</div>
                         <select class="form-select" [(ngModel)]="sms.sendPhase">
                           <option value="1" [selected]="sms.sendPhase === '1'">{{ 'planner.reports.phase.1' | translate}} </option>
                           <option value="2" [selected]="sms.sendPhase === '2'">{{ 'planner.reports.phase.2' | translate}} </option>
                           <option value="3" [selected]="sms.sendPhase === '3'">{{ 'planner.reports.phase.3' | translate}} </option>
                           <option value="4" [selected]="sms.sendPhase === '4'">{{ 'planner.reports.phase.4' | translate}} </option>
                           <option value="any" [selected]="sms.sendPhase === 'any'">{{'basic.any' | translate}}</option>
                         </select>
                         <div class="gray-text">{{'planner.contractorPage.sendWhen' | translate}}</div>
                         <select class="form-select" [(ngModel)]="sms.sendWhen">
<!--                           <option value="always" [selected]="sms.sendWhen === 'always'">{{ 'planner.contractorPage.sendAlways' | translate}} </option>-->
                           <option value="ifconfirmed" [selected]="sms.sendWhen === 'ifconfirmed'">{{ 'planner.contractorPage.sendIfConfirmed' | translate}} </option>
                           <option value="ifnotconfirmed" [selected]="sms.sendWhen === 'ifnotconfirmed'">{{ 'planner.contractorPage.sendIfNotConfirmed' | translate}} </option>
                         </select>
                         <div class="gray-text">{{'planner.contractorPage.sendToWho' | translate}}</div>
                         <select class="form-select" [(ngModel)]="sms.personType">
                           <option value="0" [selected]="sms.personType === '0'">{{ 'persontype.0' | translate}} </option>
                           <option value="2" [selected]="sms.personType === '2'">{{ 'persontype.2' | translate}} </option>
                           <option value="any" [selected]="sms.personType === 'any'">{{ 'basic.any' | translate}} </option>
                         </select>
                       </div>
                     </div>

                     <div class="smss col-4 px-2" id="newsms" *ngIf="smsMessages.length === 0" >
                       <div class="smsMessageContent mb-1">
                         <button class="btn btn-secondary right mb-1" (click)="removeSms()"><img src="assets/icons/Bin.svg" class="btn-icon btnimg"></button>
                         <div class="gray-text">{{'planner.contractorPage.message' | translate}}</div>
                         <!--                       <textarea class="m-c-child-content edit-input form-control" appTextCount [(ngModel)]="myModel1" maxlength="{{smsContentLength}}"></textarea>-->
                         <textarea class="m-c-child-content edit-input form-control" [maxlength]="this.smsContentMaxLength" [(ngModel)]="smsContent" (ngModelChange)="checkMaxLengthDefault($event)" ></textarea>
                         <div [hidden]="!this.over1Message" class="gray-text right">{{'planner.contractorPage.longMessage' | translate}}</div>
                         <div class="gray-text">{{this.smsContentRealLength}}/{{this.smsContentMessageLength}} {{'planner.contractorPage.characters' | translate}}</div>
                       </div>
                       <div class="smsMessageSchedule mb-1">
                         <div class="gray-text">{{'planner.contractorPage.messageSchedule' | translate}}</div>
                         <select class="form-select" [(ngModel)]="smsTiming">
<!--                           <option style="font-size: 1pt; background-color: #707591;" disabled>&nbsp;</option>-->
                           <option *ngFor="let e of [].constructor(21); let i = index" value="{{i+1}}d">{{i+1}} {{ (i == 0 ? 'planner.contractorPage.day' : 'planner.contractorPage.days') | translate}} {{'basic.before' | translate}} </option>
                         </select>
                       </div>
                       <div class="smsSendWhen">
                         <div class="gray-text">{{'planner.contractorPage.sendAccessType' | translate}}</div>
                         <select class="form-select" [(ngModel)]="smsSendAccessType">
                           <option value="1" [selected]="smsSendAccessType === '1'">{{ 'accessTypes.1' | translate}} </option>
                           <option value="2" [selected]="smsSendAccessType === '2'">{{ 'accessTypes.2' | translate}} </option>
                           <option value="3" [selected]="smsSendAccessType === '3'">{{ 'accessTypes.3' | translate}} </option>
                           <option value="any" [selected]="smsSendAccessType === 'any'">{{'basic.any' | translate}}</option>
                         </select>
                         <div class="gray-text">{{'planner.contractorPage.sendPhase' | translate}}</div>
                         <select class="form-select" [(ngModel)]="smsPhase">
                           <option value="1" [selected]="smsPhase === '1'">{{ 'planner.reports.phase.1' | translate}} </option>
                           <option value="2" [selected]="smsPhase === '2'">{{ 'planner.reports.phase.2' | translate}} </option>
                           <option value="3" [selected]="smsPhase === '3'">{{ 'planner.reports.phase.3' | translate}} </option>
                           <option value="4" [selected]="smsPhase === '4'">{{ 'planner.reports.phase.4' | translate}} </option>
                           <option value="any" [selected]="smsPhase === 'any'">{{'basic.any' | translate}}</option>
                         </select>
                         <div class="gray-text">{{'planner.contractorPage.sendWhen' | translate}}</div>
                         <select class="form-select" [(ngModel)]="smsSendWhen">
<!--                           <option value="always" [selected]="smsSendWhen === 'always'">{{ 'planner.contractorPage.sendAlways' | translate}} </option>-->
                           <option value="ifconfirmed" [selected]="smsSendWhen === 'ifconfirmed'">{{ 'planner.contractorPage.sendIfConfirmed' | translate}} </option>
                           <option value="ifnotconfirmed" [selected]="smsSendWhen === 'ifnotconfirmed'">{{ 'planner.contractorPage.sendIfNotConfirmed' | translate}} </option>
                         </select>
                         <div class="gray-text">{{'planner.contractorPage.sendToWho' | translate}}</div>
                         <select class="form-select" [(ngModel)]="smsSendToWho">
                           <option value="0" [selected]="smsSendToWho === '0'">{{ 'persontype.0' | translate}} </option>
                           <option value="2" [selected]="smsSendToWho === '2'">{{ 'persontype.2' | translate}} </option>
                           <option value="any" [selected]="smsSendToWho === 'any'">{{ 'basic.any' | translate}} </option>
                         </select>
                       </div>
                     </div>

                     <div class="col-2 px-2">
                       <button class="btn btn-light btn-lg" (click)="newSms()">{{'planner.contractorPage.newMessageTemplate' | translate}}</button>
                     </div>
                   </div>
                 </div>

                 <div class="d-flex mt-4" *ngIf="smsEdit">
                   <button class="btn btn-primary" (click)="saveSmss()">{{'basic.save' | translate}}</button>
                   <button class="btn btn-secondary ms-2" (click)="resetSmss()">{{'basic.cancel' | translate}}</button>
                 </div>

                 <hr>

                 <div class="col-12">
                  <div class="row">
                     <div class="col-md-8">
                        <div class="form-group">
                           <h4>{{'planner.contractorPage.smsMailingOptions' | translate}}</h4>
                        </div>
                     </div>
                     <div class="col-md-4">
                        <button [ngClass]="!editToggleMailingFileOptions ? 'btn-secondary' : 'btn-primary'" class="btn float-end" (click)="editToggleMailingFileOptions = !editToggleMailingFileOptions">
                           <img *ngIf="!editToggleMailingFileOptions" src="assets/icons/edit_gray_24dp.svg" class="btn-icon">
                           <span *ngIf="!editToggleMailingFileOptions">{{'basic.edit' | translate }}</span>
                           <span *ngIf="editToggleMailingFileOptions">{{'basic.cancel' | translate}}</span>
                        </button>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-md-6 section-padding">
                        <div class="form-group" *ngIf="!editToggleMailingFileOptions">
                           <label>{{'planner.contractorPage.endingTimeDelay' | translate}}</label>
                           <div>{{endingTimeDelay}} {{'planner.contractorPage.day(s)' | translate}}</div>
                        </div>
                        <div class="form-group" *ngIf="editToggleMailingFileOptions">
                           <label>{{'planner.contractorPage.endingTimeDelay' | translate}}</label>
                           <div ngbDropdown>
                              <button class="btn btn-secondary w-100" type="button" id="dropdownButton" ngbDropdownToggle>
                                  {{endingTimeDelay}} {{'planner.contractorPage.day(s)' | translate}}
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownButton" class="w-100">
                                  <div *ngFor="let endingTime of endingTimeDelayOptions">
                                      <button ngbDropdownItem type="button" (click)="changeEndingTimeDelay(endingTime)">{{endingTime}}</button>
                                  </div>
                              </div>
                          </div>
                        </div>
                     </div>
                  </div>
               </div>

                  <hr>
                  <div class="row mt-10">
                     <div class="col-md-10">
                        <div class="form-group">
                           <h4>{{'planner.contractorPage.importedInfo' | translate}}</h4>
                        </div>
                     </div>
                  </div>
                  <load-spinner *ngIf="importedLoading"></load-spinner>
                  <div *ngIf="!importedLoading">
                     <div class="mt-10 wrapper">
                        <div class="first mr-10">{{'planner.contractorPage.contractorCreated' | translate}} {{contractorCreated | date: 'dd.MM.yyyy HH:mm'}}</div>
                        <div class="second">{{contractorOrderCount}} {{'basic.meters' | translate | lowercase}}</div>
                     </div>
                     <div *ngIf="workorders.length > 0">
                        <div class="row mt-10">
                           <div class="col-md-12">
                              <table class="table table-striped" id="table1">
                                 <thead>
                                    <tr>
                                       <th>{{'basic.systemID' | translate}}</th>
                                       <th>{{'basic.address' | translate}}</th>
                                       <th>{{'basic.workorderID' | translate}}</th>
                                       <th>{{'basic.transformerID' | translate}}</th>
                                       <th>{{'basic.latitude' | translate}}</th>
                                       <th>{{'basic.longitude' | translate}}</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr *ngFor="let workorder of workorders | slice:0 :limitValue">
                                       <td>{{workorder.id}}</td>
                                       <td>{{workorder.address}}</td>
                                       <td>{{workorder.old_device_id}}</td>
                                       <td>{{workorder.project_id}}</td>
                                       <td>{{workorder.coordinates.latitude}}</td>
                                       <td>{{workorder.coordinates.longitude}}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div class="d-flex">
                           <div class="limitPosition">{{'planner.contractorPage.limitListCount' | translate}} </div>
                           <div ngbDropdown class="d-inline-block ms-2">
                              <button type="button" class="btn btn-outline-secondary" id="dropdownLimit" ngbDropdownToggle>
                                 {{limitValue}}
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownLimit">
                                 <button ngbDropdownItem (click)="changeLimit(10)">10</button>
                                 <button ngbDropdownItem (click)="changeLimit(25)">25</button>
                                 <button ngbDropdownItem (click)="changeLimit(50)">50</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                   <hr>
                   <div class="row mt-10">
                       <div class="col-md-10">
                           <h3>{{'planner.contractorPage.imagePortal' | translate}}</h3>
                           <input type="date" class="form-control-sm" [(ngModel)]="startDate" />
                           <input type="date" class="form-control-sm mx-1" [(ngModel)]="endDate" />
                           <button (click)="findImages()" class="btn btn-secondary" >{{'basic.search' | translate}}</button>

                           <div class="mt-3" *ngIf=" results !== null">
                               <p>{{'basic.results2' | translate}}<br>
                               <p>{{ results }} {{'planner.contractorPage.images' | translate}}</p>
                               <p [hidden]="!imagesLoading">{{'planner.contractorPage.imagesLoading' | translate}}</p>
                               <button [disabled]="imagesLoading" *ngIf="results" (click)="downloadImages()" class="btn btn-primary" >{{'planner.contractorPage.downloadImages' | translate}}</button>
                           </div>
                       </div>
                   </div>

                   <hr>

            </div>
         </div>
      </div>
   </div>
</div>
