import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectLeadDashboardComponent } from './components/project-lead-dashboard/project-lead-dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    ProjectLeadDashboardComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    TranslateModule
  ]
})
export class ProjectLeadModule { }
