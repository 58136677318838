import { Component, EventEmitter, Output, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Html5QrcodeScanner, Html5Qrcode } from 'html5-qrcode';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
  standalone: false
})
export class QrScannerComponent implements OnInit, OnDestroy {
  @Output() codeScanned = new EventEmitter<string>();
  private qrScanner!: Html5Qrcode;
  scannedCode: string = '';
  isCodeScanned = false;

  constructor(private cdr: ChangeDetectorRef, private dialogRef: MatDialogRef<QrScannerComponent>) { }

  ngOnInit() {
    this.qrScanner = new Html5Qrcode("qr-reader");

    Html5Qrcode.getCameras().then(cameras => {
      if (cameras && cameras.length > 0) {
        // Find the first back-facing camera, ensuring the label property exists
        const backCamera = cameras.find(camera => camera.label && camera.label.toLowerCase().includes('back'));

        // If no back camera is found, default to the last camera in the list
        const backupLastCamera = cameras.length - 1;
        const selectedCamera = backCamera || cameras[backupLastCamera];

        this.qrScanner.start(selectedCamera.id, {
          fps: 20,
          qrbox: 200,
          aspectRatio: 1.777777778,
          videoConstraints: {
            width: { ideal: 640 },
            height: { ideal: 360 },
            facingMode: "environment"
          }
        }, decodedText => {
          let extractedQRCode = this.extractLastEightDigits(decodedText);
          this.scannedCode = extractedQRCode;
          this.isCodeScanned = true;
          this.cdr.detectChanges(); // Update the view
        }, errorMessage => {
          console.error(errorMessage); // Handle scan error
        });
      } else {
        console.error('No cameras found.');
      }
    }).catch(err => {
      console.error('Error fetching cameras:', err);
    });
  }


  extractLastEightDigits(text: string): string {
    // Regular expression to find the last 8 digits in the string
    const regex = /(\d{8})\b/;
    const matches = text.match(regex);
    return matches ? matches[0] : '';
  }


  ngOnDestroy() {
    if (this.qrScanner) {
      this.qrScanner.clear(); // Clear the QR scanner
    }
  }

  stopScanning(): void {
    this.isCodeScanned = false;
    this.cdr.detectChanges(); // Update the view
    this.dialogRef.close(); // Close the dialog
    this.resetScanner();
  }

  confirmScan(): void {
    this.codeScanned.emit(this.scannedCode);
    this.resetScanner();
  }

  cancelScan(): void {
    this.isCodeScanned = false;
    this.scannedCode = '';
    this.cdr.detectChanges();
    this.resetScanner();
  }

  private resetScanner(): void {
    if (this.qrScanner) {
      this.qrScanner.stop().then(() => {
        this.qrScanner.clear(); // Clear the QR scanner
      }).catch(err => {
        console.error('Failed to stop the QR Scanner', err);
      });
    }
  }
}
