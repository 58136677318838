import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  standalone: false
})
export class SideNavComponent implements OnInit  {

  private navigationSubscription!: Subscription

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.handleRouteChange();
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleRouteChange();
      }
    });
  
  }
  
  handleRouteChange() {
    const path = this.activatedRoute.snapshot.firstChild?.url[0].path;

    const windowWidth = window.innerWidth
    if (windowWidth < 1200) {
      let toggle = document.getElementById('sidebar')
      if (toggle) {
        if (toggle.classList.contains('active') && path &&
          (!['map', 'dashboard', 'user-management', 'human-resources'].includes(path))       
        ) toggle.classList.remove('active')
      }
    }

    const humanResourcesSubmenu = document.getElementById('humanResourcesSubmenu');
    if (humanResourcesSubmenu) {
      if (path === 'human-resources' || path === 'workers' || path === 'worker-detail' || path === 'teams-detail' || path === 'teams' || path === 'weekly-planning-compare') {
       humanResourcesSubmenu.classList.add('active');
      } else {
       humanResourcesSubmenu.classList.remove('active');
      }
    }

    const mapSubmenu = document.getElementById('mapSubmenu');
    if (mapSubmenu) {
      if (path === 'map' || path === 'msa-map' || path === 'workorders-without-coordinates') {
        mapSubmenu.classList.add('active');
      } else {
        mapSubmenu.classList.remove('active');
      }
    }

    const dashSubmenu = document.getElementById('dashSubmenu');
    if (dashSubmenu) {
      if (path === 'dashboard' || path === 'contractor' || path === 'new-contractor' || path === 'import-data' || path === 'end-user-communication' || path === 'weekly-planning' || path === 'weekly-planning-map' || path === 'weekly-planning-detail' || path === 'reports') {
        dashSubmenu.classList.add('active');
      } else {
        dashSubmenu.classList.remove('active');
      }
    }

    const userManagementSubmenu = document.getElementById('user-managementSubmenu');
    if (userManagementSubmenu) {
      if (path === 'user-management' || path === 'planner-management' || path === 'callservice-management' || path === 'planner-detail' || path === 'callService-detail') {
        userManagementSubmenu.classList.add('active');
      } else {
        userManagementSubmenu.classList.remove('active');
      }
    }
  } 
  
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
