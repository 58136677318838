import { Component, OnDestroy, OnInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MapService } from '@modules/planner/services/map/map.service';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { forkJoin, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PlannerContractorService } from '@modules/planner/services/planner-contractor/planner-contractor.service';
import Swal from 'sweetalert2';

declare const L: any;
const lightColor = '#F4F7FC'

@Component({
  selector: 'app-weekly-planning-map',
  templateUrl: './weekly-planning-compare.component.html',
  styleUrls: ['./weekly-planning-compare.component.scss'],
  standalone: false
})
export class WeeklyPlanningCompareComponent implements OnInit, OnDestroy {

  @ViewChildren('detailsRef') detailsRefs!: QueryList<ElementRef>;

  componentDestroyed$: Subject<boolean> = new Subject()
  map: any
  allMsaEstimates: any
  contractorEstimates: any
  allEstimates: any
  contractorId: any
  markersExplanations = Array()
  showExplanations = true;
  infoBox = "assets/icons/category_gray_24dp.svg"
  mapInfoBox: string = "assets/icons/aspect_ratio_24.svg"
  showMapSizes: boolean = false
  mapMode: number = 3
  spinner = true;
  teamId
  teamSize: number = 0
  teamEfficiency: number = 1.00
  calendarId
  tableSpinner = true;
  defaultMarker: string = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot_1">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot_1" data-name="Element + dot – 1" clip-path="url(#clip-Element_dot_1)">    <g id="Ellipse_696" data-name="Ellipse 696" transform="translate(4 4)" fill="none" stroke="#a2a2a2" stroke-width="3">      <circle cx="12" cy="12" r="12" stroke="none"/>      <circle cx="12" cy="12" r="10.5" fill="none"/>    </g>  </g></svg>'
  moreMarkersRemoved = false
  markerNames: Array<any> = []
  markerIds: Array<any> = []

  //selected stuff
  selectedTime = '0'
  selectedSpinner = false
  timeSubscriptions: Subscription = new Subscription()

  //statuses
  selectSlotMode = false
  bypassMode = false
  showHideMarkers = Array()
  selectSlotMultiple: boolean = false

  //calendar
  calendarData: any[] = []
  calendarDays: any
  calendarToRender = new Map<string, Array<any>>();
  slotRows = Array();
  firstDay: any
  lastDay: any
  weekNr: any
  slots: any
  originalCalendarData: any[] = []
  allCalendars;
  allSlots = Array()

  // Leaflet groups
  workorderGroup: any
  projectGroup: any
  layerGroups: any
  markerClusterGroup: any



  defaultmax = 1000
  slotToEdit: number = 0
  oldSlotValue: null | number = null
  oldSlotI: number = 0
  oldSlotIndex: number = 0

  openedSlotId: string | null = null;
  private unsubscribeClickOutside?: () => void;

  // --------------------
  calendarIds: string[] = []
  moveMarkersToggle: boolean = false
  selectedMarkers: any[] = []


  constructor(
    private mapService: MapService,
    private workorderService: WorkorderService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private calendars: CalendarsService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private userService: UserService,
    private plannerContractorService: PlannerContractorService
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(qparams => {
      const calendarIdsParam = qparams['calendarIds'];
      if (calendarIdsParam) {
        // Assuming calendarIds should be an array of IDs
        this.calendarIds = calendarIdsParam.split(','); // Convert string to array
        this.getMsaEstimates(() => {
          this.getContractorInfo()
        })

      }
    });

  }

  /**
   * Gets msaEstimates as an object
   */
  getMsaEstimates(cb) {
    let allMsaEstimates = {}
    this.workorderService.getMsas()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
            allMsaEstimates[data[i].id] = data[i].default_time_parameters
          }
          this.allMsaEstimates = allMsaEstimates
          cb()

        }
      )
  }

  getContractorInfo() {
    this.getCurrentContractorId(() =>
      this.plannerContractorService.getContractorById(this.contractorId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.contractorEstimates = data.default_time_parameters
          this.allEstimates = data.all_estimates
          this.getCalendarIds(false)
        }
        )
    )
  }

  getCurrentContractorId(cb) {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.contractorId = data.current_contractor
          // cb() = callback to let the other function know that this one is finished
          cb()
        }
      )
  }

  getCalendarIds(useCurrentDate: boolean) {

    // Convert each element in this.calendarIds to an observable
    const observables = this.calendarIds.map(element =>
      this.calendars.getCalendarById(parseInt(element)).pipe(takeUntil(this.componentDestroyed$))
    );

    // Use forkJoin to wait for all observables to complete
    forkJoin(observables).subscribe(results => {
      // results is an array of all the data returned by the observables
      results.forEach(data => {
        this.calendarData.push(data);
        this.originalCalendarData.push(data);
      });

      // Run after all observables complete
      if (!useCurrentDate) this.setupCalendar(this.getFirstDayOfWeek(new Date()));
      else this.setupCalendar(this.firstDay)
    });

  }


  // Destroy subscriptions on this component when leaving page
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
    if (this.unsubscribeClickOutside) {
      this.unsubscribeClickOutside();
    }
  }



  /**
   * Builds the stuff which we need to render the calendar week.
   * slotRows is the array that is used to render the slots.
   * @param startDate
   * @param endDate
   * @param slots
   */
  setupCalendar(date: Date) {
    let start = date
    this.calendarData.forEach(calendar => {



      let calendarStart = new Date(start.getTime())
      //check if startdate is a monday
      this.firstDay = this.getFirstDayOfWeek(start)
      this.lastDay = this.getLastDayOfWeek(start)

      this.setWeekFromDate(this.firstDay)

      let allDays = Array()

      // We use start so that we dont mutate the firstDay Date
      while (calendarStart <= this.lastDay) {
        let x = new Date(calendarStart.getTime())
        allDays.push(x)
        calendarStart.setDate(calendarStart.getDate() + 1)
      }
      // calendarDays holds all the days that contain a slot.
      calendar.days = allDays
      calendar.map = new Map<string, Array<any>>();
      for (let i = 0; i < allDays.length; i++) {
        const element = allDays[i]

        let dateString = element.toLocaleDateString()

        let rowObjects = Array()

        for (const slot in calendar.slots) {
          let currentSlot = calendar.slots[slot]
          if (currentSlot.disabled === 0) currentSlot.color = 'white'
          let date = currentSlot.starttime
          let slotDateString = new Date(date).toLocaleDateString()

          if (slotDateString == dateString) {
            rowObjects.push(currentSlot)
          }
        }
        rowObjects.sort(this.compareSlots);
        // Sets data to map

        calendar.map.set(dateString, rowObjects)
      }

      // max slots is the maximum number of slots a day in a week can have
      let maxSlotsPerDay = 0
      if (calendar.map) {
        calendar.map.forEach(day => {
          if (day.length > maxSlotsPerDay) {
            maxSlotsPerDay = day.length
          }
        })
      }


      // We fill all slots using maxSlots as a boundary, slots that dont have data appear empty
      calendar.slotRows = []
      for (let index = 0; index < maxSlotsPerDay; index++) {
        const slotRow = Array()

        calendar.map.forEach(day => {
          if (day[index]) {
            if (day[index].workorders.length != 0) {
              this.putMarkersOnSlot(day[index], calendar)
            }

            slotRow.push(day[index])
          } else {
            slotRow.push(this.translateService.instant('planner.weeklyPlanningMap.emptySlot'))
          }

        })

        calendar.slotRows.push(slotRow)
      }
    })
  }

  /**
  * 2.11.2022
  * Compare function for slots.
  * Compares starttime and sorts according to them calendar slots.
  * Inside try block, because if we can't split starttime for some reason.
  * Error in try block return 0, so it won't affect sort.
  * @param a first element
  * @param b second element
  * @returns sort order
  */
  compareSlots(a, b) {
    try {
      let aStartTimeMinutes: number = parseInt(a.starttime.split(" ")[1].split(":")[0]) * 60 + parseInt(a.starttime.split(" ")[1].split(":")[1])
      let bStartTimeMinutes: number = parseInt(b.starttime.split(" ")[1].split(":")[0]) * 60 + parseInt(b.starttime.split(" ")[1].split(":")[1])
      if (aStartTimeMinutes < bStartTimeMinutes) return -1
      else if (aStartTimeMinutes > bStartTimeMinutes) return 1
      else return 0
    } catch (err) {
      return 0
    }
  }

  /**
   * Returns the name of given date in short form.
   * @param date
   * @returns
   *
   * Update: Configured to return local version day name.
   * Defaults to English version
   */
  getDayName(date) {
    const language = this.translateService.currentLang
    if (language === 'en') return date.toLocaleDateString('en-GB', { weekday: 'short' })
    else if (language === 'de') return date.toLocaleDateString('de-DE', { weekday: 'short' });
    else return date.toLocaleDateString('en-GB', { weekday: 'short' })
  }

  /**
   * Returns hours and minutes of startTime and endTime of a slot as string to render in calendar.
   *
   * example return: '07:00 - 10:00'
   * @param startString
   * @param endString
   * @returns
   */
  getTime(startString, endString) {

    if (!startString && !endString) return ''

    startString = new Date(startString)

    endString = new Date(endString)

    let startHours = startString.getHours()
    let startMinutes = startString.getMinutes()
    let endHours = endString.getHours()
    let endMinutes = endString.getMinutes()

    if (startHours < 10) startHours = '0' + startHours;
    if (startMinutes < 10) startMinutes = '0' + startMinutes;
    if (endHours < 10) endHours = '0' + endHours;
    if (endMinutes < 10) endMinutes = '0' + endMinutes;

    let startTime = startHours + ':' + startMinutes

    let endTime = endHours + ':' + endMinutes

    let totalTime = startTime + ' - ' + endTime

    return totalTime

  }


  setProgress(progress) {

    let color = "#FF9F0A"
    if (progress >= 90 && progress <= 100) {
      color = "#10A231"
    } else if (progress > 100) {
      color = "#0058FF"
    }

    let styles = {
      'width': progress + '%',
      'background-color': color
    };

    return styles
  }


  selectSlot(slotId, index) {
    if (!this.selectSlotMode) return;

    this.tableSpinner = true;
    let selectedSlot: any = null;

    // Find current slot
    for (let calendar of this.calendarData) {
        const slotRows = calendar.slotRows[index];
        if (slotRows) {
            const slot = slotRows.find(slot => slot.id === slotId);
            if (slot) {
                if (typeof slot === 'string' || slot.disabled === 1) {
                    this.toastService.sendToast(false, this.translateService.instant('planner.weeklyPlanningMap.selectValidSlot'));
                    this.tableSpinner = false;
                    return;
                }
                selectedSlot = slot;
                break; // Slot found, exit loop
            }
        }
    }

    if (!selectedSlot) {
        this.tableSpinner = false;
        return; // No slot found
    }

    // Combine markers, removing duplicates
    const allMarkers = this.selectedMarkers.flatMap(element => element.markers);
    const uniqueMarkers = selectedSlot.markers ?
        allMarkers.filter(marker => !selectedSlot.markers.some(slotMarker => marker.id === slotMarker.id)).concat(selectedSlot.markers) :
        allMarkers;

    this.finalizeSlot(uniqueMarkers, selectedSlot);
    this.saveCalendars();
    this.resetSelection();  // Moved to after saveCalendars to keep selectedMarkers available for processing  
  }

  resetSelection() {
   this.selectSlotMode = false;
   this.selectedMarkers = [];
   this.moveMarkersToggle = false;
   this.tableSpinner = false;
  }

  countIcons(markers) {
    const counts = {};
    markers.forEach(marker => {
      marker.checked = false;
      counts[marker.icon] = (counts[marker.icon] || 0) + 1;
    });
    return Object.keys(counts).map(key => ({
      icon: key,
      count: counts[key]
    }));
  }

  removeDuplicateMarkers(selectedSlot, slot) {
    slot.markers = slot.markers.filter(slotMarker =>
      !selectedSlot.markers.some(selectedMarker => selectedMarker.id === slotMarker.id));
  }

  finalizeSlot(allMarkers, selectedSlot) {
    // Initialize counts and set default properties
    const countsFinal = this.countIcons(allMarkers);

    // Set markers and counts to slot
    Object.assign(selectedSlot, {
        markers: allMarkers,
        counts: countsFinal,
        color: 'white',
        selected: false
    });

    // Simplify the nested loops for calendar data
    this.calendarData.forEach(calendar => {
        calendar.slotRows.forEach(slotRow => {
            slotRow.forEach(slot => {
                if (slot.id !== selectedSlot.id) {
                    this.selectedMarkers.forEach(selectedMarker => {
                        if (selectedMarker.slot.id === slot.id && selectedMarker.markers) {
                            this.removeDuplicateMarkers(selectedSlot, slot);
                            // Recount icons after potential removal
                            slot.counts = this.countIcons(slot.markers);
                            slot.selected = false;
                        }
                    });
                }
            });
        });
    });
}



  findSlotById(newSlotId) {
    for (let index = 0; index < this.calendarData.length; index++) {
      const calendar = this.calendarData[index];
      for (let i = 0; i < calendar.slotRows.length; i++) {
        const day = calendar.slotRows[i];
        for (let dayIndex = 0; dayIndex < day.length; dayIndex++) {
          const slot = day[dayIndex];
          if (slot.id === newSlotId) return slot
        }
      }
    }
  }

  /**
   * Function for placing markers to slot.counts and to slot.markers for showing them in interface.
   * Go through layerGroups to find markers with id that matches slots id. Then we save iconSVG for further usage.
   * In html we use SVG icon to generate icon for interface. Slot.counts show primary markers position and when opened
   * we show slot.markers as more detailed version.
   * @param slot slot that we get from getCalendars function
   */
  putMarkersOnSlot(slot, calendar) {
    let markers = Array();
    let counts = Array()
    // Go through every workorder
    for (let i = 0; i < slot.workorders.length; i++) {
      let workorderId = slot.workorders[i].workorder_id
      markers.push({
        icon: this.getSvgIcon(slot.workorders[i].time_parameters, calendar),
        id: workorderId,
        old_device_id: slot.workorders[i].old_device_id,
        time: slot.workorders[i].time,
        checked: this.selectedMarkers.some(sm => sm.markers.some(m => m.id === workorderId))
      })
    }

    let meters = markers
    let boolean = false
    meters.forEach((x) => {
      if (x.checked) boolean = true
      counts[x.icon] = (counts[x.icon] || 0) + 1
    });
    if (boolean) slot.selected = true
    else slot.selected = false

    let countsArray = Array()

    // We break the counts object and form an array from that data
    for (const key in counts) {
      if (Object.prototype.hasOwnProperty.call(counts, key)) {
        const element = counts[key];

        countsArray.push({
          icon: key,
          count: element
        })
      }
    }

    let freeAccess = (slot.fill_free_access / slot.duration) * 100

    let notFree = (slot.fill / slot.duration) * 100

    if (this.teamSize) {
      notFree = notFree / this.teamSize
      freeAccess = freeAccess / this.teamSize
    }
    if (this.teamEfficiency) {
      notFree = notFree / this.teamEfficiency
      freeAccess = freeAccess / this.teamEfficiency

    }
    slot.notFree = Math.round(notFree)
    slot.freeAccess = Math.round(freeAccess)

    // Put markers to correct places
    slot.counts = countsArray;
    slot.markers = markers;
  }

  getSvgIcon(time_parameters, calendar) {
    let timeEstim = []
    let msaTimeEstim = []
    let contractorDefaultEstimates

    if (time_parameters) {
      timeEstim = JSON.parse(time_parameters)
    }

    if (calendar.msa_id) {
      if (this.allMsaEstimates[calendar.msa_id]) {
        msaTimeEstim = JSON.parse(this.allMsaEstimates[calendar.msa_id])
        msaTimeEstim = msaTimeEstim[0]
      }
    }

    if (this.contractorEstimates) {
      contractorDefaultEstimates = JSON.parse(this.contractorEstimates)
    }

    let markerShape = null
    let markerColor = null
    let markerDots = null

    // This loop goes through contractor default time estimates to get possible shapes/colors of the marker

    for (let i in timeEstim) {
      const categoryid = timeEstim[i]['categoryid']
      const estimateid = timeEstim[i]['estimateid']

      for (let x in contractorDefaultEstimates) {
        const element = contractorDefaultEstimates[x];


        if (element['categoryid'] == categoryid) {
          const highlighted = contractorDefaultEstimates[x]['highlighted']
          if (contractorDefaultEstimates[x]['estimates']) {
            for (let index = 0; index < contractorDefaultEstimates[x]['estimates'].length; index++) {
              const timeEstimate = contractorDefaultEstimates[x]['estimates'][index];

              if (timeEstimate['estimateid'] == estimateid) {
                if (highlighted == 'shape') {
                  markerShape = timeEstimate['attribute']

                } else if (highlighted == 'color') {
                  markerColor = timeEstimate['attribute']

                } else if (highlighted == 'dots') {
                  markerDots = timeEstimate['attribute']
                }
              }
            }
          }
        }
      }
    }

    // This  goes through msa time estimates to get possible shapes/colors of the marker, and overwrites any shapes and colors that we set above

    if (calendar.msa_id && msaTimeEstim) {
      for (let i in timeEstim) {
        const categoryid = timeEstim[i]['categoryid']
        const estimateid = timeEstim[i]['estimateid']

        if (msaTimeEstim['categoryid'] == categoryid) {
          const highlighted = msaTimeEstim['highlighted']
          if (msaTimeEstim['estimates']) {
            for (let index = 0; index < msaTimeEstim['estimates'].length; index++) {
              const timeEstimate = msaTimeEstim['estimates'][index];

              if (timeEstimate['estimateid'] == estimateid) {

                if (highlighted == 'shape') {
                  markerShape = timeEstimate['attribute']

                } else if (highlighted == 'color') {
                  markerColor = timeEstimate['attribute']

                } else if (highlighted == 'dots') {
                  markerDots = timeEstimate['attribute']
                }
              }
            }
          }
        }
      }

    }
    let svgString = this.mapService.getGeneratedSvg(markerShape, markerColor, markerDots)
    // Gets our marker icon from getGeneratedSvg method, returns default icon if null values are passed
    return svgString
  }

  /** Function for button inside an open slot to close it
  * @param id
  */
  closeDetailsWithId(id) {
    if (document.getElementById(id)) {
      document.getElementById(id)?.removeAttribute("open");
    }
  }



  /**
   * Called by week change arrow, handles change to next week
   */
  nextWeek() {
    const nextMonday = this.getFirstDayOfNextWeek(this.firstDay)

    this.setupCalendar(nextMonday)
  }

  /**
   * Called by week change arrow, handles change to previous week
   */
  lastWeek() {
    const lastMonday = this.getFirstDayOfLastWeek(this.firstDay)

    this.setupCalendar(lastMonday)
  }

  /**
   * Gets first day of current week
   * @param d
   * @returns
   */
  getFirstDayOfWeek(d) {
    // clone date object, so we don't mutate it
    const date = new Date(d);
    const day = date.getDay(); // get day of week

    // day of month - day of week (-6 if Sunday), otherwise +1
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }

  /**
   * Gets last day of current week
   * @param d
   * @returns
   */
  getLastDayOfWeek(d) {

    // clone date object, so we don't mutate it
    const date = new Date(d)
    const day = date.getDay() // get day of week

    const diff = date.getDate() - (day - 1) + 6;

    return new Date(date.setDate(diff));

  }

  /**
   * Sets the weeknumber from given date
   * @param d
   */
  setWeekFromDate(d) {

    let startDate = new Date(d.getFullYear(), 0, 1)
    let days = Math.floor((Number(d) - Number(startDate)) / (24 * 60 * 60 * 1000))

    this.weekNr = Math.ceil((d.getDay() + 1 + days) / 7)
  }

  /**
   * Returns first day of last week
   * @param d
   * @returns
   */
  getFirstDayOfLastWeek(d) {

    const lastWeek = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);

    return lastWeek;
  }

  /**
   * Returns first day of next week
   * @param d
   * @returns
   */
  getFirstDayOfNextWeek(d) {

    const nextWeek = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 7);

    return nextWeek;
  }


  saveCalendars() {
    let changes = false;
    let observables: Observable<any>[] = [];

    let addedArray: any[] = [];
    let deletedArray: any[] = [];
    let allWorkordersWithConfirmed: any[] = [];

    this.calendarData.forEach(calendar => {
        Object.entries(calendar.slots).forEach(([key, calendarSlot]) => {
            const slot = calendarSlot as CalendarSlot;
            if (slot.workorders && slot.workorders.length > 0) {
                slot.workorders.forEach(element => {
                  if (element.time_confirmed === 1 && 
                    this.selectedMarkers.some(item => 
                        item.markers.some(m => m.id === element.workorder_id))) {
                    allWorkordersWithConfirmed.push(element);
                  }
                });
            }
            if (slot.markers) {
                const slotsSet = new Set(slot.markers.map(marker => parseInt(marker.id)));

                this.originalCalendarData.forEach(originalCalendar => {
                    if (originalCalendar.slots[key]) {
                        const originalSlotsSet = new Set(originalCalendar.slots[key].workorders.map(workorder => workorder.workorder_id));
                        const { addedMarkers, deletedWorkorders } = this.findDifferences(slotsSet, originalSlotsSet);

                        addedMarkers.forEach(markerId => addedArray.push({ key, markerId }));
                        deletedWorkorders.forEach(workorderId => deletedArray.push({ key, workorderId }));
                    }
                });
            }
        });
    });

    // Only those that are truly removed, not those that are just moved
    const addedIds = new Set(addedArray.map(item => item.markerId));
    deletedArray = deletedArray.filter(item => !addedIds.has(item.workorderId));

    // Process deletions
    deletedArray.forEach(({ key, workorderId }) => {
        observables.push(this.calendars.updateCalendarSlots(key, workorderId, 'delete'));
        changes = true;
    });

    // Process additions
    addedArray.forEach(({ key, markerId }) => {
        observables.push(this.calendars.updateCalendarSlots(key, markerId, 'add'));
        changes = true;
    });

    if (changes && allWorkordersWithConfirmed.length > 0) {
        let tableContent = '<table><thead><tr><th>Old device ID</th></tr></thead><tbody>';
        allWorkordersWithConfirmed.forEach(item => {
            tableContent += `<tr><td>${item.old_device_id}</td></tr>`;
        });
        tableContent += '</tbody></table>';

        Swal.fire({
            titleText: 'Remove confirmations?',
            showConfirmButton: true,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            html: tableContent
        }).then((result) => {
            if (result.isConfirmed) {
                this.workorderService.updateWorkordersConfirmation(allWorkordersWithConfirmed.map(e => e.workorder_id)).subscribe(
                    () => this.processChanges(observables)
                );
            } else if (result.isDenied) {
                this.processChanges(observables);
            } else if (result.isDismissed) {
                this.originalCalendarData = [];
                this.calendarData = [];
                this.getCalendarIds(true);
            }
        });
    } else {
        this.processChanges(observables);
    }
}

  findDifferences(slotsSet, originalSlotsSet) {
    const addedMarkers = [...slotsSet].filter(id => !originalSlotsSet.has(id));
    const deletedWorkorders = [...originalSlotsSet].filter(id => !slotsSet.has(id));
    return { addedMarkers, deletedWorkorders };
  }

  processChanges(observables) {
    forkJoin(observables)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
            // Reset data after changes
            this.originalCalendarData = [];
            this.calendarData = [];
            this.getCalendarIds(true);
        });
}



  /**
   * Calculates slot time from slot workorders.
   * @param workorders is a key value pair object with id as key and time as value
   * @returns
   *
   * @edit 2.12.2022
   * Logic for new api-call api/calendars/ID
   * @author Jesse Lindholm
   */
  getSlotTime(workorders) {
    let time = 0

    for (const key in workorders) {
      if (Object.prototype.hasOwnProperty.call(workorders, key)) {
        const element = workorders[key];
        if (element) time = time + element.time
      }
    }

    return this.workorderService.minutesToHoursAndMinutes(time)
  }


  /**
   * Move to current day with clicking icon on top of calendars
   */
  moveToToday() {
    let date = new Date();
    let day = date.getDay(),

      diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    this.firstDay = new Date(date.setDate(diff));
    this.lastDay = new Date()
    this.lastDay.setDate(this.firstDay.getDate() + 6)
    this.weekNr = this.datePipe.transform(this.firstDay, 'w')
    //this.setupCalendar(this.firstDay, this.calendarData.slots)

  }

  /**
  * Toggle right UI for fill for one slot
  */
  toggleFill(selectedSlot) {
      for (let calendarIndex = 0; calendarIndex < this.calendarData.length; calendarIndex++) {
        const calendar = this.calendarData[calendarIndex];

        for (let i = 0; i < calendar.slotRows.length; i++) {
          for (let x = 0; x < calendar.slotRows[i].length; x++) {
            const slot = calendar.slotRows[i][x];
            // Empty slot's have typeof "string", disabled slot's have disabled set to 1 (skip them)
            if (slot.disabled === 0 && typeof (slot) !== 'string') {
              slot.color = 'white'
              slot.startHere = false
            }
          }
        }
        if (selectedSlot.disabled === 0) {
          selectedSlot.color = lightColor
          selectedSlot.startHere = true
        }
      }
  }



 selectMarker(marker, slot) {
  let index = this.selectedMarkers.findIndex(sm => sm.slot.id === slot.id)
  if (index === -1) {
    this.selectedMarkers.push({markers: [marker], slot: slot})
    this.moveMarkersToggle = true
    slot.selected = true
  } else {
    let item = this.selectedMarkers[index]
    let foundIndex = item.markers.findIndex(i => i.id === marker.id)
    if (foundIndex !== -1) {
      item.markers.splice(foundIndex, 1)
      if (item.markers.length === 0) {
        this.selectedMarkers.splice(index, 1)
        slot.selected = false
        if (this.selectedMarkers.length === 0) this.moveMarkersToggle = false

      }
    } else item.markers.push(marker)
  }

 }

  // User clicks toggle to allow selection of a new slot
  toggleSlotSelection() {
    this.selectSlotMode = true;
    this.selectSlotMultiple = false
  }

  // Clears selected markers, resets state
  clearSelectedMarkers() {
    this.selectSlotMode = false;
    this.moveMarkersToggle = false;
    this.calendarData.forEach(calendar => {
      calendar.slotRows.forEach(slotRow => {
        slotRow.forEach(slot => {
          if (slot.selected) slot.selected = false
          if (slot.markers) {
            slot.markers.forEach(marker => {
              marker.checked = false
            });
          }
        })
      });
    });
    this.selectedMarkers = [];
  }


}


interface Marker {
  id: string; // Assuming id is a string that needs to be parsed to an int
  checked: boolean;
}

interface CalendarSlot {
  markers?: Marker[];
  workorders?: { workorder_id: number, time_confirmed: null | number , checked: boolean}[]; 
}
